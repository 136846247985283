import { makeAutoObservable } from "mobx";
import {
  MonitorConfigResponse,
  IMonitorConfigResponse,
} from "../proto/proto";
import { Store } from "./store";

export class Config {
  accId: number;
  source: number;
  barcode: string | null;
  refreshIntervalInMs = 1000;
  isReceivedFromServer = false;
  accCurrency = "USD";
  currencyDenomination: number = 2;
  currencySymbol: string | null = null;
  options: MonitorConfigResponse.IMonitorOption[] = [];

  constructor(private store: Store) {
    const url = new URL(document.location.href);
    this.accId = Number(url.searchParams.get("accId"));
    this.source = Number(url.searchParams.get("source"));
    this.barcode = url.searchParams.get("barcode");
    makeAutoObservable(this);
  }

  get isCTAEnabled(): boolean {
    return (
      this.options.find((_) => _.type === MonitorConfigResponse.MonitorOptionType.CTA_ENABLED)
        ?.optValueBool ?? false
    );
  }

  get CTABlockBG(): string | null {
    return (
      this.options.find((_) => _.type === MonitorConfigResponse.MonitorOptionType.CTA_BLOCK_BG)
        ?.optValueStr ?? null
    );
  }

  get CTATitle(): string | null {
    return (
      this.options.find(
        (_) => _.type === MonitorConfigResponse.MonitorOptionType.CTA_BTN_LETSPLAY_TEXT
      )?.optValueStr ?? null
    );
  }

  get CTADesc(): string | null {
    return (
      this.options.find(
        (_) => _.type === MonitorConfigResponse.MonitorOptionType.CTA_BTN_BE_A_WINNER_TEXT
      )?.optValueStr ?? null
    );
  }

  get CTAButtonText(): string | null {
    return (
      this.options.find((_) => _.type === MonitorConfigResponse.MonitorOptionType.CTA_BTN_SPIN_TEXT)
        ?.optValueStr ?? null
    );
  }

  get CTAButtonBG(): string | null {
    return (
      this.options.find((_) => _.type === MonitorConfigResponse.MonitorOptionType.CTA_BTN_SPIN_BG)
        ?.optValueStr ?? null
    );
  }

  get CTAButtonLink(): string | null {
    return (
      this.options.find((_) => _.type === MonitorConfigResponse.MonitorOptionType.CTA_BTN_LINK)
        ?.optValueStr ?? null
    );
  }

  get isInfoEnabled(): boolean {
    return (
      this.options.find((_) => _.type === MonitorConfigResponse.MonitorOptionType.INFO_ENABLED)
        ?.optValueBool ?? false
    );
  }

  get infoText(): string | null {
    return (
      this.options.find((_) => _.type === MonitorConfigResponse.MonitorOptionType.INFO_BTN_TEXT)
        ?.optValueStr ?? null
    );
  }

  get infoLink(): string | null {
    return (
      this.options.find((_) => _.type === MonitorConfigResponse.MonitorOptionType.INFO_BTN_LINK)
        ?.optValueStr ?? null
    );
  }

  get infoBG(): string | null {
    return (
      this.options.find((_) => _.type === MonitorConfigResponse.MonitorOptionType.INFO_BG)
        ?.optValueStr ?? null
    );
  }

  get isSocialEnabled(): boolean {
    return (
      this.options.find((_) => _.type === MonitorConfigResponse.MonitorOptionType.SOCIAL_ENABLED)
        ?.optValueBool ?? false
    );
  }

  get socialFBLink(): string | null {
    return (
      this.options.find((_) => _.type === MonitorConfigResponse.MonitorOptionType.SOCIAL_FB_LINK)
        ?.optValueStr ?? null
    );
  }

  get socialInstagramLink(): string | null {
    return (
      this.options.find(
        (_) => _.type === MonitorConfigResponse.MonitorOptionType.SOCIAL_INSTAGRAM_LINK
      )?.optValueStr ?? null
    );
  }

  get socialTelegramLink(): string | null {
    return (
      this.options.find(
        (_) => _.type === MonitorConfigResponse.MonitorOptionType.SOCIAL_TELEGRAM_LINK
      )?.optValueStr ?? null
    );
  }

  process(response: IMonitorConfigResponse | null | undefined, refreashInterval?: number | null) {
    this.options = response?.options || [];
    this.accCurrency = response?.accCurrency || '';
    this.currencyDenomination = response?.accCurrencyInfo?.denom || 2;
    this.currencySymbol = response?.accCurrencyInfo?.symbol || null;
    this.refreshIntervalInMs = refreashInterval || 1000;
    this.isReceivedFromServer = true;
  }
}
