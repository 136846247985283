import {v4 as uuid} from 'uuid';
import { sleep } from "../../utils/sleep";
import { Store } from "../store";
import { MonitorRequest, MonitorResponse, MonitorConfigRequest, MonitorActionRequest, IMonitorConfigResponse, MonitorJackpotsRequest, IJackpotsUnifiedResponse } from "../../proto/proto";
import { log } from "../../utils/log";

const JACKPOT_MONITOR_API = "/exec";
const JACKPOT_MONITOR_RETRY_TIMEOUT = 1000;

export class API {
  constructor(private store: Store) {}

  configRequest = async (accId?: number, source?: number): Promise<IMonitorConfigResponse | undefined | null> => {
    const configRequest = new MonitorConfigRequest();
    let trx = uuid();
    const monitorActionRequest = new MonitorActionRequest({
      config: configRequest,
      trx: trx,
    })
    const request = new MonitorRequest({
      accId,
      source,
      actions: [monitorActionRequest]
    });
    log("->", request);
    const body = MonitorRequest.encode(request).finish();
    const result = await this.executeWithRetry(body);
    log("<-", result);

    const res = result?.actions ? 
      result?.actions.find((action) => {
        return !!action.config
      }) : undefined;

    return res ? res.config : undefined;
  };

  jackpotsRequest = async (accId?: number, source?: number): Promise<IJackpotsUnifiedResponse | undefined | null> => {
    const jackpotsRequest = new MonitorJackpotsRequest({
      winners: {
        defaultFetchCount: 10,
        globalFetchCount: 3
      }
    });
    let trx = uuid();
    const monitorActionRequest = new MonitorActionRequest({
      jackpots: jackpotsRequest,
      trx: trx,
    })
    const request = new MonitorRequest({
      accId,
      source,
      actions: [monitorActionRequest]
    });
    log("->", request);
    const body = MonitorRequest.encode(request).finish();
    const result = await this.executeWithRetry(body);
    log("<-", result);

    const res = result?.actions ? 
      result?.actions.find((action) => {
        return !!action.jackpots
      }) : undefined;

    return res ? res.jackpots : undefined;
  };

  executeWithRetry = async (body: Uint8Array): Promise<MonitorResponse> => {
    let result: MonitorResponse | undefined;
    let retryNumber = 0;
    while (!result) {
      try {
        if (retryNumber > 0) {
          await sleep(JACKPOT_MONITOR_RETRY_TIMEOUT);
        }
        const response = await this.executeRequest(body, retryNumber);
        if (!response.ok) {
          throw new Error("Status " + response.status);
        }
        const buffer = new Uint8Array(await response.arrayBuffer());
        result = MonitorResponse.decode(buffer);
      } catch (error) {
        console.error(error);
      } finally {
        retryNumber++;
      }
    }
    return result;
  };

  executeRequest = async (body: Uint8Array, retryNumber: number) => {
    return fetch(JACKPOT_MONITOR_API, {
      body,
      headers: {
        Cache: "no-cache",
        "Content-Type": "application/x-protobuf",
      },
      method: "POST",
    });
  };
}
