import "./spin.css";
import { observer } from "mobx-react";
import { useStore } from "../../stores/store";
import { formatLink } from "../../utils/formatLink";

const _Spin = () => {
  const store = useStore();

  if (!store.config.isCTAEnabled) {
    return null;
  }
  const background = store.config.CTABlockBG ? store.config.CTABlockBG : 'linear-gradient(180deg, #0F132A 0%, #2A316C 53.12%, #151C53 97.92%)';
  const buttonBackground = store.config.CTAButtonBG ? store.config.CTAButtonBG : 'linear-gradient(180deg, #EAC400 0%, #FFEF64 50.46%, #EAC400 100%)';
  return (
    <div
      className={`spin ${store.isSpinBlockShown ? "" : "spin--hidden"}`}
      style={{ background: background }}
    >
      <div className="spin__skip" onClick={store.toggleSpinBlock}>
        Skip
      </div>
      <h1 className="spin__letsPlay">
        {store.config.CTATitle ?? "Let's play!"}
      </h1>
      <p className="spin__winner">
        {store.config.CTADesc ?? "Be a winner today"}
      </p>
      <a
        href={store.config.CTAButtonLink ? formatLink(store.config.CTAButtonLink) : "#"}

        className="spin__button"
        style={{
          background: buttonBackground
        }}
      >
        <span
          className="spin__buttonText"
          style={
            store.config.CTABlockBG
              ? {
                  backgroundImage: store.config.CTABlockBG.includes("gradient")
                    ? store.config.CTABlockBG
                    : "none",
                  backgroundColor: !store.config.CTABlockBG.includes("gradient")
                    ? store.config.CTABlockBG
                    : "transparent",
                }
              : {}
          }
        >
          {store.config.CTAButtonText ?? "SPIN"}
        </span>
      </a>
    </div>
  );
};

export const Spin = observer(_Spin);
