import { makeAutoObservable } from "mobx";
import React from "react";
import { MobXProviderContext } from "mobx-react";
import { Config } from "./config";
import { API } from "./services/api";
import { sleep } from "../utils/sleep";
import { Jackpots } from "./jackpots";

export class Store {
  config = new Config(this);
  jackpots = new Jackpots(this);

  //services
  api = new API(this);

  isSpinBlockShown = true;

  constructor() {
    makeAutoObservable(this);
  }

  init = async () => {
    const configResponse = await this.api.configRequest(this.config.accId, this.config.source);
    const jackpotsResponse = await this.api.jackpotsRequest(this.config.accId, this.config.source);
    const checkingTime = jackpotsResponse?.refreshIntervalInMs;
    this.config.process(configResponse, checkingTime);
    this.jackpots.process(jackpotsResponse);
    this.startChecking();
  };

  startChecking = async () => {
    while (true) {
      try {
        await sleep(this.config.refreshIntervalInMs);
        const jackpotsResponse = await this.api.jackpotsRequest(this.config.accId, this.config.source);
        this.jackpots.process(jackpotsResponse);
      } catch (error) {
        console.error(error);
      }
    }
  };

  toggleSpinBlock = () => {
    this.isSpinBlockShown = !this.isSpinBlockShown;
  };
}

export const store = new Store();

export function useStore(): Store {
  return React.useContext(MobXProviderContext).store;
}
