import { useStore } from "../stores/store";
import { Jackpot } from "../components/jackpot/jackpot";
import { observer } from "mobx-react";


const _Jackpots = () => {
  const {jackpots} = useStore();

  return (
    <>
      <h1>JACKPOTS</h1>
        {jackpots.global.map((_, index) => (
          <Jackpot key={_.key} jackpot={_} index={index} />
        ))}
        {jackpots.otherLocal.length > 0 && (
          <section className="local">
            <h2>LOCAL</h2>
            <div className="local__jackpots">
              {jackpots.otherLocal.map((_, index) => (
                <Jackpot key={_.key} size="small" jackpot={_} />
              ))}
            </div>
          </section>
        )}
    </>
  );
};

export const Jackpots = observer(_Jackpots);
