import { action, computed, makeObservable, observable } from "mobx";
import { IJackpotsUnifiedResponse, IJackpotUnifiedWinner } from "../proto/proto";
import { Jackpot } from "./jackpot";
import { Store } from "./store";


export interface IWinner extends IJackpotUnifiedWinner {
  jackpot?: Jackpot;
}


export class Jackpots {
  global = observable<Jackpot>([]);
  local = observable<Jackpot>([]);
  globalWinsList = observable<IWinner>([]);
  localWinsList = observable<IWinner>([]);

  constructor(private store: Store) {
    makeObservable(this, {
      localWithCurrentSource: computed,
      otherLocal: computed,
      process: action,
      localWins: computed,
      globalWins: computed
    });
  }

  get localWithCurrentSource(): Jackpot | undefined {
    return this.local.find((_) => Number(_.id) === this.store.config.source);
  }

  get otherLocal(): Jackpot[] {
    return this.local.filter((_) => !!_.name);
  }

  get localWins() {
    return this.localWinsList;
  }

  get globalWins() {
    return this.globalWinsList;
  }

  process = async(response: IJackpotsUnifiedResponse | null | undefined) => {
    const jackpots: Jackpot[] = [];
    if (response?.jackpots) {

      if (!this.local?.length || !this.global?.length) {
        response.jackpots.forEach((jp) => {
          jackpots.push(new Jackpot(this.store, jp))
        })
      }
      
      if (!this.local?.length) {
        this.local.replace(
          jackpots
            .filter((_) => (!!_.name || !!_.balance) && !_.isGlobal && _.sortWeight < 50)
            .sort((a,b) => b.sortWeight - a.sortWeight)
        )
      } else {
        this.local.forEach((_) => {
          const state = response.jackpots?.find((jp) => jp.id === _.id);
          if (state) {
            _.updateState(state);
          }
        })
      }
      if (!this.global?.length) {
        this.global.replace(
          jackpots
            .filter((_) => (!!_.name || !!_.balance) && (_.isGlobal || _.sortWeight >= 50))
            .sort((a,b) => {
              if (a.isGlobal && !b.isGlobal) {
                return -1;
              }
              if (!a.isGlobal && b.isGlobal) {
                return 1;
              }
              return b.sortWeight - a.sortWeight;
            })
        )
      } else {
        this.global.forEach((_) => {
          const state = response.jackpots?.find((jp) => jp.id === _.id);
          if (state) {
            _.updateState(state);
          }
        })
      }
    }
    if (response?.winners) {
      const winners: IWinner[] = [];
      response.winners.forEach((winner) => {
        winners.push({
          ...winner,
          jackpot: this.global.find((jp) => jp.id === winner.jackpotId) || this.local.find((jp) => jp.id === winner.jackpotId)
        })
      })
      this.globalWinsList.replace(
        winners
          .filter((_) => (_.jackpot?.isGlobal))
          .sort((a, b) => {
            if (!!a?.stamp && !!b?.stamp) {
              return b.stamp.compare(a.stamp) !== 0 ? b.stamp.compare(a.stamp)
               : a.barcode && b.barcode 
                  ? Number(a.barcode) - Number(b.barcode) : 0;
            }
            return a.barcode && b.barcode 
            ? Number(a.barcode) - Number(b.barcode) : 0;
          })
          .slice(0, 10)
      )

      this.localWinsList.replace(
        winners
          .filter((_) => (!_.jackpot?.isGlobal))
          .sort((a, b) => {
            if (!!a?.stamp && !!b?.stamp) {
              return b.stamp.compare(a.stamp) !== 0 ? b.stamp.compare(a.stamp)
               : a.barcode && b.barcode 
                  ? Number(a.barcode) - Number(b.barcode) : 0;
            }
            return a.barcode && b.barcode 
            ? Number(a.barcode) - Number(b.barcode) : 0;
          })
          .slice(0, 10)
      )
    }
  }
}
