/*eslint-disable*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";
$protobuf.util.Long = require('long');
$protobuf.configure();

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.JackpotsUnifiedRequest = (function() {
    
        /**
         * Properties of a JackpotsUnifiedRequest.
         * @exports IJackpotsUnifiedRequest
         * @interface IJackpotsUnifiedRequest
         * @property {number|null} [accId] JackpotsUnifiedRequest accId
         * @property {Array.<string>|null} [jackpotsIds] JackpotsUnifiedRequest jackpotsIds
         * @property {IJackpotsUnifiedWinnersRequest|null} [winners] JackpotsUnifiedRequest winners
         */
    
        /**
         * Constructs a new JackpotsUnifiedRequest.
         * @exports JackpotsUnifiedRequest
         * @classdesc Represents a JackpotsUnifiedRequest.
         * @implements IJackpotsUnifiedRequest
         * @constructor
         * @param {IJackpotsUnifiedRequest=} [properties] Properties to set
         */
        function JackpotsUnifiedRequest(properties) {
            this.jackpotsIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * JackpotsUnifiedRequest accId.
         * @member {number} accId
         * @memberof JackpotsUnifiedRequest
         * @instance
         */
        JackpotsUnifiedRequest.prototype.accId = 0;
    
        /**
         * JackpotsUnifiedRequest jackpotsIds.
         * @member {Array.<string>} jackpotsIds
         * @memberof JackpotsUnifiedRequest
         * @instance
         */
        JackpotsUnifiedRequest.prototype.jackpotsIds = $util.emptyArray;
    
        /**
         * JackpotsUnifiedRequest winners.
         * @member {IJackpotsUnifiedWinnersRequest|null|undefined} winners
         * @memberof JackpotsUnifiedRequest
         * @instance
         */
        JackpotsUnifiedRequest.prototype.winners = null;
    
        /**
         * Creates a new JackpotsUnifiedRequest instance using the specified properties.
         * @function create
         * @memberof JackpotsUnifiedRequest
         * @static
         * @param {IJackpotsUnifiedRequest=} [properties] Properties to set
         * @returns {JackpotsUnifiedRequest} JackpotsUnifiedRequest instance
         */
        JackpotsUnifiedRequest.create = function create(properties) {
            return new JackpotsUnifiedRequest(properties);
        };
    
        /**
         * Encodes the specified JackpotsUnifiedRequest message. Does not implicitly {@link JackpotsUnifiedRequest.verify|verify} messages.
         * @function encode
         * @memberof JackpotsUnifiedRequest
         * @static
         * @param {IJackpotsUnifiedRequest} message JackpotsUnifiedRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JackpotsUnifiedRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.accId != null && Object.hasOwnProperty.call(message, "accId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.accId);
            if (message.jackpotsIds != null && message.jackpotsIds.length)
                for (var i = 0; i < message.jackpotsIds.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.jackpotsIds[i]);
            if (message.winners != null && Object.hasOwnProperty.call(message, "winners"))
                $root.JackpotsUnifiedWinnersRequest.encode(message.winners, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified JackpotsUnifiedRequest message, length delimited. Does not implicitly {@link JackpotsUnifiedRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof JackpotsUnifiedRequest
         * @static
         * @param {IJackpotsUnifiedRequest} message JackpotsUnifiedRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JackpotsUnifiedRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a JackpotsUnifiedRequest message from the specified reader or buffer.
         * @function decode
         * @memberof JackpotsUnifiedRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {JackpotsUnifiedRequest} JackpotsUnifiedRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JackpotsUnifiedRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.JackpotsUnifiedRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.accId = reader.uint32();
                    break;
                case 2:
                    if (!(message.jackpotsIds && message.jackpotsIds.length))
                        message.jackpotsIds = [];
                    message.jackpotsIds.push(reader.string());
                    break;
                case 10:
                    message.winners = $root.JackpotsUnifiedWinnersRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a JackpotsUnifiedRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof JackpotsUnifiedRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {JackpotsUnifiedRequest} JackpotsUnifiedRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JackpotsUnifiedRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a JackpotsUnifiedRequest message.
         * @function verify
         * @memberof JackpotsUnifiedRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JackpotsUnifiedRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.accId != null && message.hasOwnProperty("accId"))
                if (!$util.isInteger(message.accId))
                    return "accId: integer expected";
            if (message.jackpotsIds != null && message.hasOwnProperty("jackpotsIds")) {
                if (!Array.isArray(message.jackpotsIds))
                    return "jackpotsIds: array expected";
                for (var i = 0; i < message.jackpotsIds.length; ++i)
                    if (!$util.isString(message.jackpotsIds[i]))
                        return "jackpotsIds: string[] expected";
            }
            if (message.winners != null && message.hasOwnProperty("winners")) {
                var error = $root.JackpotsUnifiedWinnersRequest.verify(message.winners);
                if (error)
                    return "winners." + error;
            }
            return null;
        };
    
        /**
         * Creates a JackpotsUnifiedRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof JackpotsUnifiedRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {JackpotsUnifiedRequest} JackpotsUnifiedRequest
         */
        JackpotsUnifiedRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.JackpotsUnifiedRequest)
                return object;
            var message = new $root.JackpotsUnifiedRequest();
            if (object.accId != null)
                message.accId = object.accId >>> 0;
            if (object.jackpotsIds) {
                if (!Array.isArray(object.jackpotsIds))
                    throw TypeError(".JackpotsUnifiedRequest.jackpotsIds: array expected");
                message.jackpotsIds = [];
                for (var i = 0; i < object.jackpotsIds.length; ++i)
                    message.jackpotsIds[i] = String(object.jackpotsIds[i]);
            }
            if (object.winners != null) {
                if (typeof object.winners !== "object")
                    throw TypeError(".JackpotsUnifiedRequest.winners: object expected");
                message.winners = $root.JackpotsUnifiedWinnersRequest.fromObject(object.winners);
            }
            return message;
        };
    
        /**
         * Creates a plain object from a JackpotsUnifiedRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof JackpotsUnifiedRequest
         * @static
         * @param {JackpotsUnifiedRequest} message JackpotsUnifiedRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JackpotsUnifiedRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.jackpotsIds = [];
            if (options.defaults) {
                object.accId = 0;
                object.winners = null;
            }
            if (message.accId != null && message.hasOwnProperty("accId"))
                object.accId = message.accId;
            if (message.jackpotsIds && message.jackpotsIds.length) {
                object.jackpotsIds = [];
                for (var j = 0; j < message.jackpotsIds.length; ++j)
                    object.jackpotsIds[j] = message.jackpotsIds[j];
            }
            if (message.winners != null && message.hasOwnProperty("winners"))
                object.winners = $root.JackpotsUnifiedWinnersRequest.toObject(message.winners, options);
            return object;
        };
    
        /**
         * Converts this JackpotsUnifiedRequest to JSON.
         * @function toJSON
         * @memberof JackpotsUnifiedRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JackpotsUnifiedRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return JackpotsUnifiedRequest;
    })();
    
    $root.JackpotsUnifiedWinnersRequest = (function() {
    
        /**
         * Properties of a JackpotsUnifiedWinnersRequest.
         * @exports IJackpotsUnifiedWinnersRequest
         * @interface IJackpotsUnifiedWinnersRequest
         * @property {JackpotsUnifiedWinnersRequest.FetchType|null} [type] JackpotsUnifiedWinnersRequest type
         * @property {number|null} [defaultFetchCount] JackpotsUnifiedWinnersRequest defaultFetchCount
         * @property {number|null} [globalFetchCount] JackpotsUnifiedWinnersRequest globalFetchCount
         */
    
        /**
         * Constructs a new JackpotsUnifiedWinnersRequest.
         * @exports JackpotsUnifiedWinnersRequest
         * @classdesc Represents a JackpotsUnifiedWinnersRequest.
         * @implements IJackpotsUnifiedWinnersRequest
         * @constructor
         * @param {IJackpotsUnifiedWinnersRequest=} [properties] Properties to set
         */
        function JackpotsUnifiedWinnersRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * JackpotsUnifiedWinnersRequest type.
         * @member {JackpotsUnifiedWinnersRequest.FetchType} type
         * @memberof JackpotsUnifiedWinnersRequest
         * @instance
         */
        JackpotsUnifiedWinnersRequest.prototype.type = 0;
    
        /**
         * JackpotsUnifiedWinnersRequest defaultFetchCount.
         * @member {number} defaultFetchCount
         * @memberof JackpotsUnifiedWinnersRequest
         * @instance
         */
        JackpotsUnifiedWinnersRequest.prototype.defaultFetchCount = 0;
    
        /**
         * JackpotsUnifiedWinnersRequest globalFetchCount.
         * @member {number} globalFetchCount
         * @memberof JackpotsUnifiedWinnersRequest
         * @instance
         */
        JackpotsUnifiedWinnersRequest.prototype.globalFetchCount = 0;
    
        /**
         * Creates a new JackpotsUnifiedWinnersRequest instance using the specified properties.
         * @function create
         * @memberof JackpotsUnifiedWinnersRequest
         * @static
         * @param {IJackpotsUnifiedWinnersRequest=} [properties] Properties to set
         * @returns {JackpotsUnifiedWinnersRequest} JackpotsUnifiedWinnersRequest instance
         */
        JackpotsUnifiedWinnersRequest.create = function create(properties) {
            return new JackpotsUnifiedWinnersRequest(properties);
        };
    
        /**
         * Encodes the specified JackpotsUnifiedWinnersRequest message. Does not implicitly {@link JackpotsUnifiedWinnersRequest.verify|verify} messages.
         * @function encode
         * @memberof JackpotsUnifiedWinnersRequest
         * @static
         * @param {IJackpotsUnifiedWinnersRequest} message JackpotsUnifiedWinnersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JackpotsUnifiedWinnersRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.defaultFetchCount != null && Object.hasOwnProperty.call(message, "defaultFetchCount"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.defaultFetchCount);
            if (message.globalFetchCount != null && Object.hasOwnProperty.call(message, "globalFetchCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.globalFetchCount);
            return writer;
        };
    
        /**
         * Encodes the specified JackpotsUnifiedWinnersRequest message, length delimited. Does not implicitly {@link JackpotsUnifiedWinnersRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof JackpotsUnifiedWinnersRequest
         * @static
         * @param {IJackpotsUnifiedWinnersRequest} message JackpotsUnifiedWinnersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JackpotsUnifiedWinnersRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a JackpotsUnifiedWinnersRequest message from the specified reader or buffer.
         * @function decode
         * @memberof JackpotsUnifiedWinnersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {JackpotsUnifiedWinnersRequest} JackpotsUnifiedWinnersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JackpotsUnifiedWinnersRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.JackpotsUnifiedWinnersRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.defaultFetchCount = reader.uint32();
                    break;
                case 3:
                    message.globalFetchCount = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a JackpotsUnifiedWinnersRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof JackpotsUnifiedWinnersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {JackpotsUnifiedWinnersRequest} JackpotsUnifiedWinnersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JackpotsUnifiedWinnersRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a JackpotsUnifiedWinnersRequest message.
         * @function verify
         * @memberof JackpotsUnifiedWinnersRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JackpotsUnifiedWinnersRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                    break;
                }
            if (message.defaultFetchCount != null && message.hasOwnProperty("defaultFetchCount"))
                if (!$util.isInteger(message.defaultFetchCount))
                    return "defaultFetchCount: integer expected";
            if (message.globalFetchCount != null && message.hasOwnProperty("globalFetchCount"))
                if (!$util.isInteger(message.globalFetchCount))
                    return "globalFetchCount: integer expected";
            return null;
        };
    
        /**
         * Creates a JackpotsUnifiedWinnersRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof JackpotsUnifiedWinnersRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {JackpotsUnifiedWinnersRequest} JackpotsUnifiedWinnersRequest
         */
        JackpotsUnifiedWinnersRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.JackpotsUnifiedWinnersRequest)
                return object;
            var message = new $root.JackpotsUnifiedWinnersRequest();
            switch (object.type) {
            case "FT_DEFAULT_GLOBAL_VISUAL":
            case 0:
                message.type = 0;
                break;
            }
            if (object.defaultFetchCount != null)
                message.defaultFetchCount = object.defaultFetchCount >>> 0;
            if (object.globalFetchCount != null)
                message.globalFetchCount = object.globalFetchCount >>> 0;
            return message;
        };
    
        /**
         * Creates a plain object from a JackpotsUnifiedWinnersRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof JackpotsUnifiedWinnersRequest
         * @static
         * @param {JackpotsUnifiedWinnersRequest} message JackpotsUnifiedWinnersRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JackpotsUnifiedWinnersRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "FT_DEFAULT_GLOBAL_VISUAL" : 0;
                object.defaultFetchCount = 0;
                object.globalFetchCount = 0;
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.JackpotsUnifiedWinnersRequest.FetchType[message.type] : message.type;
            if (message.defaultFetchCount != null && message.hasOwnProperty("defaultFetchCount"))
                object.defaultFetchCount = message.defaultFetchCount;
            if (message.globalFetchCount != null && message.hasOwnProperty("globalFetchCount"))
                object.globalFetchCount = message.globalFetchCount;
            return object;
        };
    
        /**
         * Converts this JackpotsUnifiedWinnersRequest to JSON.
         * @function toJSON
         * @memberof JackpotsUnifiedWinnersRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JackpotsUnifiedWinnersRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        /**
         * FetchType enum.
         * @name JackpotsUnifiedWinnersRequest.FetchType
         * @enum {number}
         * @property {number} FT_DEFAULT_GLOBAL_VISUAL=0 FT_DEFAULT_GLOBAL_VISUAL value
         */
        JackpotsUnifiedWinnersRequest.FetchType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "FT_DEFAULT_GLOBAL_VISUAL"] = 0;
            return values;
        })();
    
        return JackpotsUnifiedWinnersRequest;
    })();
    
    $root.JackpotsUnifiedResponse = (function() {
    
        /**
         * Properties of a JackpotsUnifiedResponse.
         * @exports IJackpotsUnifiedResponse
         * @interface IJackpotsUnifiedResponse
         * @property {number|null} [refreshIntervalInMs] JackpotsUnifiedResponse refreshIntervalInMs
         * @property {Array.<IJackpotUnifiedInfo>|null} [jackpots] JackpotsUnifiedResponse jackpots
         * @property {Array.<IJackpotUnifiedWinner>|null} [winners] JackpotsUnifiedResponse winners
         */
    
        /**
         * Constructs a new JackpotsUnifiedResponse.
         * @exports JackpotsUnifiedResponse
         * @classdesc Represents a JackpotsUnifiedResponse.
         * @implements IJackpotsUnifiedResponse
         * @constructor
         * @param {IJackpotsUnifiedResponse=} [properties] Properties to set
         */
        function JackpotsUnifiedResponse(properties) {
            this.jackpots = [];
            this.winners = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * JackpotsUnifiedResponse refreshIntervalInMs.
         * @member {number} refreshIntervalInMs
         * @memberof JackpotsUnifiedResponse
         * @instance
         */
        JackpotsUnifiedResponse.prototype.refreshIntervalInMs = 0;
    
        /**
         * JackpotsUnifiedResponse jackpots.
         * @member {Array.<IJackpotUnifiedInfo>} jackpots
         * @memberof JackpotsUnifiedResponse
         * @instance
         */
        JackpotsUnifiedResponse.prototype.jackpots = $util.emptyArray;
    
        /**
         * JackpotsUnifiedResponse winners.
         * @member {Array.<IJackpotUnifiedWinner>} winners
         * @memberof JackpotsUnifiedResponse
         * @instance
         */
        JackpotsUnifiedResponse.prototype.winners = $util.emptyArray;
    
        /**
         * Creates a new JackpotsUnifiedResponse instance using the specified properties.
         * @function create
         * @memberof JackpotsUnifiedResponse
         * @static
         * @param {IJackpotsUnifiedResponse=} [properties] Properties to set
         * @returns {JackpotsUnifiedResponse} JackpotsUnifiedResponse instance
         */
        JackpotsUnifiedResponse.create = function create(properties) {
            return new JackpotsUnifiedResponse(properties);
        };
    
        /**
         * Encodes the specified JackpotsUnifiedResponse message. Does not implicitly {@link JackpotsUnifiedResponse.verify|verify} messages.
         * @function encode
         * @memberof JackpotsUnifiedResponse
         * @static
         * @param {IJackpotsUnifiedResponse} message JackpotsUnifiedResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JackpotsUnifiedResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.refreshIntervalInMs != null && Object.hasOwnProperty.call(message, "refreshIntervalInMs"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.refreshIntervalInMs);
            if (message.jackpots != null && message.jackpots.length)
                for (var i = 0; i < message.jackpots.length; ++i)
                    $root.JackpotUnifiedInfo.encode(message.jackpots[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.winners != null && message.winners.length)
                for (var i = 0; i < message.winners.length; ++i)
                    $root.JackpotUnifiedWinner.encode(message.winners[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified JackpotsUnifiedResponse message, length delimited. Does not implicitly {@link JackpotsUnifiedResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof JackpotsUnifiedResponse
         * @static
         * @param {IJackpotsUnifiedResponse} message JackpotsUnifiedResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JackpotsUnifiedResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a JackpotsUnifiedResponse message from the specified reader or buffer.
         * @function decode
         * @memberof JackpotsUnifiedResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {JackpotsUnifiedResponse} JackpotsUnifiedResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JackpotsUnifiedResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.JackpotsUnifiedResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.refreshIntervalInMs = reader.uint32();
                    break;
                case 10:
                    if (!(message.jackpots && message.jackpots.length))
                        message.jackpots = [];
                    message.jackpots.push($root.JackpotUnifiedInfo.decode(reader, reader.uint32()));
                    break;
                case 11:
                    if (!(message.winners && message.winners.length))
                        message.winners = [];
                    message.winners.push($root.JackpotUnifiedWinner.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a JackpotsUnifiedResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof JackpotsUnifiedResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {JackpotsUnifiedResponse} JackpotsUnifiedResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JackpotsUnifiedResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a JackpotsUnifiedResponse message.
         * @function verify
         * @memberof JackpotsUnifiedResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JackpotsUnifiedResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.refreshIntervalInMs != null && message.hasOwnProperty("refreshIntervalInMs"))
                if (!$util.isInteger(message.refreshIntervalInMs))
                    return "refreshIntervalInMs: integer expected";
            if (message.jackpots != null && message.hasOwnProperty("jackpots")) {
                if (!Array.isArray(message.jackpots))
                    return "jackpots: array expected";
                for (var i = 0; i < message.jackpots.length; ++i) {
                    var error = $root.JackpotUnifiedInfo.verify(message.jackpots[i]);
                    if (error)
                        return "jackpots." + error;
                }
            }
            if (message.winners != null && message.hasOwnProperty("winners")) {
                if (!Array.isArray(message.winners))
                    return "winners: array expected";
                for (var i = 0; i < message.winners.length; ++i) {
                    var error = $root.JackpotUnifiedWinner.verify(message.winners[i]);
                    if (error)
                        return "winners." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a JackpotsUnifiedResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof JackpotsUnifiedResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {JackpotsUnifiedResponse} JackpotsUnifiedResponse
         */
        JackpotsUnifiedResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.JackpotsUnifiedResponse)
                return object;
            var message = new $root.JackpotsUnifiedResponse();
            if (object.refreshIntervalInMs != null)
                message.refreshIntervalInMs = object.refreshIntervalInMs >>> 0;
            if (object.jackpots) {
                if (!Array.isArray(object.jackpots))
                    throw TypeError(".JackpotsUnifiedResponse.jackpots: array expected");
                message.jackpots = [];
                for (var i = 0; i < object.jackpots.length; ++i) {
                    if (typeof object.jackpots[i] !== "object")
                        throw TypeError(".JackpotsUnifiedResponse.jackpots: object expected");
                    message.jackpots[i] = $root.JackpotUnifiedInfo.fromObject(object.jackpots[i]);
                }
            }
            if (object.winners) {
                if (!Array.isArray(object.winners))
                    throw TypeError(".JackpotsUnifiedResponse.winners: array expected");
                message.winners = [];
                for (var i = 0; i < object.winners.length; ++i) {
                    if (typeof object.winners[i] !== "object")
                        throw TypeError(".JackpotsUnifiedResponse.winners: object expected");
                    message.winners[i] = $root.JackpotUnifiedWinner.fromObject(object.winners[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a JackpotsUnifiedResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof JackpotsUnifiedResponse
         * @static
         * @param {JackpotsUnifiedResponse} message JackpotsUnifiedResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JackpotsUnifiedResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.jackpots = [];
                object.winners = [];
            }
            if (options.defaults)
                object.refreshIntervalInMs = 0;
            if (message.refreshIntervalInMs != null && message.hasOwnProperty("refreshIntervalInMs"))
                object.refreshIntervalInMs = message.refreshIntervalInMs;
            if (message.jackpots && message.jackpots.length) {
                object.jackpots = [];
                for (var j = 0; j < message.jackpots.length; ++j)
                    object.jackpots[j] = $root.JackpotUnifiedInfo.toObject(message.jackpots[j], options);
            }
            if (message.winners && message.winners.length) {
                object.winners = [];
                for (var j = 0; j < message.winners.length; ++j)
                    object.winners[j] = $root.JackpotUnifiedWinner.toObject(message.winners[j], options);
            }
            return object;
        };
    
        /**
         * Converts this JackpotsUnifiedResponse to JSON.
         * @function toJSON
         * @memberof JackpotsUnifiedResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JackpotsUnifiedResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return JackpotsUnifiedResponse;
    })();
    
    $root.JackpotUnifiedInfo = (function() {
    
        /**
         * Properties of a JackpotUnifiedInfo.
         * @exports IJackpotUnifiedInfo
         * @interface IJackpotUnifiedInfo
         * @property {string|null} [id] JackpotUnifiedInfo id
         * @property {Long|null} [balance] JackpotUnifiedInfo balance
         * @property {Long|null} [fallValue] JackpotUnifiedInfo fallValue
         * @property {string|null} [formattedAmount] JackpotUnifiedInfo formattedAmount
         * @property {Array.<IJackpotUnifiedOpt>|null} [opts] JackpotUnifiedInfo opts
         */
    
        /**
         * Constructs a new JackpotUnifiedInfo.
         * @exports JackpotUnifiedInfo
         * @classdesc Represents a JackpotUnifiedInfo.
         * @implements IJackpotUnifiedInfo
         * @constructor
         * @param {IJackpotUnifiedInfo=} [properties] Properties to set
         */
        function JackpotUnifiedInfo(properties) {
            this.opts = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * JackpotUnifiedInfo id.
         * @member {string} id
         * @memberof JackpotUnifiedInfo
         * @instance
         */
        JackpotUnifiedInfo.prototype.id = "";
    
        /**
         * JackpotUnifiedInfo balance.
         * @member {Long} balance
         * @memberof JackpotUnifiedInfo
         * @instance
         */
        JackpotUnifiedInfo.prototype.balance = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * JackpotUnifiedInfo fallValue.
         * @member {Long} fallValue
         * @memberof JackpotUnifiedInfo
         * @instance
         */
        JackpotUnifiedInfo.prototype.fallValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * JackpotUnifiedInfo formattedAmount.
         * @member {string} formattedAmount
         * @memberof JackpotUnifiedInfo
         * @instance
         */
        JackpotUnifiedInfo.prototype.formattedAmount = "";
    
        /**
         * JackpotUnifiedInfo opts.
         * @member {Array.<IJackpotUnifiedOpt>} opts
         * @memberof JackpotUnifiedInfo
         * @instance
         */
        JackpotUnifiedInfo.prototype.opts = $util.emptyArray;
    
        /**
         * Creates a new JackpotUnifiedInfo instance using the specified properties.
         * @function create
         * @memberof JackpotUnifiedInfo
         * @static
         * @param {IJackpotUnifiedInfo=} [properties] Properties to set
         * @returns {JackpotUnifiedInfo} JackpotUnifiedInfo instance
         */
        JackpotUnifiedInfo.create = function create(properties) {
            return new JackpotUnifiedInfo(properties);
        };
    
        /**
         * Encodes the specified JackpotUnifiedInfo message. Does not implicitly {@link JackpotUnifiedInfo.verify|verify} messages.
         * @function encode
         * @memberof JackpotUnifiedInfo
         * @static
         * @param {IJackpotUnifiedInfo} message JackpotUnifiedInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JackpotUnifiedInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.balance);
            if (message.fallValue != null && Object.hasOwnProperty.call(message, "fallValue"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.fallValue);
            if (message.formattedAmount != null && Object.hasOwnProperty.call(message, "formattedAmount"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.formattedAmount);
            if (message.opts != null && message.opts.length)
                for (var i = 0; i < message.opts.length; ++i)
                    $root.JackpotUnifiedOpt.encode(message.opts[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified JackpotUnifiedInfo message, length delimited. Does not implicitly {@link JackpotUnifiedInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof JackpotUnifiedInfo
         * @static
         * @param {IJackpotUnifiedInfo} message JackpotUnifiedInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JackpotUnifiedInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a JackpotUnifiedInfo message from the specified reader or buffer.
         * @function decode
         * @memberof JackpotUnifiedInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {JackpotUnifiedInfo} JackpotUnifiedInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JackpotUnifiedInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.JackpotUnifiedInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.balance = reader.uint64();
                    break;
                case 3:
                    message.fallValue = reader.uint64();
                    break;
                case 4:
                    message.formattedAmount = reader.string();
                    break;
                case 10:
                    if (!(message.opts && message.opts.length))
                        message.opts = [];
                    message.opts.push($root.JackpotUnifiedOpt.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a JackpotUnifiedInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof JackpotUnifiedInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {JackpotUnifiedInfo} JackpotUnifiedInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JackpotUnifiedInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a JackpotUnifiedInfo message.
         * @function verify
         * @memberof JackpotUnifiedInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JackpotUnifiedInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (!$util.isInteger(message.balance) && !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high)))
                    return "balance: integer|Long expected";
            if (message.fallValue != null && message.hasOwnProperty("fallValue"))
                if (!$util.isInteger(message.fallValue) && !(message.fallValue && $util.isInteger(message.fallValue.low) && $util.isInteger(message.fallValue.high)))
                    return "fallValue: integer|Long expected";
            if (message.formattedAmount != null && message.hasOwnProperty("formattedAmount"))
                if (!$util.isString(message.formattedAmount))
                    return "formattedAmount: string expected";
            if (message.opts != null && message.hasOwnProperty("opts")) {
                if (!Array.isArray(message.opts))
                    return "opts: array expected";
                for (var i = 0; i < message.opts.length; ++i) {
                    var error = $root.JackpotUnifiedOpt.verify(message.opts[i]);
                    if (error)
                        return "opts." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a JackpotUnifiedInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof JackpotUnifiedInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {JackpotUnifiedInfo} JackpotUnifiedInfo
         */
        JackpotUnifiedInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.JackpotUnifiedInfo)
                return object;
            var message = new $root.JackpotUnifiedInfo();
            if (object.id != null)
                message.id = String(object.id);
            if (object.balance != null)
                if ($util.Long)
                    (message.balance = $util.Long.fromValue(object.balance)).unsigned = true;
                else if (typeof object.balance === "string")
                    message.balance = parseInt(object.balance, 10);
                else if (typeof object.balance === "number")
                    message.balance = object.balance;
                else if (typeof object.balance === "object")
                    message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber(true);
            if (object.fallValue != null)
                if ($util.Long)
                    (message.fallValue = $util.Long.fromValue(object.fallValue)).unsigned = true;
                else if (typeof object.fallValue === "string")
                    message.fallValue = parseInt(object.fallValue, 10);
                else if (typeof object.fallValue === "number")
                    message.fallValue = object.fallValue;
                else if (typeof object.fallValue === "object")
                    message.fallValue = new $util.LongBits(object.fallValue.low >>> 0, object.fallValue.high >>> 0).toNumber(true);
            if (object.formattedAmount != null)
                message.formattedAmount = String(object.formattedAmount);
            if (object.opts) {
                if (!Array.isArray(object.opts))
                    throw TypeError(".JackpotUnifiedInfo.opts: array expected");
                message.opts = [];
                for (var i = 0; i < object.opts.length; ++i) {
                    if (typeof object.opts[i] !== "object")
                        throw TypeError(".JackpotUnifiedInfo.opts: object expected");
                    message.opts[i] = $root.JackpotUnifiedOpt.fromObject(object.opts[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a JackpotUnifiedInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof JackpotUnifiedInfo
         * @static
         * @param {JackpotUnifiedInfo} message JackpotUnifiedInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JackpotUnifiedInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.opts = [];
            if (options.defaults) {
                object.id = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.balance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.balance = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.fallValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fallValue = options.longs === String ? "0" : 0;
                object.formattedAmount = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (typeof message.balance === "number")
                    object.balance = options.longs === String ? String(message.balance) : message.balance;
                else
                    object.balance = options.longs === String ? $util.Long.prototype.toString.call(message.balance) : options.longs === Number ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber(true) : message.balance;
            if (message.fallValue != null && message.hasOwnProperty("fallValue"))
                if (typeof message.fallValue === "number")
                    object.fallValue = options.longs === String ? String(message.fallValue) : message.fallValue;
                else
                    object.fallValue = options.longs === String ? $util.Long.prototype.toString.call(message.fallValue) : options.longs === Number ? new $util.LongBits(message.fallValue.low >>> 0, message.fallValue.high >>> 0).toNumber(true) : message.fallValue;
            if (message.formattedAmount != null && message.hasOwnProperty("formattedAmount"))
                object.formattedAmount = message.formattedAmount;
            if (message.opts && message.opts.length) {
                object.opts = [];
                for (var j = 0; j < message.opts.length; ++j)
                    object.opts[j] = $root.JackpotUnifiedOpt.toObject(message.opts[j], options);
            }
            return object;
        };
    
        /**
         * Converts this JackpotUnifiedInfo to JSON.
         * @function toJSON
         * @memberof JackpotUnifiedInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JackpotUnifiedInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return JackpotUnifiedInfo;
    })();
    
    $root.JackpotUnifiedWinner = (function() {
    
        /**
         * Properties of a JackpotUnifiedWinner.
         * @exports IJackpotUnifiedWinner
         * @interface IJackpotUnifiedWinner
         * @property {string|null} [winId] JackpotUnifiedWinner winId
         * @property {string|null} [jackpotId] JackpotUnifiedWinner jackpotId
         * @property {Long|null} [stamp] JackpotUnifiedWinner stamp
         * @property {string|null} [barcode] JackpotUnifiedWinner barcode
         * @property {Long|null} [winAmount] JackpotUnifiedWinner winAmount
         * @property {string|null} [formattedWinAmount] JackpotUnifiedWinner formattedWinAmount
         */
    
        /**
         * Constructs a new JackpotUnifiedWinner.
         * @exports JackpotUnifiedWinner
         * @classdesc Represents a JackpotUnifiedWinner.
         * @implements IJackpotUnifiedWinner
         * @constructor
         * @param {IJackpotUnifiedWinner=} [properties] Properties to set
         */
        function JackpotUnifiedWinner(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * JackpotUnifiedWinner winId.
         * @member {string} winId
         * @memberof JackpotUnifiedWinner
         * @instance
         */
        JackpotUnifiedWinner.prototype.winId = "";
    
        /**
         * JackpotUnifiedWinner jackpotId.
         * @member {string} jackpotId
         * @memberof JackpotUnifiedWinner
         * @instance
         */
        JackpotUnifiedWinner.prototype.jackpotId = "";
    
        /**
         * JackpotUnifiedWinner stamp.
         * @member {Long} stamp
         * @memberof JackpotUnifiedWinner
         * @instance
         */
        JackpotUnifiedWinner.prototype.stamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * JackpotUnifiedWinner barcode.
         * @member {string} barcode
         * @memberof JackpotUnifiedWinner
         * @instance
         */
        JackpotUnifiedWinner.prototype.barcode = "";
    
        /**
         * JackpotUnifiedWinner winAmount.
         * @member {Long} winAmount
         * @memberof JackpotUnifiedWinner
         * @instance
         */
        JackpotUnifiedWinner.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * JackpotUnifiedWinner formattedWinAmount.
         * @member {string} formattedWinAmount
         * @memberof JackpotUnifiedWinner
         * @instance
         */
        JackpotUnifiedWinner.prototype.formattedWinAmount = "";
    
        /**
         * Creates a new JackpotUnifiedWinner instance using the specified properties.
         * @function create
         * @memberof JackpotUnifiedWinner
         * @static
         * @param {IJackpotUnifiedWinner=} [properties] Properties to set
         * @returns {JackpotUnifiedWinner} JackpotUnifiedWinner instance
         */
        JackpotUnifiedWinner.create = function create(properties) {
            return new JackpotUnifiedWinner(properties);
        };
    
        /**
         * Encodes the specified JackpotUnifiedWinner message. Does not implicitly {@link JackpotUnifiedWinner.verify|verify} messages.
         * @function encode
         * @memberof JackpotUnifiedWinner
         * @static
         * @param {IJackpotUnifiedWinner} message JackpotUnifiedWinner message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JackpotUnifiedWinner.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.winId != null && Object.hasOwnProperty.call(message, "winId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.winId);
            if (message.jackpotId != null && Object.hasOwnProperty.call(message, "jackpotId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.jackpotId);
            if (message.stamp != null && Object.hasOwnProperty.call(message, "stamp"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.stamp);
            if (message.barcode != null && Object.hasOwnProperty.call(message, "barcode"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.barcode);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.winAmount);
            if (message.formattedWinAmount != null && Object.hasOwnProperty.call(message, "formattedWinAmount"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.formattedWinAmount);
            return writer;
        };
    
        /**
         * Encodes the specified JackpotUnifiedWinner message, length delimited. Does not implicitly {@link JackpotUnifiedWinner.verify|verify} messages.
         * @function encodeDelimited
         * @memberof JackpotUnifiedWinner
         * @static
         * @param {IJackpotUnifiedWinner} message JackpotUnifiedWinner message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JackpotUnifiedWinner.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a JackpotUnifiedWinner message from the specified reader or buffer.
         * @function decode
         * @memberof JackpotUnifiedWinner
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {JackpotUnifiedWinner} JackpotUnifiedWinner
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JackpotUnifiedWinner.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.JackpotUnifiedWinner();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.winId = reader.string();
                    break;
                case 2:
                    message.jackpotId = reader.string();
                    break;
                case 3:
                    message.stamp = reader.uint64();
                    break;
                case 4:
                    message.barcode = reader.string();
                    break;
                case 10:
                    message.winAmount = reader.uint64();
                    break;
                case 11:
                    message.formattedWinAmount = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a JackpotUnifiedWinner message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof JackpotUnifiedWinner
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {JackpotUnifiedWinner} JackpotUnifiedWinner
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JackpotUnifiedWinner.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a JackpotUnifiedWinner message.
         * @function verify
         * @memberof JackpotUnifiedWinner
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JackpotUnifiedWinner.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.winId != null && message.hasOwnProperty("winId"))
                if (!$util.isString(message.winId))
                    return "winId: string expected";
            if (message.jackpotId != null && message.hasOwnProperty("jackpotId"))
                if (!$util.isString(message.jackpotId))
                    return "jackpotId: string expected";
            if (message.stamp != null && message.hasOwnProperty("stamp"))
                if (!$util.isInteger(message.stamp) && !(message.stamp && $util.isInteger(message.stamp.low) && $util.isInteger(message.stamp.high)))
                    return "stamp: integer|Long expected";
            if (message.barcode != null && message.hasOwnProperty("barcode"))
                if (!$util.isString(message.barcode))
                    return "barcode: string expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.formattedWinAmount != null && message.hasOwnProperty("formattedWinAmount"))
                if (!$util.isString(message.formattedWinAmount))
                    return "formattedWinAmount: string expected";
            return null;
        };
    
        /**
         * Creates a JackpotUnifiedWinner message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof JackpotUnifiedWinner
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {JackpotUnifiedWinner} JackpotUnifiedWinner
         */
        JackpotUnifiedWinner.fromObject = function fromObject(object) {
            if (object instanceof $root.JackpotUnifiedWinner)
                return object;
            var message = new $root.JackpotUnifiedWinner();
            if (object.winId != null)
                message.winId = String(object.winId);
            if (object.jackpotId != null)
                message.jackpotId = String(object.jackpotId);
            if (object.stamp != null)
                if ($util.Long)
                    (message.stamp = $util.Long.fromValue(object.stamp)).unsigned = true;
                else if (typeof object.stamp === "string")
                    message.stamp = parseInt(object.stamp, 10);
                else if (typeof object.stamp === "number")
                    message.stamp = object.stamp;
                else if (typeof object.stamp === "object")
                    message.stamp = new $util.LongBits(object.stamp.low >>> 0, object.stamp.high >>> 0).toNumber(true);
            if (object.barcode != null)
                message.barcode = String(object.barcode);
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = true;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber(true);
            if (object.formattedWinAmount != null)
                message.formattedWinAmount = String(object.formattedWinAmount);
            return message;
        };
    
        /**
         * Creates a plain object from a JackpotUnifiedWinner message. Also converts values to other types if specified.
         * @function toObject
         * @memberof JackpotUnifiedWinner
         * @static
         * @param {JackpotUnifiedWinner} message JackpotUnifiedWinner
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JackpotUnifiedWinner.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.winId = "";
                object.jackpotId = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.stamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.stamp = options.longs === String ? "0" : 0;
                object.barcode = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                object.formattedWinAmount = "";
            }
            if (message.winId != null && message.hasOwnProperty("winId"))
                object.winId = message.winId;
            if (message.jackpotId != null && message.hasOwnProperty("jackpotId"))
                object.jackpotId = message.jackpotId;
            if (message.stamp != null && message.hasOwnProperty("stamp"))
                if (typeof message.stamp === "number")
                    object.stamp = options.longs === String ? String(message.stamp) : message.stamp;
                else
                    object.stamp = options.longs === String ? $util.Long.prototype.toString.call(message.stamp) : options.longs === Number ? new $util.LongBits(message.stamp.low >>> 0, message.stamp.high >>> 0).toNumber(true) : message.stamp;
            if (message.barcode != null && message.hasOwnProperty("barcode"))
                object.barcode = message.barcode;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber(true) : message.winAmount;
            if (message.formattedWinAmount != null && message.hasOwnProperty("formattedWinAmount"))
                object.formattedWinAmount = message.formattedWinAmount;
            return object;
        };
    
        /**
         * Converts this JackpotUnifiedWinner to JSON.
         * @function toJSON
         * @memberof JackpotUnifiedWinner
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JackpotUnifiedWinner.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return JackpotUnifiedWinner;
    })();
    
    $root.JackpotUnifiedOpt = (function() {
    
        /**
         * Properties of a JackpotUnifiedOpt.
         * @exports IJackpotUnifiedOpt
         * @interface IJackpotUnifiedOpt
         * @property {JackpotUnifiedOpt.Type|null} [optType] JackpotUnifiedOpt optType
         * @property {boolean|null} [valBool] JackpotUnifiedOpt valBool
         * @property {Long|null} [valUInt] JackpotUnifiedOpt valUInt
         * @property {string|null} [valStr] JackpotUnifiedOpt valStr
         */
    
        /**
         * Constructs a new JackpotUnifiedOpt.
         * @exports JackpotUnifiedOpt
         * @classdesc Represents a JackpotUnifiedOpt.
         * @implements IJackpotUnifiedOpt
         * @constructor
         * @param {IJackpotUnifiedOpt=} [properties] Properties to set
         */
        function JackpotUnifiedOpt(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * JackpotUnifiedOpt optType.
         * @member {JackpotUnifiedOpt.Type} optType
         * @memberof JackpotUnifiedOpt
         * @instance
         */
        JackpotUnifiedOpt.prototype.optType = 0;
    
        /**
         * JackpotUnifiedOpt valBool.
         * @member {boolean} valBool
         * @memberof JackpotUnifiedOpt
         * @instance
         */
        JackpotUnifiedOpt.prototype.valBool = false;
    
        /**
         * JackpotUnifiedOpt valUInt.
         * @member {Long} valUInt
         * @memberof JackpotUnifiedOpt
         * @instance
         */
        JackpotUnifiedOpt.prototype.valUInt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * JackpotUnifiedOpt valStr.
         * @member {string} valStr
         * @memberof JackpotUnifiedOpt
         * @instance
         */
        JackpotUnifiedOpt.prototype.valStr = "";
    
        /**
         * Creates a new JackpotUnifiedOpt instance using the specified properties.
         * @function create
         * @memberof JackpotUnifiedOpt
         * @static
         * @param {IJackpotUnifiedOpt=} [properties] Properties to set
         * @returns {JackpotUnifiedOpt} JackpotUnifiedOpt instance
         */
        JackpotUnifiedOpt.create = function create(properties) {
            return new JackpotUnifiedOpt(properties);
        };
    
        /**
         * Encodes the specified JackpotUnifiedOpt message. Does not implicitly {@link JackpotUnifiedOpt.verify|verify} messages.
         * @function encode
         * @memberof JackpotUnifiedOpt
         * @static
         * @param {IJackpotUnifiedOpt} message JackpotUnifiedOpt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JackpotUnifiedOpt.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.optType != null && Object.hasOwnProperty.call(message, "optType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.optType);
            if (message.valBool != null && Object.hasOwnProperty.call(message, "valBool"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.valBool);
            if (message.valUInt != null && Object.hasOwnProperty.call(message, "valUInt"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.valUInt);
            if (message.valStr != null && Object.hasOwnProperty.call(message, "valStr"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.valStr);
            return writer;
        };
    
        /**
         * Encodes the specified JackpotUnifiedOpt message, length delimited. Does not implicitly {@link JackpotUnifiedOpt.verify|verify} messages.
         * @function encodeDelimited
         * @memberof JackpotUnifiedOpt
         * @static
         * @param {IJackpotUnifiedOpt} message JackpotUnifiedOpt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JackpotUnifiedOpt.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a JackpotUnifiedOpt message from the specified reader or buffer.
         * @function decode
         * @memberof JackpotUnifiedOpt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {JackpotUnifiedOpt} JackpotUnifiedOpt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JackpotUnifiedOpt.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.JackpotUnifiedOpt();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.optType = reader.int32();
                    break;
                case 2:
                    message.valBool = reader.bool();
                    break;
                case 3:
                    message.valUInt = reader.uint64();
                    break;
                case 4:
                    message.valStr = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a JackpotUnifiedOpt message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof JackpotUnifiedOpt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {JackpotUnifiedOpt} JackpotUnifiedOpt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JackpotUnifiedOpt.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a JackpotUnifiedOpt message.
         * @function verify
         * @memberof JackpotUnifiedOpt
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JackpotUnifiedOpt.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.optType != null && message.hasOwnProperty("optType"))
                switch (message.optType) {
                default:
                    return "optType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                    break;
                }
            if (message.valBool != null && message.hasOwnProperty("valBool"))
                if (typeof message.valBool !== "boolean")
                    return "valBool: boolean expected";
            if (message.valUInt != null && message.hasOwnProperty("valUInt"))
                if (!$util.isInteger(message.valUInt) && !(message.valUInt && $util.isInteger(message.valUInt.low) && $util.isInteger(message.valUInt.high)))
                    return "valUInt: integer|Long expected";
            if (message.valStr != null && message.hasOwnProperty("valStr"))
                if (!$util.isString(message.valStr))
                    return "valStr: string expected";
            return null;
        };
    
        /**
         * Creates a JackpotUnifiedOpt message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof JackpotUnifiedOpt
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {JackpotUnifiedOpt} JackpotUnifiedOpt
         */
        JackpotUnifiedOpt.fromObject = function fromObject(object) {
            if (object instanceof $root.JackpotUnifiedOpt)
                return object;
            var message = new $root.JackpotUnifiedOpt();
            switch (object.optType) {
            case "JO_HIDE_PROGRESS":
            case 0:
                message.optType = 0;
                break;
            case "JO_MONITOR_STYLE":
            case 1:
                message.optType = 1;
                break;
            case "JO_DISABLE_IN_MONITOR":
            case 2:
                message.optType = 2;
                break;
            case "JO_SORT_WEIGHT":
            case 3:
                message.optType = 3;
                break;
            case "JO_VISUAL_TYPE":
            case 4:
                message.optType = 4;
                break;
            case "JO_MIN_BET":
            case 5:
                message.optType = 5;
                break;
            case "JO_NAME":
            case 6:
                message.optType = 6;
                break;
            case "JO_SOURCE":
            case 7:
                message.optType = 7;
                break;
            case "JO_BLINK_DISABLED":
            case 8:
                message.optType = 8;
                break;
            }
            if (object.valBool != null)
                message.valBool = Boolean(object.valBool);
            if (object.valUInt != null)
                if ($util.Long)
                    (message.valUInt = $util.Long.fromValue(object.valUInt)).unsigned = true;
                else if (typeof object.valUInt === "string")
                    message.valUInt = parseInt(object.valUInt, 10);
                else if (typeof object.valUInt === "number")
                    message.valUInt = object.valUInt;
                else if (typeof object.valUInt === "object")
                    message.valUInt = new $util.LongBits(object.valUInt.low >>> 0, object.valUInt.high >>> 0).toNumber(true);
            if (object.valStr != null)
                message.valStr = String(object.valStr);
            return message;
        };
    
        /**
         * Creates a plain object from a JackpotUnifiedOpt message. Also converts values to other types if specified.
         * @function toObject
         * @memberof JackpotUnifiedOpt
         * @static
         * @param {JackpotUnifiedOpt} message JackpotUnifiedOpt
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JackpotUnifiedOpt.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.optType = options.enums === String ? "JO_HIDE_PROGRESS" : 0;
                object.valBool = false;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.valUInt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.valUInt = options.longs === String ? "0" : 0;
                object.valStr = "";
            }
            if (message.optType != null && message.hasOwnProperty("optType"))
                object.optType = options.enums === String ? $root.JackpotUnifiedOpt.Type[message.optType] : message.optType;
            if (message.valBool != null && message.hasOwnProperty("valBool"))
                object.valBool = message.valBool;
            if (message.valUInt != null && message.hasOwnProperty("valUInt"))
                if (typeof message.valUInt === "number")
                    object.valUInt = options.longs === String ? String(message.valUInt) : message.valUInt;
                else
                    object.valUInt = options.longs === String ? $util.Long.prototype.toString.call(message.valUInt) : options.longs === Number ? new $util.LongBits(message.valUInt.low >>> 0, message.valUInt.high >>> 0).toNumber(true) : message.valUInt;
            if (message.valStr != null && message.hasOwnProperty("valStr"))
                object.valStr = message.valStr;
            return object;
        };
    
        /**
         * Converts this JackpotUnifiedOpt to JSON.
         * @function toJSON
         * @memberof JackpotUnifiedOpt
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JackpotUnifiedOpt.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        /**
         * VisualType enum.
         * @name JackpotUnifiedOpt.VisualType
         * @enum {number}
         * @property {number} JVT_DEFAULT=0 JVT_DEFAULT value
         * @property {number} JVT_GLOBAL=1 JVT_GLOBAL value
         */
        JackpotUnifiedOpt.VisualType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "JVT_DEFAULT"] = 0;
            values[valuesById[1] = "JVT_GLOBAL"] = 1;
            return values;
        })();
    
        /**
         * Type enum.
         * @name JackpotUnifiedOpt.Type
         * @enum {number}
         * @property {number} JO_HIDE_PROGRESS=0 JO_HIDE_PROGRESS value
         * @property {number} JO_MONITOR_STYLE=1 JO_MONITOR_STYLE value
         * @property {number} JO_DISABLE_IN_MONITOR=2 JO_DISABLE_IN_MONITOR value
         * @property {number} JO_SORT_WEIGHT=3 JO_SORT_WEIGHT value
         * @property {number} JO_VISUAL_TYPE=4 JO_VISUAL_TYPE value
         * @property {number} JO_MIN_BET=5 JO_MIN_BET value
         * @property {number} JO_NAME=6 JO_NAME value
         * @property {number} JO_SOURCE=7 JO_SOURCE value
         * @property {number} JO_BLINK_DISABLED=8 JO_BLINK_DISABLED value
         */
        JackpotUnifiedOpt.Type = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "JO_HIDE_PROGRESS"] = 0;
            values[valuesById[1] = "JO_MONITOR_STYLE"] = 1;
            values[valuesById[2] = "JO_DISABLE_IN_MONITOR"] = 2;
            values[valuesById[3] = "JO_SORT_WEIGHT"] = 3;
            values[valuesById[4] = "JO_VISUAL_TYPE"] = 4;
            values[valuesById[5] = "JO_MIN_BET"] = 5;
            values[valuesById[6] = "JO_NAME"] = 6;
            values[valuesById[7] = "JO_SOURCE"] = 7;
            values[valuesById[8] = "JO_BLINK_DISABLED"] = 8;
            return values;
        })();
    
        return JackpotUnifiedOpt;
    })();
    
    $root.UserSlipData = (function() {
    
        /**
         * Properties of a UserSlipData.
         * @exports IUserSlipData
         * @interface IUserSlipData
         * @property {Long|null} [id] UserSlipData id
         * @property {string|null} [barcode] UserSlipData barcode
         * @property {UserSlipData.SlipType|null} [type] UserSlipData type
         * @property {UserSlipData.SlipStatus|null} [status] UserSlipData status
         * @property {Long|null} [acceptedAt] UserSlipData acceptedAt
         * @property {Long|null} [paidAt] UserSlipData paidAt
         * @property {Long|null} [paidAmount] UserSlipData paidAmount
         * @property {Long|null} [possibleWinAmount] UserSlipData possibleWinAmount
         * @property {Long|null} [winAmount] UserSlipData winAmount
         * @property {Long|null} [expressAmount] UserSlipData expressAmount
         * @property {Long|null} [jackpotAmount] UserSlipData jackpotAmount
         * @property {Long|null} [totalWinAmount] UserSlipData totalWinAmount
         * @property {Array.<UserSlipData.IStake>|null} [stakes] UserSlipData stakes
         */
    
        /**
         * Constructs a new UserSlipData.
         * @exports UserSlipData
         * @classdesc Represents a UserSlipData.
         * @implements IUserSlipData
         * @constructor
         * @param {IUserSlipData=} [properties] Properties to set
         */
        function UserSlipData(properties) {
            this.stakes = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * UserSlipData id.
         * @member {Long} id
         * @memberof UserSlipData
         * @instance
         */
        UserSlipData.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * UserSlipData barcode.
         * @member {string} barcode
         * @memberof UserSlipData
         * @instance
         */
        UserSlipData.prototype.barcode = "";
    
        /**
         * UserSlipData type.
         * @member {UserSlipData.SlipType} type
         * @memberof UserSlipData
         * @instance
         */
        UserSlipData.prototype.type = 0;
    
        /**
         * UserSlipData status.
         * @member {UserSlipData.SlipStatus} status
         * @memberof UserSlipData
         * @instance
         */
        UserSlipData.prototype.status = 0;
    
        /**
         * UserSlipData acceptedAt.
         * @member {Long} acceptedAt
         * @memberof UserSlipData
         * @instance
         */
        UserSlipData.prototype.acceptedAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * UserSlipData paidAt.
         * @member {Long} paidAt
         * @memberof UserSlipData
         * @instance
         */
        UserSlipData.prototype.paidAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * UserSlipData paidAmount.
         * @member {Long} paidAmount
         * @memberof UserSlipData
         * @instance
         */
        UserSlipData.prototype.paidAmount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * UserSlipData possibleWinAmount.
         * @member {Long} possibleWinAmount
         * @memberof UserSlipData
         * @instance
         */
        UserSlipData.prototype.possibleWinAmount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * UserSlipData winAmount.
         * @member {Long} winAmount
         * @memberof UserSlipData
         * @instance
         */
        UserSlipData.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * UserSlipData expressAmount.
         * @member {Long} expressAmount
         * @memberof UserSlipData
         * @instance
         */
        UserSlipData.prototype.expressAmount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * UserSlipData jackpotAmount.
         * @member {Long} jackpotAmount
         * @memberof UserSlipData
         * @instance
         */
        UserSlipData.prototype.jackpotAmount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * UserSlipData totalWinAmount.
         * @member {Long} totalWinAmount
         * @memberof UserSlipData
         * @instance
         */
        UserSlipData.prototype.totalWinAmount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * UserSlipData stakes.
         * @member {Array.<UserSlipData.IStake>} stakes
         * @memberof UserSlipData
         * @instance
         */
        UserSlipData.prototype.stakes = $util.emptyArray;
    
        /**
         * Creates a new UserSlipData instance using the specified properties.
         * @function create
         * @memberof UserSlipData
         * @static
         * @param {IUserSlipData=} [properties] Properties to set
         * @returns {UserSlipData} UserSlipData instance
         */
        UserSlipData.create = function create(properties) {
            return new UserSlipData(properties);
        };
    
        /**
         * Encodes the specified UserSlipData message. Does not implicitly {@link UserSlipData.verify|verify} messages.
         * @function encode
         * @memberof UserSlipData
         * @static
         * @param {IUserSlipData} message UserSlipData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserSlipData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
            if (message.barcode != null && Object.hasOwnProperty.call(message, "barcode"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.barcode);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.status);
            if (message.acceptedAt != null && Object.hasOwnProperty.call(message, "acceptedAt"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.acceptedAt);
            if (message.paidAt != null && Object.hasOwnProperty.call(message, "paidAt"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.paidAt);
            if (message.paidAmount != null && Object.hasOwnProperty.call(message, "paidAmount"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.paidAmount);
            if (message.possibleWinAmount != null && Object.hasOwnProperty.call(message, "possibleWinAmount"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.possibleWinAmount);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.winAmount);
            if (message.expressAmount != null && Object.hasOwnProperty.call(message, "expressAmount"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.expressAmount);
            if (message.jackpotAmount != null && Object.hasOwnProperty.call(message, "jackpotAmount"))
                writer.uint32(/* id 11, wireType 0 =*/88).uint64(message.jackpotAmount);
            if (message.totalWinAmount != null && Object.hasOwnProperty.call(message, "totalWinAmount"))
                writer.uint32(/* id 12, wireType 0 =*/96).uint64(message.totalWinAmount);
            if (message.stakes != null && message.stakes.length)
                for (var i = 0; i < message.stakes.length; ++i)
                    $root.UserSlipData.Stake.encode(message.stakes[i], writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified UserSlipData message, length delimited. Does not implicitly {@link UserSlipData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof UserSlipData
         * @static
         * @param {IUserSlipData} message UserSlipData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserSlipData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a UserSlipData message from the specified reader or buffer.
         * @function decode
         * @memberof UserSlipData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {UserSlipData} UserSlipData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserSlipData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UserSlipData();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.uint64();
                    break;
                case 2:
                    message.barcode = reader.string();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                case 4:
                    message.status = reader.int32();
                    break;
                case 5:
                    message.acceptedAt = reader.uint64();
                    break;
                case 6:
                    message.paidAt = reader.uint64();
                    break;
                case 7:
                    message.paidAmount = reader.uint64();
                    break;
                case 8:
                    message.possibleWinAmount = reader.uint64();
                    break;
                case 9:
                    message.winAmount = reader.uint64();
                    break;
                case 10:
                    message.expressAmount = reader.uint64();
                    break;
                case 11:
                    message.jackpotAmount = reader.uint64();
                    break;
                case 12:
                    message.totalWinAmount = reader.uint64();
                    break;
                case 20:
                    if (!(message.stakes && message.stakes.length))
                        message.stakes = [];
                    message.stakes.push($root.UserSlipData.Stake.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a UserSlipData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof UserSlipData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {UserSlipData} UserSlipData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserSlipData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a UserSlipData message.
         * @function verify
         * @memberof UserSlipData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserSlipData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                    return "id: integer|Long expected";
            if (message.barcode != null && message.hasOwnProperty("barcode"))
                if (!$util.isString(message.barcode))
                    return "barcode: string expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.acceptedAt != null && message.hasOwnProperty("acceptedAt"))
                if (!$util.isInteger(message.acceptedAt) && !(message.acceptedAt && $util.isInteger(message.acceptedAt.low) && $util.isInteger(message.acceptedAt.high)))
                    return "acceptedAt: integer|Long expected";
            if (message.paidAt != null && message.hasOwnProperty("paidAt"))
                if (!$util.isInteger(message.paidAt) && !(message.paidAt && $util.isInteger(message.paidAt.low) && $util.isInteger(message.paidAt.high)))
                    return "paidAt: integer|Long expected";
            if (message.paidAmount != null && message.hasOwnProperty("paidAmount"))
                if (!$util.isInteger(message.paidAmount) && !(message.paidAmount && $util.isInteger(message.paidAmount.low) && $util.isInteger(message.paidAmount.high)))
                    return "paidAmount: integer|Long expected";
            if (message.possibleWinAmount != null && message.hasOwnProperty("possibleWinAmount"))
                if (!$util.isInteger(message.possibleWinAmount) && !(message.possibleWinAmount && $util.isInteger(message.possibleWinAmount.low) && $util.isInteger(message.possibleWinAmount.high)))
                    return "possibleWinAmount: integer|Long expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.expressAmount != null && message.hasOwnProperty("expressAmount"))
                if (!$util.isInteger(message.expressAmount) && !(message.expressAmount && $util.isInteger(message.expressAmount.low) && $util.isInteger(message.expressAmount.high)))
                    return "expressAmount: integer|Long expected";
            if (message.jackpotAmount != null && message.hasOwnProperty("jackpotAmount"))
                if (!$util.isInteger(message.jackpotAmount) && !(message.jackpotAmount && $util.isInteger(message.jackpotAmount.low) && $util.isInteger(message.jackpotAmount.high)))
                    return "jackpotAmount: integer|Long expected";
            if (message.totalWinAmount != null && message.hasOwnProperty("totalWinAmount"))
                if (!$util.isInteger(message.totalWinAmount) && !(message.totalWinAmount && $util.isInteger(message.totalWinAmount.low) && $util.isInteger(message.totalWinAmount.high)))
                    return "totalWinAmount: integer|Long expected";
            if (message.stakes != null && message.hasOwnProperty("stakes")) {
                if (!Array.isArray(message.stakes))
                    return "stakes: array expected";
                for (var i = 0; i < message.stakes.length; ++i) {
                    var error = $root.UserSlipData.Stake.verify(message.stakes[i]);
                    if (error)
                        return "stakes." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a UserSlipData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof UserSlipData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {UserSlipData} UserSlipData
         */
        UserSlipData.fromObject = function fromObject(object) {
            if (object instanceof $root.UserSlipData)
                return object;
            var message = new $root.UserSlipData();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.barcode != null)
                message.barcode = String(object.barcode);
            switch (object.type) {
            case "ST_ORDINAL":
            case 0:
                message.type = 0;
                break;
            case "ST_EXPRESS":
            case 1:
                message.type = 1;
                break;
            }
            switch (object.status) {
            case "SS_NOT_CALCULATED":
            case 0:
                message.status = 0;
                break;
            case "SS_LOST":
            case 1:
                message.status = 1;
                break;
            case "SS_WIN":
            case 2:
                message.status = 2;
                break;
            case "SS_PAID":
            case 3:
                message.status = 3;
                break;
            }
            if (object.acceptedAt != null)
                if ($util.Long)
                    (message.acceptedAt = $util.Long.fromValue(object.acceptedAt)).unsigned = true;
                else if (typeof object.acceptedAt === "string")
                    message.acceptedAt = parseInt(object.acceptedAt, 10);
                else if (typeof object.acceptedAt === "number")
                    message.acceptedAt = object.acceptedAt;
                else if (typeof object.acceptedAt === "object")
                    message.acceptedAt = new $util.LongBits(object.acceptedAt.low >>> 0, object.acceptedAt.high >>> 0).toNumber(true);
            if (object.paidAt != null)
                if ($util.Long)
                    (message.paidAt = $util.Long.fromValue(object.paidAt)).unsigned = true;
                else if (typeof object.paidAt === "string")
                    message.paidAt = parseInt(object.paidAt, 10);
                else if (typeof object.paidAt === "number")
                    message.paidAt = object.paidAt;
                else if (typeof object.paidAt === "object")
                    message.paidAt = new $util.LongBits(object.paidAt.low >>> 0, object.paidAt.high >>> 0).toNumber(true);
            if (object.paidAmount != null)
                if ($util.Long)
                    (message.paidAmount = $util.Long.fromValue(object.paidAmount)).unsigned = true;
                else if (typeof object.paidAmount === "string")
                    message.paidAmount = parseInt(object.paidAmount, 10);
                else if (typeof object.paidAmount === "number")
                    message.paidAmount = object.paidAmount;
                else if (typeof object.paidAmount === "object")
                    message.paidAmount = new $util.LongBits(object.paidAmount.low >>> 0, object.paidAmount.high >>> 0).toNumber(true);
            if (object.possibleWinAmount != null)
                if ($util.Long)
                    (message.possibleWinAmount = $util.Long.fromValue(object.possibleWinAmount)).unsigned = true;
                else if (typeof object.possibleWinAmount === "string")
                    message.possibleWinAmount = parseInt(object.possibleWinAmount, 10);
                else if (typeof object.possibleWinAmount === "number")
                    message.possibleWinAmount = object.possibleWinAmount;
                else if (typeof object.possibleWinAmount === "object")
                    message.possibleWinAmount = new $util.LongBits(object.possibleWinAmount.low >>> 0, object.possibleWinAmount.high >>> 0).toNumber(true);
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = true;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber(true);
            if (object.expressAmount != null)
                if ($util.Long)
                    (message.expressAmount = $util.Long.fromValue(object.expressAmount)).unsigned = true;
                else if (typeof object.expressAmount === "string")
                    message.expressAmount = parseInt(object.expressAmount, 10);
                else if (typeof object.expressAmount === "number")
                    message.expressAmount = object.expressAmount;
                else if (typeof object.expressAmount === "object")
                    message.expressAmount = new $util.LongBits(object.expressAmount.low >>> 0, object.expressAmount.high >>> 0).toNumber(true);
            if (object.jackpotAmount != null)
                if ($util.Long)
                    (message.jackpotAmount = $util.Long.fromValue(object.jackpotAmount)).unsigned = true;
                else if (typeof object.jackpotAmount === "string")
                    message.jackpotAmount = parseInt(object.jackpotAmount, 10);
                else if (typeof object.jackpotAmount === "number")
                    message.jackpotAmount = object.jackpotAmount;
                else if (typeof object.jackpotAmount === "object")
                    message.jackpotAmount = new $util.LongBits(object.jackpotAmount.low >>> 0, object.jackpotAmount.high >>> 0).toNumber(true);
            if (object.totalWinAmount != null)
                if ($util.Long)
                    (message.totalWinAmount = $util.Long.fromValue(object.totalWinAmount)).unsigned = true;
                else if (typeof object.totalWinAmount === "string")
                    message.totalWinAmount = parseInt(object.totalWinAmount, 10);
                else if (typeof object.totalWinAmount === "number")
                    message.totalWinAmount = object.totalWinAmount;
                else if (typeof object.totalWinAmount === "object")
                    message.totalWinAmount = new $util.LongBits(object.totalWinAmount.low >>> 0, object.totalWinAmount.high >>> 0).toNumber(true);
            if (object.stakes) {
                if (!Array.isArray(object.stakes))
                    throw TypeError(".UserSlipData.stakes: array expected");
                message.stakes = [];
                for (var i = 0; i < object.stakes.length; ++i) {
                    if (typeof object.stakes[i] !== "object")
                        throw TypeError(".UserSlipData.stakes: object expected");
                    message.stakes[i] = $root.UserSlipData.Stake.fromObject(object.stakes[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a UserSlipData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof UserSlipData
         * @static
         * @param {UserSlipData} message UserSlipData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserSlipData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.stakes = [];
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.barcode = "";
                object.type = options.enums === String ? "ST_ORDINAL" : 0;
                object.status = options.enums === String ? "SS_NOT_CALCULATED" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.acceptedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.acceptedAt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.paidAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.paidAt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.paidAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.paidAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.possibleWinAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.possibleWinAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.expressAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expressAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.jackpotAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.jackpotAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.totalWinAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalWinAmount = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.barcode != null && message.hasOwnProperty("barcode"))
                object.barcode = message.barcode;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.UserSlipData.SlipType[message.type] : message.type;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.UserSlipData.SlipStatus[message.status] : message.status;
            if (message.acceptedAt != null && message.hasOwnProperty("acceptedAt"))
                if (typeof message.acceptedAt === "number")
                    object.acceptedAt = options.longs === String ? String(message.acceptedAt) : message.acceptedAt;
                else
                    object.acceptedAt = options.longs === String ? $util.Long.prototype.toString.call(message.acceptedAt) : options.longs === Number ? new $util.LongBits(message.acceptedAt.low >>> 0, message.acceptedAt.high >>> 0).toNumber(true) : message.acceptedAt;
            if (message.paidAt != null && message.hasOwnProperty("paidAt"))
                if (typeof message.paidAt === "number")
                    object.paidAt = options.longs === String ? String(message.paidAt) : message.paidAt;
                else
                    object.paidAt = options.longs === String ? $util.Long.prototype.toString.call(message.paidAt) : options.longs === Number ? new $util.LongBits(message.paidAt.low >>> 0, message.paidAt.high >>> 0).toNumber(true) : message.paidAt;
            if (message.paidAmount != null && message.hasOwnProperty("paidAmount"))
                if (typeof message.paidAmount === "number")
                    object.paidAmount = options.longs === String ? String(message.paidAmount) : message.paidAmount;
                else
                    object.paidAmount = options.longs === String ? $util.Long.prototype.toString.call(message.paidAmount) : options.longs === Number ? new $util.LongBits(message.paidAmount.low >>> 0, message.paidAmount.high >>> 0).toNumber(true) : message.paidAmount;
            if (message.possibleWinAmount != null && message.hasOwnProperty("possibleWinAmount"))
                if (typeof message.possibleWinAmount === "number")
                    object.possibleWinAmount = options.longs === String ? String(message.possibleWinAmount) : message.possibleWinAmount;
                else
                    object.possibleWinAmount = options.longs === String ? $util.Long.prototype.toString.call(message.possibleWinAmount) : options.longs === Number ? new $util.LongBits(message.possibleWinAmount.low >>> 0, message.possibleWinAmount.high >>> 0).toNumber(true) : message.possibleWinAmount;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber(true) : message.winAmount;
            if (message.expressAmount != null && message.hasOwnProperty("expressAmount"))
                if (typeof message.expressAmount === "number")
                    object.expressAmount = options.longs === String ? String(message.expressAmount) : message.expressAmount;
                else
                    object.expressAmount = options.longs === String ? $util.Long.prototype.toString.call(message.expressAmount) : options.longs === Number ? new $util.LongBits(message.expressAmount.low >>> 0, message.expressAmount.high >>> 0).toNumber(true) : message.expressAmount;
            if (message.jackpotAmount != null && message.hasOwnProperty("jackpotAmount"))
                if (typeof message.jackpotAmount === "number")
                    object.jackpotAmount = options.longs === String ? String(message.jackpotAmount) : message.jackpotAmount;
                else
                    object.jackpotAmount = options.longs === String ? $util.Long.prototype.toString.call(message.jackpotAmount) : options.longs === Number ? new $util.LongBits(message.jackpotAmount.low >>> 0, message.jackpotAmount.high >>> 0).toNumber(true) : message.jackpotAmount;
            if (message.totalWinAmount != null && message.hasOwnProperty("totalWinAmount"))
                if (typeof message.totalWinAmount === "number")
                    object.totalWinAmount = options.longs === String ? String(message.totalWinAmount) : message.totalWinAmount;
                else
                    object.totalWinAmount = options.longs === String ? $util.Long.prototype.toString.call(message.totalWinAmount) : options.longs === Number ? new $util.LongBits(message.totalWinAmount.low >>> 0, message.totalWinAmount.high >>> 0).toNumber(true) : message.totalWinAmount;
            if (message.stakes && message.stakes.length) {
                object.stakes = [];
                for (var j = 0; j < message.stakes.length; ++j)
                    object.stakes[j] = $root.UserSlipData.Stake.toObject(message.stakes[j], options);
            }
            return object;
        };
    
        /**
         * Converts this UserSlipData to JSON.
         * @function toJSON
         * @memberof UserSlipData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserSlipData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        /**
         * SlipType enum.
         * @name UserSlipData.SlipType
         * @enum {number}
         * @property {number} ST_ORDINAL=0 ST_ORDINAL value
         * @property {number} ST_EXPRESS=1 ST_EXPRESS value
         */
        UserSlipData.SlipType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ST_ORDINAL"] = 0;
            values[valuesById[1] = "ST_EXPRESS"] = 1;
            return values;
        })();
    
        /**
         * SlipStatus enum.
         * @name UserSlipData.SlipStatus
         * @enum {number}
         * @property {number} SS_NOT_CALCULATED=0 SS_NOT_CALCULATED value
         * @property {number} SS_LOST=1 SS_LOST value
         * @property {number} SS_WIN=2 SS_WIN value
         * @property {number} SS_PAID=3 SS_PAID value
         */
        UserSlipData.SlipStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SS_NOT_CALCULATED"] = 0;
            values[valuesById[1] = "SS_LOST"] = 1;
            values[valuesById[2] = "SS_WIN"] = 2;
            values[valuesById[3] = "SS_PAID"] = 3;
            return values;
        })();
    
        UserSlipData.Stake = (function() {
    
            /**
             * Properties of a Stake.
             * @memberof UserSlipData
             * @interface IStake
             * @property {Long|null} [id] Stake id
             * @property {UserSlipData.Stake.StakeStatus|null} [status] Stake status
             * @property {number|null} [source] Stake source
             * @property {Long|null} [round] Stake round
             * @property {Long|null} [betAmount] Stake betAmount
             * @property {number|null} [odd] Stake odd
             * @property {string|null} [event] Stake event
             * @property {string|null} [game] Stake game
             * @property {string|null} [result] Stake result
             */
    
            /**
             * Constructs a new Stake.
             * @memberof UserSlipData
             * @classdesc Represents a Stake.
             * @implements IStake
             * @constructor
             * @param {UserSlipData.IStake=} [properties] Properties to set
             */
            function Stake(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Stake id.
             * @member {Long} id
             * @memberof UserSlipData.Stake
             * @instance
             */
            Stake.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Stake status.
             * @member {UserSlipData.Stake.StakeStatus} status
             * @memberof UserSlipData.Stake
             * @instance
             */
            Stake.prototype.status = 0;
    
            /**
             * Stake source.
             * @member {number} source
             * @memberof UserSlipData.Stake
             * @instance
             */
            Stake.prototype.source = 0;
    
            /**
             * Stake round.
             * @member {Long} round
             * @memberof UserSlipData.Stake
             * @instance
             */
            Stake.prototype.round = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Stake betAmount.
             * @member {Long} betAmount
             * @memberof UserSlipData.Stake
             * @instance
             */
            Stake.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Stake odd.
             * @member {number} odd
             * @memberof UserSlipData.Stake
             * @instance
             */
            Stake.prototype.odd = 0;
    
            /**
             * Stake event.
             * @member {string} event
             * @memberof UserSlipData.Stake
             * @instance
             */
            Stake.prototype.event = "";
    
            /**
             * Stake game.
             * @member {string} game
             * @memberof UserSlipData.Stake
             * @instance
             */
            Stake.prototype.game = "";
    
            /**
             * Stake result.
             * @member {string} result
             * @memberof UserSlipData.Stake
             * @instance
             */
            Stake.prototype.result = "";
    
            /**
             * Creates a new Stake instance using the specified properties.
             * @function create
             * @memberof UserSlipData.Stake
             * @static
             * @param {UserSlipData.IStake=} [properties] Properties to set
             * @returns {UserSlipData.Stake} Stake instance
             */
            Stake.create = function create(properties) {
                return new Stake(properties);
            };
    
            /**
             * Encodes the specified Stake message. Does not implicitly {@link UserSlipData.Stake.verify|verify} messages.
             * @function encode
             * @memberof UserSlipData.Stake
             * @static
             * @param {UserSlipData.IStake} message Stake message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Stake.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.source);
                if (message.round != null && Object.hasOwnProperty.call(message, "round"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.round);
                if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.betAmount);
                if (message.odd != null && Object.hasOwnProperty.call(message, "odd"))
                    writer.uint32(/* id 6, wireType 1 =*/49).double(message.odd);
                if (message.event != null && Object.hasOwnProperty.call(message, "event"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.event);
                if (message.game != null && Object.hasOwnProperty.call(message, "game"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.game);
                if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.result);
                return writer;
            };
    
            /**
             * Encodes the specified Stake message, length delimited. Does not implicitly {@link UserSlipData.Stake.verify|verify} messages.
             * @function encodeDelimited
             * @memberof UserSlipData.Stake
             * @static
             * @param {UserSlipData.IStake} message Stake message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Stake.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Stake message from the specified reader or buffer.
             * @function decode
             * @memberof UserSlipData.Stake
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {UserSlipData.Stake} Stake
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Stake.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UserSlipData.Stake();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.status = reader.int32();
                        break;
                    case 3:
                        message.source = reader.uint32();
                        break;
                    case 4:
                        message.round = reader.uint64();
                        break;
                    case 5:
                        message.betAmount = reader.uint64();
                        break;
                    case 6:
                        message.odd = reader.double();
                        break;
                    case 10:
                        message.event = reader.string();
                        break;
                    case 11:
                        message.game = reader.string();
                        break;
                    case 12:
                        message.result = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Stake message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof UserSlipData.Stake
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {UserSlipData.Stake} Stake
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Stake.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Stake message.
             * @function verify
             * @memberof UserSlipData.Stake
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Stake.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.source != null && message.hasOwnProperty("source"))
                    if (!$util.isInteger(message.source))
                        return "source: integer expected";
                if (message.round != null && message.hasOwnProperty("round"))
                    if (!$util.isInteger(message.round) && !(message.round && $util.isInteger(message.round.low) && $util.isInteger(message.round.high)))
                        return "round: integer|Long expected";
                if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                    if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                        return "betAmount: integer|Long expected";
                if (message.odd != null && message.hasOwnProperty("odd"))
                    if (typeof message.odd !== "number")
                        return "odd: number expected";
                if (message.event != null && message.hasOwnProperty("event"))
                    if (!$util.isString(message.event))
                        return "event: string expected";
                if (message.game != null && message.hasOwnProperty("game"))
                    if (!$util.isString(message.game))
                        return "game: string expected";
                if (message.result != null && message.hasOwnProperty("result"))
                    if (!$util.isString(message.result))
                        return "result: string expected";
                return null;
            };
    
            /**
             * Creates a Stake message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof UserSlipData.Stake
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {UserSlipData.Stake} Stake
             */
            Stake.fromObject = function fromObject(object) {
                if (object instanceof $root.UserSlipData.Stake)
                    return object;
                var message = new $root.UserSlipData.Stake();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                switch (object.status) {
                case "STS_UNKNOWN":
                case 0:
                    message.status = 0;
                    break;
                case "STS_LOST":
                case 1:
                    message.status = 1;
                    break;
                case "STS_WIN":
                case 2:
                    message.status = 2;
                    break;
                }
                if (object.source != null)
                    message.source = object.source >>> 0;
                if (object.round != null)
                    if ($util.Long)
                        (message.round = $util.Long.fromValue(object.round)).unsigned = true;
                    else if (typeof object.round === "string")
                        message.round = parseInt(object.round, 10);
                    else if (typeof object.round === "number")
                        message.round = object.round;
                    else if (typeof object.round === "object")
                        message.round = new $util.LongBits(object.round.low >>> 0, object.round.high >>> 0).toNumber(true);
                if (object.betAmount != null)
                    if ($util.Long)
                        (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = true;
                    else if (typeof object.betAmount === "string")
                        message.betAmount = parseInt(object.betAmount, 10);
                    else if (typeof object.betAmount === "number")
                        message.betAmount = object.betAmount;
                    else if (typeof object.betAmount === "object")
                        message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber(true);
                if (object.odd != null)
                    message.odd = Number(object.odd);
                if (object.event != null)
                    message.event = String(object.event);
                if (object.game != null)
                    message.game = String(object.game);
                if (object.result != null)
                    message.result = String(object.result);
                return message;
            };
    
            /**
             * Creates a plain object from a Stake message. Also converts values to other types if specified.
             * @function toObject
             * @memberof UserSlipData.Stake
             * @static
             * @param {UserSlipData.Stake} message Stake
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Stake.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.status = options.enums === String ? "STS_UNKNOWN" : 0;
                    object.source = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.round = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.round = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.betAmount = options.longs === String ? "0" : 0;
                    object.odd = 0;
                    object.event = "";
                    object.game = "";
                    object.result = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.UserSlipData.Stake.StakeStatus[message.status] : message.status;
                if (message.source != null && message.hasOwnProperty("source"))
                    object.source = message.source;
                if (message.round != null && message.hasOwnProperty("round"))
                    if (typeof message.round === "number")
                        object.round = options.longs === String ? String(message.round) : message.round;
                    else
                        object.round = options.longs === String ? $util.Long.prototype.toString.call(message.round) : options.longs === Number ? new $util.LongBits(message.round.low >>> 0, message.round.high >>> 0).toNumber(true) : message.round;
                if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                    if (typeof message.betAmount === "number")
                        object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                    else
                        object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber(true) : message.betAmount;
                if (message.odd != null && message.hasOwnProperty("odd"))
                    object.odd = options.json && !isFinite(message.odd) ? String(message.odd) : message.odd;
                if (message.event != null && message.hasOwnProperty("event"))
                    object.event = message.event;
                if (message.game != null && message.hasOwnProperty("game"))
                    object.game = message.game;
                if (message.result != null && message.hasOwnProperty("result"))
                    object.result = message.result;
                return object;
            };
    
            /**
             * Converts this Stake to JSON.
             * @function toJSON
             * @memberof UserSlipData.Stake
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Stake.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * StakeStatus enum.
             * @name UserSlipData.Stake.StakeStatus
             * @enum {number}
             * @property {number} STS_UNKNOWN=0 STS_UNKNOWN value
             * @property {number} STS_LOST=1 STS_LOST value
             * @property {number} STS_WIN=2 STS_WIN value
             */
            Stake.StakeStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STS_UNKNOWN"] = 0;
                values[valuesById[1] = "STS_LOST"] = 1;
                values[valuesById[2] = "STS_WIN"] = 2;
                return values;
            })();
    
            return Stake;
        })();
    
        return UserSlipData;
    })();
    
    $root.VSportsOutcome = (function() {
    
        /**
         * Properties of a VSportsOutcome.
         * @exports IVSportsOutcome
         * @interface IVSportsOutcome
         * @property {string|null} [teamA] VSportsOutcome teamA
         * @property {string|null} [teamB] VSportsOutcome teamB
         * @property {number|null} [teamAScore] VSportsOutcome teamAScore
         * @property {number|null} [teamBScore] VSportsOutcome teamBScore
         */
    
        /**
         * Constructs a new VSportsOutcome.
         * @exports VSportsOutcome
         * @classdesc Represents a VSportsOutcome.
         * @implements IVSportsOutcome
         * @constructor
         * @param {IVSportsOutcome=} [properties] Properties to set
         */
        function VSportsOutcome(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * VSportsOutcome teamA.
         * @member {string} teamA
         * @memberof VSportsOutcome
         * @instance
         */
        VSportsOutcome.prototype.teamA = "";
    
        /**
         * VSportsOutcome teamB.
         * @member {string} teamB
         * @memberof VSportsOutcome
         * @instance
         */
        VSportsOutcome.prototype.teamB = "";
    
        /**
         * VSportsOutcome teamAScore.
         * @member {number} teamAScore
         * @memberof VSportsOutcome
         * @instance
         */
        VSportsOutcome.prototype.teamAScore = 0;
    
        /**
         * VSportsOutcome teamBScore.
         * @member {number} teamBScore
         * @memberof VSportsOutcome
         * @instance
         */
        VSportsOutcome.prototype.teamBScore = 0;
    
        /**
         * Creates a new VSportsOutcome instance using the specified properties.
         * @function create
         * @memberof VSportsOutcome
         * @static
         * @param {IVSportsOutcome=} [properties] Properties to set
         * @returns {VSportsOutcome} VSportsOutcome instance
         */
        VSportsOutcome.create = function create(properties) {
            return new VSportsOutcome(properties);
        };
    
        /**
         * Encodes the specified VSportsOutcome message. Does not implicitly {@link VSportsOutcome.verify|verify} messages.
         * @function encode
         * @memberof VSportsOutcome
         * @static
         * @param {IVSportsOutcome} message VSportsOutcome message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VSportsOutcome.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.teamA != null && Object.hasOwnProperty.call(message, "teamA"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.teamA);
            if (message.teamB != null && Object.hasOwnProperty.call(message, "teamB"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.teamB);
            if (message.teamAScore != null && Object.hasOwnProperty.call(message, "teamAScore"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.teamAScore);
            if (message.teamBScore != null && Object.hasOwnProperty.call(message, "teamBScore"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.teamBScore);
            return writer;
        };
    
        /**
         * Encodes the specified VSportsOutcome message, length delimited. Does not implicitly {@link VSportsOutcome.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VSportsOutcome
         * @static
         * @param {IVSportsOutcome} message VSportsOutcome message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VSportsOutcome.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a VSportsOutcome message from the specified reader or buffer.
         * @function decode
         * @memberof VSportsOutcome
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {VSportsOutcome} VSportsOutcome
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VSportsOutcome.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.VSportsOutcome();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.teamA = reader.string();
                    break;
                case 2:
                    message.teamB = reader.string();
                    break;
                case 3:
                    message.teamAScore = reader.uint32();
                    break;
                case 4:
                    message.teamBScore = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a VSportsOutcome message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VSportsOutcome
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VSportsOutcome} VSportsOutcome
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VSportsOutcome.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a VSportsOutcome message.
         * @function verify
         * @memberof VSportsOutcome
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VSportsOutcome.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.teamA != null && message.hasOwnProperty("teamA"))
                if (!$util.isString(message.teamA))
                    return "teamA: string expected";
            if (message.teamB != null && message.hasOwnProperty("teamB"))
                if (!$util.isString(message.teamB))
                    return "teamB: string expected";
            if (message.teamAScore != null && message.hasOwnProperty("teamAScore"))
                if (!$util.isInteger(message.teamAScore))
                    return "teamAScore: integer expected";
            if (message.teamBScore != null && message.hasOwnProperty("teamBScore"))
                if (!$util.isInteger(message.teamBScore))
                    return "teamBScore: integer expected";
            return null;
        };
    
        /**
         * Creates a VSportsOutcome message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof VSportsOutcome
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {VSportsOutcome} VSportsOutcome
         */
        VSportsOutcome.fromObject = function fromObject(object) {
            if (object instanceof $root.VSportsOutcome)
                return object;
            var message = new $root.VSportsOutcome();
            if (object.teamA != null)
                message.teamA = String(object.teamA);
            if (object.teamB != null)
                message.teamB = String(object.teamB);
            if (object.teamAScore != null)
                message.teamAScore = object.teamAScore >>> 0;
            if (object.teamBScore != null)
                message.teamBScore = object.teamBScore >>> 0;
            return message;
        };
    
        /**
         * Creates a plain object from a VSportsOutcome message. Also converts values to other types if specified.
         * @function toObject
         * @memberof VSportsOutcome
         * @static
         * @param {VSportsOutcome} message VSportsOutcome
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VSportsOutcome.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.teamA = "";
                object.teamB = "";
                object.teamAScore = 0;
                object.teamBScore = 0;
            }
            if (message.teamA != null && message.hasOwnProperty("teamA"))
                object.teamA = message.teamA;
            if (message.teamB != null && message.hasOwnProperty("teamB"))
                object.teamB = message.teamB;
            if (message.teamAScore != null && message.hasOwnProperty("teamAScore"))
                object.teamAScore = message.teamAScore;
            if (message.teamBScore != null && message.hasOwnProperty("teamBScore"))
                object.teamBScore = message.teamBScore;
            return object;
        };
    
        /**
         * Converts this VSportsOutcome to JSON.
         * @function toJSON
         * @memberof VSportsOutcome
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VSportsOutcome.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return VSportsOutcome;
    })();
    
    $root.BallOutcome = (function() {
    
        /**
         * Properties of a BallOutcome.
         * @exports IBallOutcome
         * @interface IBallOutcome
         * @property {number|null} [outcome] BallOutcome outcome
         * @property {Array.<string>|null} [videos] BallOutcome videos
         */
    
        /**
         * Constructs a new BallOutcome.
         * @exports BallOutcome
         * @classdesc Represents a BallOutcome.
         * @implements IBallOutcome
         * @constructor
         * @param {IBallOutcome=} [properties] Properties to set
         */
        function BallOutcome(properties) {
            this.videos = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * BallOutcome outcome.
         * @member {number} outcome
         * @memberof BallOutcome
         * @instance
         */
        BallOutcome.prototype.outcome = 0;
    
        /**
         * BallOutcome videos.
         * @member {Array.<string>} videos
         * @memberof BallOutcome
         * @instance
         */
        BallOutcome.prototype.videos = $util.emptyArray;
    
        /**
         * Creates a new BallOutcome instance using the specified properties.
         * @function create
         * @memberof BallOutcome
         * @static
         * @param {IBallOutcome=} [properties] Properties to set
         * @returns {BallOutcome} BallOutcome instance
         */
        BallOutcome.create = function create(properties) {
            return new BallOutcome(properties);
        };
    
        /**
         * Encodes the specified BallOutcome message. Does not implicitly {@link BallOutcome.verify|verify} messages.
         * @function encode
         * @memberof BallOutcome
         * @static
         * @param {IBallOutcome} message BallOutcome message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BallOutcome.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.outcome != null && Object.hasOwnProperty.call(message, "outcome"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.outcome);
            if (message.videos != null && message.videos.length)
                for (var i = 0; i < message.videos.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.videos[i]);
            return writer;
        };
    
        /**
         * Encodes the specified BallOutcome message, length delimited. Does not implicitly {@link BallOutcome.verify|verify} messages.
         * @function encodeDelimited
         * @memberof BallOutcome
         * @static
         * @param {IBallOutcome} message BallOutcome message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BallOutcome.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a BallOutcome message from the specified reader or buffer.
         * @function decode
         * @memberof BallOutcome
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {BallOutcome} BallOutcome
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BallOutcome.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.BallOutcome();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.outcome = reader.int32();
                    break;
                case 2:
                    if (!(message.videos && message.videos.length))
                        message.videos = [];
                    message.videos.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a BallOutcome message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof BallOutcome
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {BallOutcome} BallOutcome
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BallOutcome.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a BallOutcome message.
         * @function verify
         * @memberof BallOutcome
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BallOutcome.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.outcome != null && message.hasOwnProperty("outcome"))
                if (!$util.isInteger(message.outcome))
                    return "outcome: integer expected";
            if (message.videos != null && message.hasOwnProperty("videos")) {
                if (!Array.isArray(message.videos))
                    return "videos: array expected";
                for (var i = 0; i < message.videos.length; ++i)
                    if (!$util.isString(message.videos[i]))
                        return "videos: string[] expected";
            }
            return null;
        };
    
        /**
         * Creates a BallOutcome message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof BallOutcome
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {BallOutcome} BallOutcome
         */
        BallOutcome.fromObject = function fromObject(object) {
            if (object instanceof $root.BallOutcome)
                return object;
            var message = new $root.BallOutcome();
            if (object.outcome != null)
                message.outcome = object.outcome | 0;
            if (object.videos) {
                if (!Array.isArray(object.videos))
                    throw TypeError(".BallOutcome.videos: array expected");
                message.videos = [];
                for (var i = 0; i < object.videos.length; ++i)
                    message.videos[i] = String(object.videos[i]);
            }
            return message;
        };
    
        /**
         * Creates a plain object from a BallOutcome message. Also converts values to other types if specified.
         * @function toObject
         * @memberof BallOutcome
         * @static
         * @param {BallOutcome} message BallOutcome
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BallOutcome.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.videos = [];
            if (options.defaults)
                object.outcome = 0;
            if (message.outcome != null && message.hasOwnProperty("outcome"))
                object.outcome = message.outcome;
            if (message.videos && message.videos.length) {
                object.videos = [];
                for (var j = 0; j < message.videos.length; ++j)
                    object.videos[j] = message.videos[j];
            }
            return object;
        };
    
        /**
         * Converts this BallOutcome to JSON.
         * @function toJSON
         * @memberof BallOutcome
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BallOutcome.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return BallOutcome;
    })();
    
    $root.BallsOutcome = (function() {
    
        /**
         * Properties of a BallsOutcome.
         * @exports IBallsOutcome
         * @interface IBallsOutcome
         * @property {Array.<number>|null} [outcomes] BallsOutcome outcomes
         */
    
        /**
         * Constructs a new BallsOutcome.
         * @exports BallsOutcome
         * @classdesc Represents a BallsOutcome.
         * @implements IBallsOutcome
         * @constructor
         * @param {IBallsOutcome=} [properties] Properties to set
         */
        function BallsOutcome(properties) {
            this.outcomes = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * BallsOutcome outcomes.
         * @member {Array.<number>} outcomes
         * @memberof BallsOutcome
         * @instance
         */
        BallsOutcome.prototype.outcomes = $util.emptyArray;
    
        /**
         * Creates a new BallsOutcome instance using the specified properties.
         * @function create
         * @memberof BallsOutcome
         * @static
         * @param {IBallsOutcome=} [properties] Properties to set
         * @returns {BallsOutcome} BallsOutcome instance
         */
        BallsOutcome.create = function create(properties) {
            return new BallsOutcome(properties);
        };
    
        /**
         * Encodes the specified BallsOutcome message. Does not implicitly {@link BallsOutcome.verify|verify} messages.
         * @function encode
         * @memberof BallsOutcome
         * @static
         * @param {IBallsOutcome} message BallsOutcome message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BallsOutcome.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.outcomes != null && message.outcomes.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.outcomes.length; ++i)
                    writer.uint32(message.outcomes[i]);
                writer.ldelim();
            }
            return writer;
        };
    
        /**
         * Encodes the specified BallsOutcome message, length delimited. Does not implicitly {@link BallsOutcome.verify|verify} messages.
         * @function encodeDelimited
         * @memberof BallsOutcome
         * @static
         * @param {IBallsOutcome} message BallsOutcome message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BallsOutcome.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a BallsOutcome message from the specified reader or buffer.
         * @function decode
         * @memberof BallsOutcome
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {BallsOutcome} BallsOutcome
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BallsOutcome.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.BallsOutcome();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.outcomes && message.outcomes.length))
                        message.outcomes = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.outcomes.push(reader.uint32());
                    } else
                        message.outcomes.push(reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a BallsOutcome message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof BallsOutcome
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {BallsOutcome} BallsOutcome
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BallsOutcome.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a BallsOutcome message.
         * @function verify
         * @memberof BallsOutcome
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BallsOutcome.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.outcomes != null && message.hasOwnProperty("outcomes")) {
                if (!Array.isArray(message.outcomes))
                    return "outcomes: array expected";
                for (var i = 0; i < message.outcomes.length; ++i)
                    if (!$util.isInteger(message.outcomes[i]))
                        return "outcomes: integer[] expected";
            }
            return null;
        };
    
        /**
         * Creates a BallsOutcome message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof BallsOutcome
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {BallsOutcome} BallsOutcome
         */
        BallsOutcome.fromObject = function fromObject(object) {
            if (object instanceof $root.BallsOutcome)
                return object;
            var message = new $root.BallsOutcome();
            if (object.outcomes) {
                if (!Array.isArray(object.outcomes))
                    throw TypeError(".BallsOutcome.outcomes: array expected");
                message.outcomes = [];
                for (var i = 0; i < object.outcomes.length; ++i)
                    message.outcomes[i] = object.outcomes[i] >>> 0;
            }
            return message;
        };
    
        /**
         * Creates a plain object from a BallsOutcome message. Also converts values to other types if specified.
         * @function toObject
         * @memberof BallsOutcome
         * @static
         * @param {BallsOutcome} message BallsOutcome
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BallsOutcome.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.outcomes = [];
            if (message.outcomes && message.outcomes.length) {
                object.outcomes = [];
                for (var j = 0; j < message.outcomes.length; ++j)
                    object.outcomes[j] = message.outcomes[j];
            }
            return object;
        };
    
        /**
         * Converts this BallsOutcome to JSON.
         * @function toJSON
         * @memberof BallsOutcome
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BallsOutcome.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return BallsOutcome;
    })();
    
    $root.RacesOutcome = (function() {
    
        /**
         * Properties of a RacesOutcome.
         * @exports IRacesOutcome
         * @interface IRacesOutcome
         * @property {Array.<number>|null} [outcomes] RacesOutcome outcomes
         * @property {Array.<RacesOutcome.IVideo>|null} [videos] RacesOutcome videos
         */
    
        /**
         * Constructs a new RacesOutcome.
         * @exports RacesOutcome
         * @classdesc Represents a RacesOutcome.
         * @implements IRacesOutcome
         * @constructor
         * @param {IRacesOutcome=} [properties] Properties to set
         */
        function RacesOutcome(properties) {
            this.outcomes = [];
            this.videos = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * RacesOutcome outcomes.
         * @member {Array.<number>} outcomes
         * @memberof RacesOutcome
         * @instance
         */
        RacesOutcome.prototype.outcomes = $util.emptyArray;
    
        /**
         * RacesOutcome videos.
         * @member {Array.<RacesOutcome.IVideo>} videos
         * @memberof RacesOutcome
         * @instance
         */
        RacesOutcome.prototype.videos = $util.emptyArray;
    
        /**
         * Creates a new RacesOutcome instance using the specified properties.
         * @function create
         * @memberof RacesOutcome
         * @static
         * @param {IRacesOutcome=} [properties] Properties to set
         * @returns {RacesOutcome} RacesOutcome instance
         */
        RacesOutcome.create = function create(properties) {
            return new RacesOutcome(properties);
        };
    
        /**
         * Encodes the specified RacesOutcome message. Does not implicitly {@link RacesOutcome.verify|verify} messages.
         * @function encode
         * @memberof RacesOutcome
         * @static
         * @param {IRacesOutcome} message RacesOutcome message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RacesOutcome.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.outcomes != null && message.outcomes.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.outcomes.length; ++i)
                    writer.uint32(message.outcomes[i]);
                writer.ldelim();
            }
            if (message.videos != null && message.videos.length)
                for (var i = 0; i < message.videos.length; ++i)
                    $root.RacesOutcome.Video.encode(message.videos[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified RacesOutcome message, length delimited. Does not implicitly {@link RacesOutcome.verify|verify} messages.
         * @function encodeDelimited
         * @memberof RacesOutcome
         * @static
         * @param {IRacesOutcome} message RacesOutcome message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RacesOutcome.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a RacesOutcome message from the specified reader or buffer.
         * @function decode
         * @memberof RacesOutcome
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {RacesOutcome} RacesOutcome
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RacesOutcome.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RacesOutcome();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.outcomes && message.outcomes.length))
                        message.outcomes = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.outcomes.push(reader.uint32());
                    } else
                        message.outcomes.push(reader.uint32());
                    break;
                case 2:
                    if (!(message.videos && message.videos.length))
                        message.videos = [];
                    message.videos.push($root.RacesOutcome.Video.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a RacesOutcome message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof RacesOutcome
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {RacesOutcome} RacesOutcome
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RacesOutcome.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a RacesOutcome message.
         * @function verify
         * @memberof RacesOutcome
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RacesOutcome.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.outcomes != null && message.hasOwnProperty("outcomes")) {
                if (!Array.isArray(message.outcomes))
                    return "outcomes: array expected";
                for (var i = 0; i < message.outcomes.length; ++i)
                    if (!$util.isInteger(message.outcomes[i]))
                        return "outcomes: integer[] expected";
            }
            if (message.videos != null && message.hasOwnProperty("videos")) {
                if (!Array.isArray(message.videos))
                    return "videos: array expected";
                for (var i = 0; i < message.videos.length; ++i) {
                    var error = $root.RacesOutcome.Video.verify(message.videos[i]);
                    if (error)
                        return "videos." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a RacesOutcome message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof RacesOutcome
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {RacesOutcome} RacesOutcome
         */
        RacesOutcome.fromObject = function fromObject(object) {
            if (object instanceof $root.RacesOutcome)
                return object;
            var message = new $root.RacesOutcome();
            if (object.outcomes) {
                if (!Array.isArray(object.outcomes))
                    throw TypeError(".RacesOutcome.outcomes: array expected");
                message.outcomes = [];
                for (var i = 0; i < object.outcomes.length; ++i)
                    message.outcomes[i] = object.outcomes[i] >>> 0;
            }
            if (object.videos) {
                if (!Array.isArray(object.videos))
                    throw TypeError(".RacesOutcome.videos: array expected");
                message.videos = [];
                for (var i = 0; i < object.videos.length; ++i) {
                    if (typeof object.videos[i] !== "object")
                        throw TypeError(".RacesOutcome.videos: object expected");
                    message.videos[i] = $root.RacesOutcome.Video.fromObject(object.videos[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a RacesOutcome message. Also converts values to other types if specified.
         * @function toObject
         * @memberof RacesOutcome
         * @static
         * @param {RacesOutcome} message RacesOutcome
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RacesOutcome.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.outcomes = [];
                object.videos = [];
            }
            if (message.outcomes && message.outcomes.length) {
                object.outcomes = [];
                for (var j = 0; j < message.outcomes.length; ++j)
                    object.outcomes[j] = message.outcomes[j];
            }
            if (message.videos && message.videos.length) {
                object.videos = [];
                for (var j = 0; j < message.videos.length; ++j)
                    object.videos[j] = $root.RacesOutcome.Video.toObject(message.videos[j], options);
            }
            return object;
        };
    
        /**
         * Converts this RacesOutcome to JSON.
         * @function toJSON
         * @memberof RacesOutcome
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RacesOutcome.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        RacesOutcome.Video = (function() {
    
            /**
             * Properties of a Video.
             * @memberof RacesOutcome
             * @interface IVideo
             * @property {RacesOutcome.Video.VideoType|null} [type] Video type
             * @property {string|null} [path] Video path
             */
    
            /**
             * Constructs a new Video.
             * @memberof RacesOutcome
             * @classdesc Represents a Video.
             * @implements IVideo
             * @constructor
             * @param {RacesOutcome.IVideo=} [properties] Properties to set
             */
            function Video(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Video type.
             * @member {RacesOutcome.Video.VideoType} type
             * @memberof RacesOutcome.Video
             * @instance
             */
            Video.prototype.type = 0;
    
            /**
             * Video path.
             * @member {string} path
             * @memberof RacesOutcome.Video
             * @instance
             */
            Video.prototype.path = "";
    
            /**
             * Creates a new Video instance using the specified properties.
             * @function create
             * @memberof RacesOutcome.Video
             * @static
             * @param {RacesOutcome.IVideo=} [properties] Properties to set
             * @returns {RacesOutcome.Video} Video instance
             */
            Video.create = function create(properties) {
                return new Video(properties);
            };
    
            /**
             * Encodes the specified Video message. Does not implicitly {@link RacesOutcome.Video.verify|verify} messages.
             * @function encode
             * @memberof RacesOutcome.Video
             * @static
             * @param {RacesOutcome.IVideo} message Video message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Video.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
                return writer;
            };
    
            /**
             * Encodes the specified Video message, length delimited. Does not implicitly {@link RacesOutcome.Video.verify|verify} messages.
             * @function encodeDelimited
             * @memberof RacesOutcome.Video
             * @static
             * @param {RacesOutcome.IVideo} message Video message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Video.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Video message from the specified reader or buffer.
             * @function decode
             * @memberof RacesOutcome.Video
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {RacesOutcome.Video} Video
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Video.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RacesOutcome.Video();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.type = reader.int32();
                        break;
                    case 2:
                        message.path = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Video message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof RacesOutcome.Video
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {RacesOutcome.Video} Video
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Video.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Video message.
             * @function verify
             * @memberof RacesOutcome.Video
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Video.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.path != null && message.hasOwnProperty("path"))
                    if (!$util.isString(message.path))
                        return "path: string expected";
                return null;
            };
    
            /**
             * Creates a Video message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof RacesOutcome.Video
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {RacesOutcome.Video} Video
             */
            Video.fromObject = function fromObject(object) {
                if (object instanceof $root.RacesOutcome.Video)
                    return object;
                var message = new $root.RacesOutcome.Video();
                switch (object.type) {
                case "VT_DEFAULT":
                case 0:
                    message.type = 0;
                    break;
                case "VT_DOGS_3D":
                case 1:
                    message.type = 1;
                    break;
                case "VT_TRONRACE_3D":
                case 2:
                    message.type = 2;
                    break;
                case "VT_VELODROM_3D":
                case 3:
                    message.type = 3;
                    break;
                case "VT_GOLDENRACE":
                case 4:
                    message.type = 4;
                    break;
                }
                if (object.path != null)
                    message.path = String(object.path);
                return message;
            };
    
            /**
             * Creates a plain object from a Video message. Also converts values to other types if specified.
             * @function toObject
             * @memberof RacesOutcome.Video
             * @static
             * @param {RacesOutcome.Video} message Video
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Video.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.type = options.enums === String ? "VT_DEFAULT" : 0;
                    object.path = "";
                }
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.RacesOutcome.Video.VideoType[message.type] : message.type;
                if (message.path != null && message.hasOwnProperty("path"))
                    object.path = message.path;
                return object;
            };
    
            /**
             * Converts this Video to JSON.
             * @function toJSON
             * @memberof RacesOutcome.Video
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Video.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * VideoType enum.
             * @name RacesOutcome.Video.VideoType
             * @enum {number}
             * @property {number} VT_DEFAULT=0 VT_DEFAULT value
             * @property {number} VT_DOGS_3D=1 VT_DOGS_3D value
             * @property {number} VT_TRONRACE_3D=2 VT_TRONRACE_3D value
             * @property {number} VT_VELODROM_3D=3 VT_VELODROM_3D value
             * @property {number} VT_GOLDENRACE=4 VT_GOLDENRACE value
             */
            Video.VideoType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "VT_DEFAULT"] = 0;
                values[valuesById[1] = "VT_DOGS_3D"] = 1;
                values[valuesById[2] = "VT_TRONRACE_3D"] = 2;
                values[valuesById[3] = "VT_VELODROM_3D"] = 3;
                values[valuesById[4] = "VT_GOLDENRACE"] = 4;
                return values;
            })();
    
            return Video;
        })();
    
        return RacesOutcome;
    })();
    
    $root.Lucky6Outcome = (function() {
    
        /**
         * Properties of a Lucky6Outcome.
         * @exports ILucky6Outcome
         * @interface ILucky6Outcome
         * @property {Array.<number>|null} [balls] Lucky6Outcome balls
         * @property {Array.<number>|null} [clovers] Lucky6Outcome clovers
         */
    
        /**
         * Constructs a new Lucky6Outcome.
         * @exports Lucky6Outcome
         * @classdesc Represents a Lucky6Outcome.
         * @implements ILucky6Outcome
         * @constructor
         * @param {ILucky6Outcome=} [properties] Properties to set
         */
        function Lucky6Outcome(properties) {
            this.balls = [];
            this.clovers = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Lucky6Outcome balls.
         * @member {Array.<number>} balls
         * @memberof Lucky6Outcome
         * @instance
         */
        Lucky6Outcome.prototype.balls = $util.emptyArray;
    
        /**
         * Lucky6Outcome clovers.
         * @member {Array.<number>} clovers
         * @memberof Lucky6Outcome
         * @instance
         */
        Lucky6Outcome.prototype.clovers = $util.emptyArray;
    
        /**
         * Creates a new Lucky6Outcome instance using the specified properties.
         * @function create
         * @memberof Lucky6Outcome
         * @static
         * @param {ILucky6Outcome=} [properties] Properties to set
         * @returns {Lucky6Outcome} Lucky6Outcome instance
         */
        Lucky6Outcome.create = function create(properties) {
            return new Lucky6Outcome(properties);
        };
    
        /**
         * Encodes the specified Lucky6Outcome message. Does not implicitly {@link Lucky6Outcome.verify|verify} messages.
         * @function encode
         * @memberof Lucky6Outcome
         * @static
         * @param {ILucky6Outcome} message Lucky6Outcome message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Lucky6Outcome.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.balls != null && message.balls.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.balls.length; ++i)
                    writer.int32(message.balls[i]);
                writer.ldelim();
            }
            if (message.clovers != null && message.clovers.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.clovers.length; ++i)
                    writer.int32(message.clovers[i]);
                writer.ldelim();
            }
            return writer;
        };
    
        /**
         * Encodes the specified Lucky6Outcome message, length delimited. Does not implicitly {@link Lucky6Outcome.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Lucky6Outcome
         * @static
         * @param {ILucky6Outcome} message Lucky6Outcome message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Lucky6Outcome.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Lucky6Outcome message from the specified reader or buffer.
         * @function decode
         * @memberof Lucky6Outcome
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Lucky6Outcome} Lucky6Outcome
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Lucky6Outcome.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Lucky6Outcome();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.balls && message.balls.length))
                        message.balls = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.balls.push(reader.int32());
                    } else
                        message.balls.push(reader.int32());
                    break;
                case 2:
                    if (!(message.clovers && message.clovers.length))
                        message.clovers = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.clovers.push(reader.int32());
                    } else
                        message.clovers.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Lucky6Outcome message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Lucky6Outcome
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Lucky6Outcome} Lucky6Outcome
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Lucky6Outcome.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Lucky6Outcome message.
         * @function verify
         * @memberof Lucky6Outcome
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Lucky6Outcome.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.balls != null && message.hasOwnProperty("balls")) {
                if (!Array.isArray(message.balls))
                    return "balls: array expected";
                for (var i = 0; i < message.balls.length; ++i)
                    if (!$util.isInteger(message.balls[i]))
                        return "balls: integer[] expected";
            }
            if (message.clovers != null && message.hasOwnProperty("clovers")) {
                if (!Array.isArray(message.clovers))
                    return "clovers: array expected";
                for (var i = 0; i < message.clovers.length; ++i)
                    if (!$util.isInteger(message.clovers[i]))
                        return "clovers: integer[] expected";
            }
            return null;
        };
    
        /**
         * Creates a Lucky6Outcome message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Lucky6Outcome
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Lucky6Outcome} Lucky6Outcome
         */
        Lucky6Outcome.fromObject = function fromObject(object) {
            if (object instanceof $root.Lucky6Outcome)
                return object;
            var message = new $root.Lucky6Outcome();
            if (object.balls) {
                if (!Array.isArray(object.balls))
                    throw TypeError(".Lucky6Outcome.balls: array expected");
                message.balls = [];
                for (var i = 0; i < object.balls.length; ++i)
                    message.balls[i] = object.balls[i] | 0;
            }
            if (object.clovers) {
                if (!Array.isArray(object.clovers))
                    throw TypeError(".Lucky6Outcome.clovers: array expected");
                message.clovers = [];
                for (var i = 0; i < object.clovers.length; ++i)
                    message.clovers[i] = object.clovers[i] | 0;
            }
            return message;
        };
    
        /**
         * Creates a plain object from a Lucky6Outcome message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Lucky6Outcome
         * @static
         * @param {Lucky6Outcome} message Lucky6Outcome
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Lucky6Outcome.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.balls = [];
                object.clovers = [];
            }
            if (message.balls && message.balls.length) {
                object.balls = [];
                for (var j = 0; j < message.balls.length; ++j)
                    object.balls[j] = message.balls[j];
            }
            if (message.clovers && message.clovers.length) {
                object.clovers = [];
                for (var j = 0; j < message.clovers.length; ++j)
                    object.clovers[j] = message.clovers[j];
            }
            return object;
        };
    
        /**
         * Converts this Lucky6Outcome to JSON.
         * @function toJSON
         * @memberof Lucky6Outcome
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Lucky6Outcome.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Lucky6Outcome;
    })();
    
    $root.PokerOutcome = (function() {
    
        /**
         * Properties of a PokerOutcome.
         * @exports IPokerOutcome
         * @interface IPokerOutcome
         * @property {Array.<PokerOutcome.IPokerTableOutcome>|null} [tables] PokerOutcome tables
         */
    
        /**
         * Constructs a new PokerOutcome.
         * @exports PokerOutcome
         * @classdesc Represents a PokerOutcome.
         * @implements IPokerOutcome
         * @constructor
         * @param {IPokerOutcome=} [properties] Properties to set
         */
        function PokerOutcome(properties) {
            this.tables = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * PokerOutcome tables.
         * @member {Array.<PokerOutcome.IPokerTableOutcome>} tables
         * @memberof PokerOutcome
         * @instance
         */
        PokerOutcome.prototype.tables = $util.emptyArray;
    
        /**
         * Creates a new PokerOutcome instance using the specified properties.
         * @function create
         * @memberof PokerOutcome
         * @static
         * @param {IPokerOutcome=} [properties] Properties to set
         * @returns {PokerOutcome} PokerOutcome instance
         */
        PokerOutcome.create = function create(properties) {
            return new PokerOutcome(properties);
        };
    
        /**
         * Encodes the specified PokerOutcome message. Does not implicitly {@link PokerOutcome.verify|verify} messages.
         * @function encode
         * @memberof PokerOutcome
         * @static
         * @param {IPokerOutcome} message PokerOutcome message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PokerOutcome.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tables != null && message.tables.length)
                for (var i = 0; i < message.tables.length; ++i)
                    $root.PokerOutcome.PokerTableOutcome.encode(message.tables[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified PokerOutcome message, length delimited. Does not implicitly {@link PokerOutcome.verify|verify} messages.
         * @function encodeDelimited
         * @memberof PokerOutcome
         * @static
         * @param {IPokerOutcome} message PokerOutcome message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PokerOutcome.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a PokerOutcome message from the specified reader or buffer.
         * @function decode
         * @memberof PokerOutcome
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PokerOutcome} PokerOutcome
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PokerOutcome.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PokerOutcome();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.tables && message.tables.length))
                        message.tables = [];
                    message.tables.push($root.PokerOutcome.PokerTableOutcome.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a PokerOutcome message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof PokerOutcome
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {PokerOutcome} PokerOutcome
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PokerOutcome.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a PokerOutcome message.
         * @function verify
         * @memberof PokerOutcome
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PokerOutcome.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tables != null && message.hasOwnProperty("tables")) {
                if (!Array.isArray(message.tables))
                    return "tables: array expected";
                for (var i = 0; i < message.tables.length; ++i) {
                    var error = $root.PokerOutcome.PokerTableOutcome.verify(message.tables[i]);
                    if (error)
                        return "tables." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a PokerOutcome message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof PokerOutcome
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {PokerOutcome} PokerOutcome
         */
        PokerOutcome.fromObject = function fromObject(object) {
            if (object instanceof $root.PokerOutcome)
                return object;
            var message = new $root.PokerOutcome();
            if (object.tables) {
                if (!Array.isArray(object.tables))
                    throw TypeError(".PokerOutcome.tables: array expected");
                message.tables = [];
                for (var i = 0; i < object.tables.length; ++i) {
                    if (typeof object.tables[i] !== "object")
                        throw TypeError(".PokerOutcome.tables: object expected");
                    message.tables[i] = $root.PokerOutcome.PokerTableOutcome.fromObject(object.tables[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a PokerOutcome message. Also converts values to other types if specified.
         * @function toObject
         * @memberof PokerOutcome
         * @static
         * @param {PokerOutcome} message PokerOutcome
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PokerOutcome.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.tables = [];
            if (message.tables && message.tables.length) {
                object.tables = [];
                for (var j = 0; j < message.tables.length; ++j)
                    object.tables[j] = $root.PokerOutcome.PokerTableOutcome.toObject(message.tables[j], options);
            }
            return object;
        };
    
        /**
         * Converts this PokerOutcome to JSON.
         * @function toJSON
         * @memberof PokerOutcome
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PokerOutcome.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        PokerOutcome.PokerPlayer = (function() {
    
            /**
             * Properties of a PokerPlayer.
             * @memberof PokerOutcome
             * @interface IPokerPlayer
             * @property {Array.<string>|null} [cards] PokerPlayer cards
             * @property {boolean|null} [win] PokerPlayer win
             */
    
            /**
             * Constructs a new PokerPlayer.
             * @memberof PokerOutcome
             * @classdesc Represents a PokerPlayer.
             * @implements IPokerPlayer
             * @constructor
             * @param {PokerOutcome.IPokerPlayer=} [properties] Properties to set
             */
            function PokerPlayer(properties) {
                this.cards = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PokerPlayer cards.
             * @member {Array.<string>} cards
             * @memberof PokerOutcome.PokerPlayer
             * @instance
             */
            PokerPlayer.prototype.cards = $util.emptyArray;
    
            /**
             * PokerPlayer win.
             * @member {boolean} win
             * @memberof PokerOutcome.PokerPlayer
             * @instance
             */
            PokerPlayer.prototype.win = false;
    
            /**
             * Creates a new PokerPlayer instance using the specified properties.
             * @function create
             * @memberof PokerOutcome.PokerPlayer
             * @static
             * @param {PokerOutcome.IPokerPlayer=} [properties] Properties to set
             * @returns {PokerOutcome.PokerPlayer} PokerPlayer instance
             */
            PokerPlayer.create = function create(properties) {
                return new PokerPlayer(properties);
            };
    
            /**
             * Encodes the specified PokerPlayer message. Does not implicitly {@link PokerOutcome.PokerPlayer.verify|verify} messages.
             * @function encode
             * @memberof PokerOutcome.PokerPlayer
             * @static
             * @param {PokerOutcome.IPokerPlayer} message PokerPlayer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PokerPlayer.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.cards != null && message.cards.length)
                    for (var i = 0; i < message.cards.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.cards[i]);
                if (message.win != null && Object.hasOwnProperty.call(message, "win"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.win);
                return writer;
            };
    
            /**
             * Encodes the specified PokerPlayer message, length delimited. Does not implicitly {@link PokerOutcome.PokerPlayer.verify|verify} messages.
             * @function encodeDelimited
             * @memberof PokerOutcome.PokerPlayer
             * @static
             * @param {PokerOutcome.IPokerPlayer} message PokerPlayer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PokerPlayer.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PokerPlayer message from the specified reader or buffer.
             * @function decode
             * @memberof PokerOutcome.PokerPlayer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PokerOutcome.PokerPlayer} PokerPlayer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PokerPlayer.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PokerOutcome.PokerPlayer();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.cards && message.cards.length))
                            message.cards = [];
                        message.cards.push(reader.string());
                        break;
                    case 2:
                        message.win = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PokerPlayer message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof PokerOutcome.PokerPlayer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {PokerOutcome.PokerPlayer} PokerPlayer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PokerPlayer.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PokerPlayer message.
             * @function verify
             * @memberof PokerOutcome.PokerPlayer
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PokerPlayer.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.cards != null && message.hasOwnProperty("cards")) {
                    if (!Array.isArray(message.cards))
                        return "cards: array expected";
                    for (var i = 0; i < message.cards.length; ++i)
                        if (!$util.isString(message.cards[i]))
                            return "cards: string[] expected";
                }
                if (message.win != null && message.hasOwnProperty("win"))
                    if (typeof message.win !== "boolean")
                        return "win: boolean expected";
                return null;
            };
    
            /**
             * Creates a PokerPlayer message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof PokerOutcome.PokerPlayer
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {PokerOutcome.PokerPlayer} PokerPlayer
             */
            PokerPlayer.fromObject = function fromObject(object) {
                if (object instanceof $root.PokerOutcome.PokerPlayer)
                    return object;
                var message = new $root.PokerOutcome.PokerPlayer();
                if (object.cards) {
                    if (!Array.isArray(object.cards))
                        throw TypeError(".PokerOutcome.PokerPlayer.cards: array expected");
                    message.cards = [];
                    for (var i = 0; i < object.cards.length; ++i)
                        message.cards[i] = String(object.cards[i]);
                }
                if (object.win != null)
                    message.win = Boolean(object.win);
                return message;
            };
    
            /**
             * Creates a plain object from a PokerPlayer message. Also converts values to other types if specified.
             * @function toObject
             * @memberof PokerOutcome.PokerPlayer
             * @static
             * @param {PokerOutcome.PokerPlayer} message PokerPlayer
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PokerPlayer.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.cards = [];
                if (options.defaults)
                    object.win = false;
                if (message.cards && message.cards.length) {
                    object.cards = [];
                    for (var j = 0; j < message.cards.length; ++j)
                        object.cards[j] = message.cards[j];
                }
                if (message.win != null && message.hasOwnProperty("win"))
                    object.win = message.win;
                return object;
            };
    
            /**
             * Converts this PokerPlayer to JSON.
             * @function toJSON
             * @memberof PokerOutcome.PokerPlayer
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PokerPlayer.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return PokerPlayer;
        })();
    
        PokerOutcome.PokerTableOutcome = (function() {
    
            /**
             * Properties of a PokerTableOutcome.
             * @memberof PokerOutcome
             * @interface IPokerTableOutcome
             * @property {string|null} [bestHand] PokerTableOutcome bestHand
             * @property {Array.<string>|null} [tableCards] PokerTableOutcome tableCards
             * @property {Array.<PokerOutcome.IPokerPlayer>|null} [players] PokerTableOutcome players
             */
    
            /**
             * Constructs a new PokerTableOutcome.
             * @memberof PokerOutcome
             * @classdesc Represents a PokerTableOutcome.
             * @implements IPokerTableOutcome
             * @constructor
             * @param {PokerOutcome.IPokerTableOutcome=} [properties] Properties to set
             */
            function PokerTableOutcome(properties) {
                this.tableCards = [];
                this.players = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * PokerTableOutcome bestHand.
             * @member {string} bestHand
             * @memberof PokerOutcome.PokerTableOutcome
             * @instance
             */
            PokerTableOutcome.prototype.bestHand = "";
    
            /**
             * PokerTableOutcome tableCards.
             * @member {Array.<string>} tableCards
             * @memberof PokerOutcome.PokerTableOutcome
             * @instance
             */
            PokerTableOutcome.prototype.tableCards = $util.emptyArray;
    
            /**
             * PokerTableOutcome players.
             * @member {Array.<PokerOutcome.IPokerPlayer>} players
             * @memberof PokerOutcome.PokerTableOutcome
             * @instance
             */
            PokerTableOutcome.prototype.players = $util.emptyArray;
    
            /**
             * Creates a new PokerTableOutcome instance using the specified properties.
             * @function create
             * @memberof PokerOutcome.PokerTableOutcome
             * @static
             * @param {PokerOutcome.IPokerTableOutcome=} [properties] Properties to set
             * @returns {PokerOutcome.PokerTableOutcome} PokerTableOutcome instance
             */
            PokerTableOutcome.create = function create(properties) {
                return new PokerTableOutcome(properties);
            };
    
            /**
             * Encodes the specified PokerTableOutcome message. Does not implicitly {@link PokerOutcome.PokerTableOutcome.verify|verify} messages.
             * @function encode
             * @memberof PokerOutcome.PokerTableOutcome
             * @static
             * @param {PokerOutcome.IPokerTableOutcome} message PokerTableOutcome message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PokerTableOutcome.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.bestHand != null && Object.hasOwnProperty.call(message, "bestHand"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.bestHand);
                if (message.tableCards != null && message.tableCards.length)
                    for (var i = 0; i < message.tableCards.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.tableCards[i]);
                if (message.players != null && message.players.length)
                    for (var i = 0; i < message.players.length; ++i)
                        $root.PokerOutcome.PokerPlayer.encode(message.players[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };
    
            /**
             * Encodes the specified PokerTableOutcome message, length delimited. Does not implicitly {@link PokerOutcome.PokerTableOutcome.verify|verify} messages.
             * @function encodeDelimited
             * @memberof PokerOutcome.PokerTableOutcome
             * @static
             * @param {PokerOutcome.IPokerTableOutcome} message PokerTableOutcome message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PokerTableOutcome.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a PokerTableOutcome message from the specified reader or buffer.
             * @function decode
             * @memberof PokerOutcome.PokerTableOutcome
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PokerOutcome.PokerTableOutcome} PokerTableOutcome
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PokerTableOutcome.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PokerOutcome.PokerTableOutcome();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.bestHand = reader.string();
                        break;
                    case 2:
                        if (!(message.tableCards && message.tableCards.length))
                            message.tableCards = [];
                        message.tableCards.push(reader.string());
                        break;
                    case 3:
                        if (!(message.players && message.players.length))
                            message.players = [];
                        message.players.push($root.PokerOutcome.PokerPlayer.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a PokerTableOutcome message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof PokerOutcome.PokerTableOutcome
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {PokerOutcome.PokerTableOutcome} PokerTableOutcome
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PokerTableOutcome.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a PokerTableOutcome message.
             * @function verify
             * @memberof PokerOutcome.PokerTableOutcome
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PokerTableOutcome.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.bestHand != null && message.hasOwnProperty("bestHand"))
                    if (!$util.isString(message.bestHand))
                        return "bestHand: string expected";
                if (message.tableCards != null && message.hasOwnProperty("tableCards")) {
                    if (!Array.isArray(message.tableCards))
                        return "tableCards: array expected";
                    for (var i = 0; i < message.tableCards.length; ++i)
                        if (!$util.isString(message.tableCards[i]))
                            return "tableCards: string[] expected";
                }
                if (message.players != null && message.hasOwnProperty("players")) {
                    if (!Array.isArray(message.players))
                        return "players: array expected";
                    for (var i = 0; i < message.players.length; ++i) {
                        var error = $root.PokerOutcome.PokerPlayer.verify(message.players[i]);
                        if (error)
                            return "players." + error;
                    }
                }
                return null;
            };
    
            /**
             * Creates a PokerTableOutcome message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof PokerOutcome.PokerTableOutcome
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {PokerOutcome.PokerTableOutcome} PokerTableOutcome
             */
            PokerTableOutcome.fromObject = function fromObject(object) {
                if (object instanceof $root.PokerOutcome.PokerTableOutcome)
                    return object;
                var message = new $root.PokerOutcome.PokerTableOutcome();
                if (object.bestHand != null)
                    message.bestHand = String(object.bestHand);
                if (object.tableCards) {
                    if (!Array.isArray(object.tableCards))
                        throw TypeError(".PokerOutcome.PokerTableOutcome.tableCards: array expected");
                    message.tableCards = [];
                    for (var i = 0; i < object.tableCards.length; ++i)
                        message.tableCards[i] = String(object.tableCards[i]);
                }
                if (object.players) {
                    if (!Array.isArray(object.players))
                        throw TypeError(".PokerOutcome.PokerTableOutcome.players: array expected");
                    message.players = [];
                    for (var i = 0; i < object.players.length; ++i) {
                        if (typeof object.players[i] !== "object")
                            throw TypeError(".PokerOutcome.PokerTableOutcome.players: object expected");
                        message.players[i] = $root.PokerOutcome.PokerPlayer.fromObject(object.players[i]);
                    }
                }
                return message;
            };
    
            /**
             * Creates a plain object from a PokerTableOutcome message. Also converts values to other types if specified.
             * @function toObject
             * @memberof PokerOutcome.PokerTableOutcome
             * @static
             * @param {PokerOutcome.PokerTableOutcome} message PokerTableOutcome
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PokerTableOutcome.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.tableCards = [];
                    object.players = [];
                }
                if (options.defaults)
                    object.bestHand = "";
                if (message.bestHand != null && message.hasOwnProperty("bestHand"))
                    object.bestHand = message.bestHand;
                if (message.tableCards && message.tableCards.length) {
                    object.tableCards = [];
                    for (var j = 0; j < message.tableCards.length; ++j)
                        object.tableCards[j] = message.tableCards[j];
                }
                if (message.players && message.players.length) {
                    object.players = [];
                    for (var j = 0; j < message.players.length; ++j)
                        object.players[j] = $root.PokerOutcome.PokerPlayer.toObject(message.players[j], options);
                }
                return object;
            };
    
            /**
             * Converts this PokerTableOutcome to JSON.
             * @function toJSON
             * @memberof PokerOutcome.PokerTableOutcome
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PokerTableOutcome.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return PokerTableOutcome;
        })();
    
        return PokerOutcome;
    })();
    
    $root.BmHistoryEntry = (function() {
    
        /**
         * Properties of a BmHistoryEntry.
         * @exports IBmHistoryEntry
         * @interface IBmHistoryEntry
         * @property {Long|null} [round] BmHistoryEntry round
         * @property {Long|null} [bettingStamp] BmHistoryEntry bettingStamp
         * @property {Long|null} [playingStamp] BmHistoryEntry playingStamp
         * @property {IBallOutcome|null} [ball] BmHistoryEntry ball
         * @property {IBallsOutcome|null} [balls] BmHistoryEntry balls
         * @property {IVSportsOutcome|null} [vsports] BmHistoryEntry vsports
         * @property {ILucky6Outcome|null} [lucky6] BmHistoryEntry lucky6
         * @property {IRacesOutcome|null} [races] BmHistoryEntry races
         * @property {IPokerOutcome|null} [poker] BmHistoryEntry poker
         */
    
        /**
         * Constructs a new BmHistoryEntry.
         * @exports BmHistoryEntry
         * @classdesc Represents a BmHistoryEntry.
         * @implements IBmHistoryEntry
         * @constructor
         * @param {IBmHistoryEntry=} [properties] Properties to set
         */
        function BmHistoryEntry(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * BmHistoryEntry round.
         * @member {Long} round
         * @memberof BmHistoryEntry
         * @instance
         */
        BmHistoryEntry.prototype.round = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * BmHistoryEntry bettingStamp.
         * @member {Long} bettingStamp
         * @memberof BmHistoryEntry
         * @instance
         */
        BmHistoryEntry.prototype.bettingStamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * BmHistoryEntry playingStamp.
         * @member {Long} playingStamp
         * @memberof BmHistoryEntry
         * @instance
         */
        BmHistoryEntry.prototype.playingStamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * BmHistoryEntry ball.
         * @member {IBallOutcome|null|undefined} ball
         * @memberof BmHistoryEntry
         * @instance
         */
        BmHistoryEntry.prototype.ball = null;
    
        /**
         * BmHistoryEntry balls.
         * @member {IBallsOutcome|null|undefined} balls
         * @memberof BmHistoryEntry
         * @instance
         */
        BmHistoryEntry.prototype.balls = null;
    
        /**
         * BmHistoryEntry vsports.
         * @member {IVSportsOutcome|null|undefined} vsports
         * @memberof BmHistoryEntry
         * @instance
         */
        BmHistoryEntry.prototype.vsports = null;
    
        /**
         * BmHistoryEntry lucky6.
         * @member {ILucky6Outcome|null|undefined} lucky6
         * @memberof BmHistoryEntry
         * @instance
         */
        BmHistoryEntry.prototype.lucky6 = null;
    
        /**
         * BmHistoryEntry races.
         * @member {IRacesOutcome|null|undefined} races
         * @memberof BmHistoryEntry
         * @instance
         */
        BmHistoryEntry.prototype.races = null;
    
        /**
         * BmHistoryEntry poker.
         * @member {IPokerOutcome|null|undefined} poker
         * @memberof BmHistoryEntry
         * @instance
         */
        BmHistoryEntry.prototype.poker = null;
    
        /**
         * Creates a new BmHistoryEntry instance using the specified properties.
         * @function create
         * @memberof BmHistoryEntry
         * @static
         * @param {IBmHistoryEntry=} [properties] Properties to set
         * @returns {BmHistoryEntry} BmHistoryEntry instance
         */
        BmHistoryEntry.create = function create(properties) {
            return new BmHistoryEntry(properties);
        };
    
        /**
         * Encodes the specified BmHistoryEntry message. Does not implicitly {@link BmHistoryEntry.verify|verify} messages.
         * @function encode
         * @memberof BmHistoryEntry
         * @static
         * @param {IBmHistoryEntry} message BmHistoryEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BmHistoryEntry.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.round != null && Object.hasOwnProperty.call(message, "round"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.round);
            if (message.bettingStamp != null && Object.hasOwnProperty.call(message, "bettingStamp"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.bettingStamp);
            if (message.playingStamp != null && Object.hasOwnProperty.call(message, "playingStamp"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.playingStamp);
            if (message.ball != null && Object.hasOwnProperty.call(message, "ball"))
                $root.BallOutcome.encode(message.ball, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.balls != null && Object.hasOwnProperty.call(message, "balls"))
                $root.BallsOutcome.encode(message.balls, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.vsports != null && Object.hasOwnProperty.call(message, "vsports"))
                $root.VSportsOutcome.encode(message.vsports, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.lucky6 != null && Object.hasOwnProperty.call(message, "lucky6"))
                $root.Lucky6Outcome.encode(message.lucky6, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.races != null && Object.hasOwnProperty.call(message, "races"))
                $root.RacesOutcome.encode(message.races, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.poker != null && Object.hasOwnProperty.call(message, "poker"))
                $root.PokerOutcome.encode(message.poker, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified BmHistoryEntry message, length delimited. Does not implicitly {@link BmHistoryEntry.verify|verify} messages.
         * @function encodeDelimited
         * @memberof BmHistoryEntry
         * @static
         * @param {IBmHistoryEntry} message BmHistoryEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BmHistoryEntry.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a BmHistoryEntry message from the specified reader or buffer.
         * @function decode
         * @memberof BmHistoryEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {BmHistoryEntry} BmHistoryEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BmHistoryEntry.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.BmHistoryEntry();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.round = reader.uint64();
                    break;
                case 2:
                    message.bettingStamp = reader.uint64();
                    break;
                case 3:
                    message.playingStamp = reader.uint64();
                    break;
                case 10:
                    message.ball = $root.BallOutcome.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.balls = $root.BallsOutcome.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.vsports = $root.VSportsOutcome.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.lucky6 = $root.Lucky6Outcome.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.races = $root.RacesOutcome.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.poker = $root.PokerOutcome.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a BmHistoryEntry message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof BmHistoryEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {BmHistoryEntry} BmHistoryEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BmHistoryEntry.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a BmHistoryEntry message.
         * @function verify
         * @memberof BmHistoryEntry
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BmHistoryEntry.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.round != null && message.hasOwnProperty("round"))
                if (!$util.isInteger(message.round) && !(message.round && $util.isInteger(message.round.low) && $util.isInteger(message.round.high)))
                    return "round: integer|Long expected";
            if (message.bettingStamp != null && message.hasOwnProperty("bettingStamp"))
                if (!$util.isInteger(message.bettingStamp) && !(message.bettingStamp && $util.isInteger(message.bettingStamp.low) && $util.isInteger(message.bettingStamp.high)))
                    return "bettingStamp: integer|Long expected";
            if (message.playingStamp != null && message.hasOwnProperty("playingStamp"))
                if (!$util.isInteger(message.playingStamp) && !(message.playingStamp && $util.isInteger(message.playingStamp.low) && $util.isInteger(message.playingStamp.high)))
                    return "playingStamp: integer|Long expected";
            if (message.ball != null && message.hasOwnProperty("ball")) {
                var error = $root.BallOutcome.verify(message.ball);
                if (error)
                    return "ball." + error;
            }
            if (message.balls != null && message.hasOwnProperty("balls")) {
                var error = $root.BallsOutcome.verify(message.balls);
                if (error)
                    return "balls." + error;
            }
            if (message.vsports != null && message.hasOwnProperty("vsports")) {
                var error = $root.VSportsOutcome.verify(message.vsports);
                if (error)
                    return "vsports." + error;
            }
            if (message.lucky6 != null && message.hasOwnProperty("lucky6")) {
                var error = $root.Lucky6Outcome.verify(message.lucky6);
                if (error)
                    return "lucky6." + error;
            }
            if (message.races != null && message.hasOwnProperty("races")) {
                var error = $root.RacesOutcome.verify(message.races);
                if (error)
                    return "races." + error;
            }
            if (message.poker != null && message.hasOwnProperty("poker")) {
                var error = $root.PokerOutcome.verify(message.poker);
                if (error)
                    return "poker." + error;
            }
            return null;
        };
    
        /**
         * Creates a BmHistoryEntry message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof BmHistoryEntry
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {BmHistoryEntry} BmHistoryEntry
         */
        BmHistoryEntry.fromObject = function fromObject(object) {
            if (object instanceof $root.BmHistoryEntry)
                return object;
            var message = new $root.BmHistoryEntry();
            if (object.round != null)
                if ($util.Long)
                    (message.round = $util.Long.fromValue(object.round)).unsigned = true;
                else if (typeof object.round === "string")
                    message.round = parseInt(object.round, 10);
                else if (typeof object.round === "number")
                    message.round = object.round;
                else if (typeof object.round === "object")
                    message.round = new $util.LongBits(object.round.low >>> 0, object.round.high >>> 0).toNumber(true);
            if (object.bettingStamp != null)
                if ($util.Long)
                    (message.bettingStamp = $util.Long.fromValue(object.bettingStamp)).unsigned = true;
                else if (typeof object.bettingStamp === "string")
                    message.bettingStamp = parseInt(object.bettingStamp, 10);
                else if (typeof object.bettingStamp === "number")
                    message.bettingStamp = object.bettingStamp;
                else if (typeof object.bettingStamp === "object")
                    message.bettingStamp = new $util.LongBits(object.bettingStamp.low >>> 0, object.bettingStamp.high >>> 0).toNumber(true);
            if (object.playingStamp != null)
                if ($util.Long)
                    (message.playingStamp = $util.Long.fromValue(object.playingStamp)).unsigned = true;
                else if (typeof object.playingStamp === "string")
                    message.playingStamp = parseInt(object.playingStamp, 10);
                else if (typeof object.playingStamp === "number")
                    message.playingStamp = object.playingStamp;
                else if (typeof object.playingStamp === "object")
                    message.playingStamp = new $util.LongBits(object.playingStamp.low >>> 0, object.playingStamp.high >>> 0).toNumber(true);
            if (object.ball != null) {
                if (typeof object.ball !== "object")
                    throw TypeError(".BmHistoryEntry.ball: object expected");
                message.ball = $root.BallOutcome.fromObject(object.ball);
            }
            if (object.balls != null) {
                if (typeof object.balls !== "object")
                    throw TypeError(".BmHistoryEntry.balls: object expected");
                message.balls = $root.BallsOutcome.fromObject(object.balls);
            }
            if (object.vsports != null) {
                if (typeof object.vsports !== "object")
                    throw TypeError(".BmHistoryEntry.vsports: object expected");
                message.vsports = $root.VSportsOutcome.fromObject(object.vsports);
            }
            if (object.lucky6 != null) {
                if (typeof object.lucky6 !== "object")
                    throw TypeError(".BmHistoryEntry.lucky6: object expected");
                message.lucky6 = $root.Lucky6Outcome.fromObject(object.lucky6);
            }
            if (object.races != null) {
                if (typeof object.races !== "object")
                    throw TypeError(".BmHistoryEntry.races: object expected");
                message.races = $root.RacesOutcome.fromObject(object.races);
            }
            if (object.poker != null) {
                if (typeof object.poker !== "object")
                    throw TypeError(".BmHistoryEntry.poker: object expected");
                message.poker = $root.PokerOutcome.fromObject(object.poker);
            }
            return message;
        };
    
        /**
         * Creates a plain object from a BmHistoryEntry message. Also converts values to other types if specified.
         * @function toObject
         * @memberof BmHistoryEntry
         * @static
         * @param {BmHistoryEntry} message BmHistoryEntry
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BmHistoryEntry.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.round = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.round = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.bettingStamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.bettingStamp = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.playingStamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.playingStamp = options.longs === String ? "0" : 0;
                object.ball = null;
                object.balls = null;
                object.vsports = null;
                object.lucky6 = null;
                object.races = null;
                object.poker = null;
            }
            if (message.round != null && message.hasOwnProperty("round"))
                if (typeof message.round === "number")
                    object.round = options.longs === String ? String(message.round) : message.round;
                else
                    object.round = options.longs === String ? $util.Long.prototype.toString.call(message.round) : options.longs === Number ? new $util.LongBits(message.round.low >>> 0, message.round.high >>> 0).toNumber(true) : message.round;
            if (message.bettingStamp != null && message.hasOwnProperty("bettingStamp"))
                if (typeof message.bettingStamp === "number")
                    object.bettingStamp = options.longs === String ? String(message.bettingStamp) : message.bettingStamp;
                else
                    object.bettingStamp = options.longs === String ? $util.Long.prototype.toString.call(message.bettingStamp) : options.longs === Number ? new $util.LongBits(message.bettingStamp.low >>> 0, message.bettingStamp.high >>> 0).toNumber(true) : message.bettingStamp;
            if (message.playingStamp != null && message.hasOwnProperty("playingStamp"))
                if (typeof message.playingStamp === "number")
                    object.playingStamp = options.longs === String ? String(message.playingStamp) : message.playingStamp;
                else
                    object.playingStamp = options.longs === String ? $util.Long.prototype.toString.call(message.playingStamp) : options.longs === Number ? new $util.LongBits(message.playingStamp.low >>> 0, message.playingStamp.high >>> 0).toNumber(true) : message.playingStamp;
            if (message.ball != null && message.hasOwnProperty("ball"))
                object.ball = $root.BallOutcome.toObject(message.ball, options);
            if (message.balls != null && message.hasOwnProperty("balls"))
                object.balls = $root.BallsOutcome.toObject(message.balls, options);
            if (message.vsports != null && message.hasOwnProperty("vsports"))
                object.vsports = $root.VSportsOutcome.toObject(message.vsports, options);
            if (message.lucky6 != null && message.hasOwnProperty("lucky6"))
                object.lucky6 = $root.Lucky6Outcome.toObject(message.lucky6, options);
            if (message.races != null && message.hasOwnProperty("races"))
                object.races = $root.RacesOutcome.toObject(message.races, options);
            if (message.poker != null && message.hasOwnProperty("poker"))
                object.poker = $root.PokerOutcome.toObject(message.poker, options);
            return object;
        };
    
        /**
         * Converts this BmHistoryEntry to JSON.
         * @function toJSON
         * @memberof BmHistoryEntry
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BmHistoryEntry.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return BmHistoryEntry;
    })();
    
    $root.MonitorRequest = (function() {
    
        /**
         * Properties of a MonitorRequest.
         * @exports IMonitorRequest
         * @interface IMonitorRequest
         * @property {number|null} [accId] MonitorRequest accId
         * @property {number|null} [source] MonitorRequest source
         * @property {string|null} [signature] MonitorRequest signature
         * @property {Array.<IMonitorActionRequest>|null} [actions] MonitorRequest actions
         */
    
        /**
         * Constructs a new MonitorRequest.
         * @exports MonitorRequest
         * @classdesc Represents a MonitorRequest.
         * @implements IMonitorRequest
         * @constructor
         * @param {IMonitorRequest=} [properties] Properties to set
         */
        function MonitorRequest(properties) {
            this.actions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * MonitorRequest accId.
         * @member {number} accId
         * @memberof MonitorRequest
         * @instance
         */
        MonitorRequest.prototype.accId = 0;
    
        /**
         * MonitorRequest source.
         * @member {number} source
         * @memberof MonitorRequest
         * @instance
         */
        MonitorRequest.prototype.source = 0;
    
        /**
         * MonitorRequest signature.
         * @member {string} signature
         * @memberof MonitorRequest
         * @instance
         */
        MonitorRequest.prototype.signature = "";
    
        /**
         * MonitorRequest actions.
         * @member {Array.<IMonitorActionRequest>} actions
         * @memberof MonitorRequest
         * @instance
         */
        MonitorRequest.prototype.actions = $util.emptyArray;
    
        /**
         * Creates a new MonitorRequest instance using the specified properties.
         * @function create
         * @memberof MonitorRequest
         * @static
         * @param {IMonitorRequest=} [properties] Properties to set
         * @returns {MonitorRequest} MonitorRequest instance
         */
        MonitorRequest.create = function create(properties) {
            return new MonitorRequest(properties);
        };
    
        /**
         * Encodes the specified MonitorRequest message. Does not implicitly {@link MonitorRequest.verify|verify} messages.
         * @function encode
         * @memberof MonitorRequest
         * @static
         * @param {IMonitorRequest} message MonitorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.accId != null && Object.hasOwnProperty.call(message, "accId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.accId);
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.source);
            if (message.signature != null && Object.hasOwnProperty.call(message, "signature"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.signature);
            if (message.actions != null && message.actions.length)
                for (var i = 0; i < message.actions.length; ++i)
                    $root.MonitorActionRequest.encode(message.actions[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified MonitorRequest message, length delimited. Does not implicitly {@link MonitorRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MonitorRequest
         * @static
         * @param {IMonitorRequest} message MonitorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a MonitorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof MonitorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MonitorRequest} MonitorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.MonitorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.accId = reader.uint32();
                    break;
                case 2:
                    message.source = reader.uint32();
                    break;
                case 4:
                    message.signature = reader.string();
                    break;
                case 10:
                    if (!(message.actions && message.actions.length))
                        message.actions = [];
                    message.actions.push($root.MonitorActionRequest.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a MonitorRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MonitorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MonitorRequest} MonitorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a MonitorRequest message.
         * @function verify
         * @memberof MonitorRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MonitorRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.accId != null && message.hasOwnProperty("accId"))
                if (!$util.isInteger(message.accId))
                    return "accId: integer expected";
            if (message.source != null && message.hasOwnProperty("source"))
                if (!$util.isInteger(message.source))
                    return "source: integer expected";
            if (message.signature != null && message.hasOwnProperty("signature"))
                if (!$util.isString(message.signature))
                    return "signature: string expected";
            if (message.actions != null && message.hasOwnProperty("actions")) {
                if (!Array.isArray(message.actions))
                    return "actions: array expected";
                for (var i = 0; i < message.actions.length; ++i) {
                    var error = $root.MonitorActionRequest.verify(message.actions[i]);
                    if (error)
                        return "actions." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a MonitorRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MonitorRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MonitorRequest} MonitorRequest
         */
        MonitorRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.MonitorRequest)
                return object;
            var message = new $root.MonitorRequest();
            if (object.accId != null)
                message.accId = object.accId >>> 0;
            if (object.source != null)
                message.source = object.source >>> 0;
            if (object.signature != null)
                message.signature = String(object.signature);
            if (object.actions) {
                if (!Array.isArray(object.actions))
                    throw TypeError(".MonitorRequest.actions: array expected");
                message.actions = [];
                for (var i = 0; i < object.actions.length; ++i) {
                    if (typeof object.actions[i] !== "object")
                        throw TypeError(".MonitorRequest.actions: object expected");
                    message.actions[i] = $root.MonitorActionRequest.fromObject(object.actions[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a MonitorRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MonitorRequest
         * @static
         * @param {MonitorRequest} message MonitorRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MonitorRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.actions = [];
            if (options.defaults) {
                object.accId = 0;
                object.source = 0;
                object.signature = "";
            }
            if (message.accId != null && message.hasOwnProperty("accId"))
                object.accId = message.accId;
            if (message.source != null && message.hasOwnProperty("source"))
                object.source = message.source;
            if (message.signature != null && message.hasOwnProperty("signature"))
                object.signature = message.signature;
            if (message.actions && message.actions.length) {
                object.actions = [];
                for (var j = 0; j < message.actions.length; ++j)
                    object.actions[j] = $root.MonitorActionRequest.toObject(message.actions[j], options);
            }
            return object;
        };
    
        /**
         * Converts this MonitorRequest to JSON.
         * @function toJSON
         * @memberof MonitorRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MonitorRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return MonitorRequest;
    })();
    
    $root.MonitorActionRequest = (function() {
    
        /**
         * Properties of a MonitorActionRequest.
         * @exports IMonitorActionRequest
         * @interface IMonitorActionRequest
         * @property {string|null} [trx] MonitorActionRequest trx
         * @property {IMonitorConfigRequest|null} [config] MonitorActionRequest config
         * @property {IMonitorCheckEventRequest|null} [checkEvent] MonitorActionRequest checkEvent
         * @property {IMonitorCheckBarcodeRequest|null} [checkBarcode] MonitorActionRequest checkBarcode
         * @property {IMonitorJackpotsRequest|null} [jackpots] MonitorActionRequest jackpots
         */
    
        /**
         * Constructs a new MonitorActionRequest.
         * @exports MonitorActionRequest
         * @classdesc Represents a MonitorActionRequest.
         * @implements IMonitorActionRequest
         * @constructor
         * @param {IMonitorActionRequest=} [properties] Properties to set
         */
        function MonitorActionRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * MonitorActionRequest trx.
         * @member {string} trx
         * @memberof MonitorActionRequest
         * @instance
         */
        MonitorActionRequest.prototype.trx = "";
    
        /**
         * MonitorActionRequest config.
         * @member {IMonitorConfigRequest|null|undefined} config
         * @memberof MonitorActionRequest
         * @instance
         */
        MonitorActionRequest.prototype.config = null;
    
        /**
         * MonitorActionRequest checkEvent.
         * @member {IMonitorCheckEventRequest|null|undefined} checkEvent
         * @memberof MonitorActionRequest
         * @instance
         */
        MonitorActionRequest.prototype.checkEvent = null;
    
        /**
         * MonitorActionRequest checkBarcode.
         * @member {IMonitorCheckBarcodeRequest|null|undefined} checkBarcode
         * @memberof MonitorActionRequest
         * @instance
         */
        MonitorActionRequest.prototype.checkBarcode = null;
    
        /**
         * MonitorActionRequest jackpots.
         * @member {IMonitorJackpotsRequest|null|undefined} jackpots
         * @memberof MonitorActionRequest
         * @instance
         */
        MonitorActionRequest.prototype.jackpots = null;
    
        /**
         * Creates a new MonitorActionRequest instance using the specified properties.
         * @function create
         * @memberof MonitorActionRequest
         * @static
         * @param {IMonitorActionRequest=} [properties] Properties to set
         * @returns {MonitorActionRequest} MonitorActionRequest instance
         */
        MonitorActionRequest.create = function create(properties) {
            return new MonitorActionRequest(properties);
        };
    
        /**
         * Encodes the specified MonitorActionRequest message. Does not implicitly {@link MonitorActionRequest.verify|verify} messages.
         * @function encode
         * @memberof MonitorActionRequest
         * @static
         * @param {IMonitorActionRequest} message MonitorActionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorActionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.trx != null && Object.hasOwnProperty.call(message, "trx"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.trx);
            if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                $root.MonitorConfigRequest.encode(message.config, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.checkEvent != null && Object.hasOwnProperty.call(message, "checkEvent"))
                $root.MonitorCheckEventRequest.encode(message.checkEvent, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.checkBarcode != null && Object.hasOwnProperty.call(message, "checkBarcode"))
                $root.MonitorCheckBarcodeRequest.encode(message.checkBarcode, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.jackpots != null && Object.hasOwnProperty.call(message, "jackpots"))
                $root.MonitorJackpotsRequest.encode(message.jackpots, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified MonitorActionRequest message, length delimited. Does not implicitly {@link MonitorActionRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MonitorActionRequest
         * @static
         * @param {IMonitorActionRequest} message MonitorActionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorActionRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a MonitorActionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof MonitorActionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MonitorActionRequest} MonitorActionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorActionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.MonitorActionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.trx = reader.string();
                    break;
                case 10:
                    message.config = $root.MonitorConfigRequest.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.checkEvent = $root.MonitorCheckEventRequest.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.checkBarcode = $root.MonitorCheckBarcodeRequest.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.jackpots = $root.MonitorJackpotsRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a MonitorActionRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MonitorActionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MonitorActionRequest} MonitorActionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorActionRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a MonitorActionRequest message.
         * @function verify
         * @memberof MonitorActionRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MonitorActionRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.trx != null && message.hasOwnProperty("trx"))
                if (!$util.isString(message.trx))
                    return "trx: string expected";
            if (message.config != null && message.hasOwnProperty("config")) {
                var error = $root.MonitorConfigRequest.verify(message.config);
                if (error)
                    return "config." + error;
            }
            if (message.checkEvent != null && message.hasOwnProperty("checkEvent")) {
                var error = $root.MonitorCheckEventRequest.verify(message.checkEvent);
                if (error)
                    return "checkEvent." + error;
            }
            if (message.checkBarcode != null && message.hasOwnProperty("checkBarcode")) {
                var error = $root.MonitorCheckBarcodeRequest.verify(message.checkBarcode);
                if (error)
                    return "checkBarcode." + error;
            }
            if (message.jackpots != null && message.hasOwnProperty("jackpots")) {
                var error = $root.MonitorJackpotsRequest.verify(message.jackpots);
                if (error)
                    return "jackpots." + error;
            }
            return null;
        };
    
        /**
         * Creates a MonitorActionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MonitorActionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MonitorActionRequest} MonitorActionRequest
         */
        MonitorActionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.MonitorActionRequest)
                return object;
            var message = new $root.MonitorActionRequest();
            if (object.trx != null)
                message.trx = String(object.trx);
            if (object.config != null) {
                if (typeof object.config !== "object")
                    throw TypeError(".MonitorActionRequest.config: object expected");
                message.config = $root.MonitorConfigRequest.fromObject(object.config);
            }
            if (object.checkEvent != null) {
                if (typeof object.checkEvent !== "object")
                    throw TypeError(".MonitorActionRequest.checkEvent: object expected");
                message.checkEvent = $root.MonitorCheckEventRequest.fromObject(object.checkEvent);
            }
            if (object.checkBarcode != null) {
                if (typeof object.checkBarcode !== "object")
                    throw TypeError(".MonitorActionRequest.checkBarcode: object expected");
                message.checkBarcode = $root.MonitorCheckBarcodeRequest.fromObject(object.checkBarcode);
            }
            if (object.jackpots != null) {
                if (typeof object.jackpots !== "object")
                    throw TypeError(".MonitorActionRequest.jackpots: object expected");
                message.jackpots = $root.MonitorJackpotsRequest.fromObject(object.jackpots);
            }
            return message;
        };
    
        /**
         * Creates a plain object from a MonitorActionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MonitorActionRequest
         * @static
         * @param {MonitorActionRequest} message MonitorActionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MonitorActionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.trx = "";
                object.config = null;
                object.checkEvent = null;
                object.checkBarcode = null;
                object.jackpots = null;
            }
            if (message.trx != null && message.hasOwnProperty("trx"))
                object.trx = message.trx;
            if (message.config != null && message.hasOwnProperty("config"))
                object.config = $root.MonitorConfigRequest.toObject(message.config, options);
            if (message.checkEvent != null && message.hasOwnProperty("checkEvent"))
                object.checkEvent = $root.MonitorCheckEventRequest.toObject(message.checkEvent, options);
            if (message.checkBarcode != null && message.hasOwnProperty("checkBarcode"))
                object.checkBarcode = $root.MonitorCheckBarcodeRequest.toObject(message.checkBarcode, options);
            if (message.jackpots != null && message.hasOwnProperty("jackpots"))
                object.jackpots = $root.MonitorJackpotsRequest.toObject(message.jackpots, options);
            return object;
        };
    
        /**
         * Converts this MonitorActionRequest to JSON.
         * @function toJSON
         * @memberof MonitorActionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MonitorActionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return MonitorActionRequest;
    })();
    
    $root.MonitorJackpotsRequest = (function() {
    
        /**
         * Properties of a MonitorJackpotsRequest.
         * @exports IMonitorJackpotsRequest
         * @interface IMonitorJackpotsRequest
         * @property {IJackpotsUnifiedWinnersRequest|null} [winners] MonitorJackpotsRequest winners
         */
    
        /**
         * Constructs a new MonitorJackpotsRequest.
         * @exports MonitorJackpotsRequest
         * @classdesc Represents a MonitorJackpotsRequest.
         * @implements IMonitorJackpotsRequest
         * @constructor
         * @param {IMonitorJackpotsRequest=} [properties] Properties to set
         */
        function MonitorJackpotsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * MonitorJackpotsRequest winners.
         * @member {IJackpotsUnifiedWinnersRequest|null|undefined} winners
         * @memberof MonitorJackpotsRequest
         * @instance
         */
        MonitorJackpotsRequest.prototype.winners = null;
    
        /**
         * Creates a new MonitorJackpotsRequest instance using the specified properties.
         * @function create
         * @memberof MonitorJackpotsRequest
         * @static
         * @param {IMonitorJackpotsRequest=} [properties] Properties to set
         * @returns {MonitorJackpotsRequest} MonitorJackpotsRequest instance
         */
        MonitorJackpotsRequest.create = function create(properties) {
            return new MonitorJackpotsRequest(properties);
        };
    
        /**
         * Encodes the specified MonitorJackpotsRequest message. Does not implicitly {@link MonitorJackpotsRequest.verify|verify} messages.
         * @function encode
         * @memberof MonitorJackpotsRequest
         * @static
         * @param {IMonitorJackpotsRequest} message MonitorJackpotsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorJackpotsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.winners != null && Object.hasOwnProperty.call(message, "winners"))
                $root.JackpotsUnifiedWinnersRequest.encode(message.winners, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified MonitorJackpotsRequest message, length delimited. Does not implicitly {@link MonitorJackpotsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MonitorJackpotsRequest
         * @static
         * @param {IMonitorJackpotsRequest} message MonitorJackpotsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorJackpotsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a MonitorJackpotsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof MonitorJackpotsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MonitorJackpotsRequest} MonitorJackpotsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorJackpotsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.MonitorJackpotsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.winners = $root.JackpotsUnifiedWinnersRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a MonitorJackpotsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MonitorJackpotsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MonitorJackpotsRequest} MonitorJackpotsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorJackpotsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a MonitorJackpotsRequest message.
         * @function verify
         * @memberof MonitorJackpotsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MonitorJackpotsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.winners != null && message.hasOwnProperty("winners")) {
                var error = $root.JackpotsUnifiedWinnersRequest.verify(message.winners);
                if (error)
                    return "winners." + error;
            }
            return null;
        };
    
        /**
         * Creates a MonitorJackpotsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MonitorJackpotsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MonitorJackpotsRequest} MonitorJackpotsRequest
         */
        MonitorJackpotsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.MonitorJackpotsRequest)
                return object;
            var message = new $root.MonitorJackpotsRequest();
            if (object.winners != null) {
                if (typeof object.winners !== "object")
                    throw TypeError(".MonitorJackpotsRequest.winners: object expected");
                message.winners = $root.JackpotsUnifiedWinnersRequest.fromObject(object.winners);
            }
            return message;
        };
    
        /**
         * Creates a plain object from a MonitorJackpotsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MonitorJackpotsRequest
         * @static
         * @param {MonitorJackpotsRequest} message MonitorJackpotsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MonitorJackpotsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.winners = null;
            if (message.winners != null && message.hasOwnProperty("winners"))
                object.winners = $root.JackpotsUnifiedWinnersRequest.toObject(message.winners, options);
            return object;
        };
    
        /**
         * Converts this MonitorJackpotsRequest to JSON.
         * @function toJSON
         * @memberof MonitorJackpotsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MonitorJackpotsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return MonitorJackpotsRequest;
    })();
    
    $root.MonitorResponse = (function() {
    
        /**
         * Properties of a MonitorResponse.
         * @exports IMonitorResponse
         * @interface IMonitorResponse
         * @property {MonitorResponse.MonitorResponseStatus|null} [status] MonitorResponse status
         * @property {string|null} [error] MonitorResponse error
         * @property {Array.<IMonitorActionResponse>|null} [actions] MonitorResponse actions
         */
    
        /**
         * Constructs a new MonitorResponse.
         * @exports MonitorResponse
         * @classdesc Represents a MonitorResponse.
         * @implements IMonitorResponse
         * @constructor
         * @param {IMonitorResponse=} [properties] Properties to set
         */
        function MonitorResponse(properties) {
            this.actions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * MonitorResponse status.
         * @member {MonitorResponse.MonitorResponseStatus} status
         * @memberof MonitorResponse
         * @instance
         */
        MonitorResponse.prototype.status = 0;
    
        /**
         * MonitorResponse error.
         * @member {string} error
         * @memberof MonitorResponse
         * @instance
         */
        MonitorResponse.prototype.error = "";
    
        /**
         * MonitorResponse actions.
         * @member {Array.<IMonitorActionResponse>} actions
         * @memberof MonitorResponse
         * @instance
         */
        MonitorResponse.prototype.actions = $util.emptyArray;
    
        /**
         * Creates a new MonitorResponse instance using the specified properties.
         * @function create
         * @memberof MonitorResponse
         * @static
         * @param {IMonitorResponse=} [properties] Properties to set
         * @returns {MonitorResponse} MonitorResponse instance
         */
        MonitorResponse.create = function create(properties) {
            return new MonitorResponse(properties);
        };
    
        /**
         * Encodes the specified MonitorResponse message. Does not implicitly {@link MonitorResponse.verify|verify} messages.
         * @function encode
         * @memberof MonitorResponse
         * @static
         * @param {IMonitorResponse} message MonitorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
            if (message.actions != null && message.actions.length)
                for (var i = 0; i < message.actions.length; ++i)
                    $root.MonitorActionResponse.encode(message.actions[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified MonitorResponse message, length delimited. Does not implicitly {@link MonitorResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MonitorResponse
         * @static
         * @param {IMonitorResponse} message MonitorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a MonitorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof MonitorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MonitorResponse} MonitorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.MonitorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.error = reader.string();
                    break;
                case 10:
                    if (!(message.actions && message.actions.length))
                        message.actions = [];
                    message.actions.push($root.MonitorActionResponse.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a MonitorResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MonitorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MonitorResponse} MonitorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a MonitorResponse message.
         * @function verify
         * @memberof MonitorResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MonitorResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            if (message.actions != null && message.hasOwnProperty("actions")) {
                if (!Array.isArray(message.actions))
                    return "actions: array expected";
                for (var i = 0; i < message.actions.length; ++i) {
                    var error = $root.MonitorActionResponse.verify(message.actions[i]);
                    if (error)
                        return "actions." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a MonitorResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MonitorResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MonitorResponse} MonitorResponse
         */
        MonitorResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.MonitorResponse)
                return object;
            var message = new $root.MonitorResponse();
            switch (object.status) {
            case "MRS_OK":
            case 0:
                message.status = 0;
                break;
            case "MRS_INVALID_SIGNATURE":
            case 1:
                message.status = 1;
                break;
            case "MRS_INVALID_REQUEST":
            case 2:
                message.status = 2;
                break;
            }
            if (object.error != null)
                message.error = String(object.error);
            if (object.actions) {
                if (!Array.isArray(object.actions))
                    throw TypeError(".MonitorResponse.actions: array expected");
                message.actions = [];
                for (var i = 0; i < object.actions.length; ++i) {
                    if (typeof object.actions[i] !== "object")
                        throw TypeError(".MonitorResponse.actions: object expected");
                    message.actions[i] = $root.MonitorActionResponse.fromObject(object.actions[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a MonitorResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MonitorResponse
         * @static
         * @param {MonitorResponse} message MonitorResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MonitorResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.actions = [];
            if (options.defaults) {
                object.status = options.enums === String ? "MRS_OK" : 0;
                object.error = "";
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.MonitorResponse.MonitorResponseStatus[message.status] : message.status;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            if (message.actions && message.actions.length) {
                object.actions = [];
                for (var j = 0; j < message.actions.length; ++j)
                    object.actions[j] = $root.MonitorActionResponse.toObject(message.actions[j], options);
            }
            return object;
        };
    
        /**
         * Converts this MonitorResponse to JSON.
         * @function toJSON
         * @memberof MonitorResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MonitorResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        /**
         * MonitorResponseStatus enum.
         * @name MonitorResponse.MonitorResponseStatus
         * @enum {number}
         * @property {number} MRS_OK=0 MRS_OK value
         * @property {number} MRS_INVALID_SIGNATURE=1 MRS_INVALID_SIGNATURE value
         * @property {number} MRS_INVALID_REQUEST=2 MRS_INVALID_REQUEST value
         */
        MonitorResponse.MonitorResponseStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "MRS_OK"] = 0;
            values[valuesById[1] = "MRS_INVALID_SIGNATURE"] = 1;
            values[valuesById[2] = "MRS_INVALID_REQUEST"] = 2;
            return values;
        })();
    
        return MonitorResponse;
    })();
    
    $root.MonitorActionResponse = (function() {
    
        /**
         * Properties of a MonitorActionResponse.
         * @exports IMonitorActionResponse
         * @interface IMonitorActionResponse
         * @property {string|null} [trx] MonitorActionResponse trx
         * @property {IMonitorConfigResponse|null} [config] MonitorActionResponse config
         * @property {IMonitorCheckEventResponse|null} [checkEvent] MonitorActionResponse checkEvent
         * @property {IMonitorCheckBarcodeResponse|null} [checkBarcode] MonitorActionResponse checkBarcode
         * @property {IJackpotsUnifiedResponse|null} [jackpots] MonitorActionResponse jackpots
         */
    
        /**
         * Constructs a new MonitorActionResponse.
         * @exports MonitorActionResponse
         * @classdesc Represents a MonitorActionResponse.
         * @implements IMonitorActionResponse
         * @constructor
         * @param {IMonitorActionResponse=} [properties] Properties to set
         */
        function MonitorActionResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * MonitorActionResponse trx.
         * @member {string} trx
         * @memberof MonitorActionResponse
         * @instance
         */
        MonitorActionResponse.prototype.trx = "";
    
        /**
         * MonitorActionResponse config.
         * @member {IMonitorConfigResponse|null|undefined} config
         * @memberof MonitorActionResponse
         * @instance
         */
        MonitorActionResponse.prototype.config = null;
    
        /**
         * MonitorActionResponse checkEvent.
         * @member {IMonitorCheckEventResponse|null|undefined} checkEvent
         * @memberof MonitorActionResponse
         * @instance
         */
        MonitorActionResponse.prototype.checkEvent = null;
    
        /**
         * MonitorActionResponse checkBarcode.
         * @member {IMonitorCheckBarcodeResponse|null|undefined} checkBarcode
         * @memberof MonitorActionResponse
         * @instance
         */
        MonitorActionResponse.prototype.checkBarcode = null;
    
        /**
         * MonitorActionResponse jackpots.
         * @member {IJackpotsUnifiedResponse|null|undefined} jackpots
         * @memberof MonitorActionResponse
         * @instance
         */
        MonitorActionResponse.prototype.jackpots = null;
    
        /**
         * Creates a new MonitorActionResponse instance using the specified properties.
         * @function create
         * @memberof MonitorActionResponse
         * @static
         * @param {IMonitorActionResponse=} [properties] Properties to set
         * @returns {MonitorActionResponse} MonitorActionResponse instance
         */
        MonitorActionResponse.create = function create(properties) {
            return new MonitorActionResponse(properties);
        };
    
        /**
         * Encodes the specified MonitorActionResponse message. Does not implicitly {@link MonitorActionResponse.verify|verify} messages.
         * @function encode
         * @memberof MonitorActionResponse
         * @static
         * @param {IMonitorActionResponse} message MonitorActionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorActionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.trx != null && Object.hasOwnProperty.call(message, "trx"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.trx);
            if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                $root.MonitorConfigResponse.encode(message.config, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.checkEvent != null && Object.hasOwnProperty.call(message, "checkEvent"))
                $root.MonitorCheckEventResponse.encode(message.checkEvent, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.checkBarcode != null && Object.hasOwnProperty.call(message, "checkBarcode"))
                $root.MonitorCheckBarcodeResponse.encode(message.checkBarcode, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.jackpots != null && Object.hasOwnProperty.call(message, "jackpots"))
                $root.JackpotsUnifiedResponse.encode(message.jackpots, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified MonitorActionResponse message, length delimited. Does not implicitly {@link MonitorActionResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MonitorActionResponse
         * @static
         * @param {IMonitorActionResponse} message MonitorActionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorActionResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a MonitorActionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof MonitorActionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MonitorActionResponse} MonitorActionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorActionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.MonitorActionResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.trx = reader.string();
                    break;
                case 10:
                    message.config = $root.MonitorConfigResponse.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.checkEvent = $root.MonitorCheckEventResponse.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.checkBarcode = $root.MonitorCheckBarcodeResponse.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.jackpots = $root.JackpotsUnifiedResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a MonitorActionResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MonitorActionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MonitorActionResponse} MonitorActionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorActionResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a MonitorActionResponse message.
         * @function verify
         * @memberof MonitorActionResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MonitorActionResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.trx != null && message.hasOwnProperty("trx"))
                if (!$util.isString(message.trx))
                    return "trx: string expected";
            if (message.config != null && message.hasOwnProperty("config")) {
                var error = $root.MonitorConfigResponse.verify(message.config);
                if (error)
                    return "config." + error;
            }
            if (message.checkEvent != null && message.hasOwnProperty("checkEvent")) {
                var error = $root.MonitorCheckEventResponse.verify(message.checkEvent);
                if (error)
                    return "checkEvent." + error;
            }
            if (message.checkBarcode != null && message.hasOwnProperty("checkBarcode")) {
                var error = $root.MonitorCheckBarcodeResponse.verify(message.checkBarcode);
                if (error)
                    return "checkBarcode." + error;
            }
            if (message.jackpots != null && message.hasOwnProperty("jackpots")) {
                var error = $root.JackpotsUnifiedResponse.verify(message.jackpots);
                if (error)
                    return "jackpots." + error;
            }
            return null;
        };
    
        /**
         * Creates a MonitorActionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MonitorActionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MonitorActionResponse} MonitorActionResponse
         */
        MonitorActionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.MonitorActionResponse)
                return object;
            var message = new $root.MonitorActionResponse();
            if (object.trx != null)
                message.trx = String(object.trx);
            if (object.config != null) {
                if (typeof object.config !== "object")
                    throw TypeError(".MonitorActionResponse.config: object expected");
                message.config = $root.MonitorConfigResponse.fromObject(object.config);
            }
            if (object.checkEvent != null) {
                if (typeof object.checkEvent !== "object")
                    throw TypeError(".MonitorActionResponse.checkEvent: object expected");
                message.checkEvent = $root.MonitorCheckEventResponse.fromObject(object.checkEvent);
            }
            if (object.checkBarcode != null) {
                if (typeof object.checkBarcode !== "object")
                    throw TypeError(".MonitorActionResponse.checkBarcode: object expected");
                message.checkBarcode = $root.MonitorCheckBarcodeResponse.fromObject(object.checkBarcode);
            }
            if (object.jackpots != null) {
                if (typeof object.jackpots !== "object")
                    throw TypeError(".MonitorActionResponse.jackpots: object expected");
                message.jackpots = $root.JackpotsUnifiedResponse.fromObject(object.jackpots);
            }
            return message;
        };
    
        /**
         * Creates a plain object from a MonitorActionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MonitorActionResponse
         * @static
         * @param {MonitorActionResponse} message MonitorActionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MonitorActionResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.trx = "";
                object.config = null;
                object.checkEvent = null;
                object.checkBarcode = null;
                object.jackpots = null;
            }
            if (message.trx != null && message.hasOwnProperty("trx"))
                object.trx = message.trx;
            if (message.config != null && message.hasOwnProperty("config"))
                object.config = $root.MonitorConfigResponse.toObject(message.config, options);
            if (message.checkEvent != null && message.hasOwnProperty("checkEvent"))
                object.checkEvent = $root.MonitorCheckEventResponse.toObject(message.checkEvent, options);
            if (message.checkBarcode != null && message.hasOwnProperty("checkBarcode"))
                object.checkBarcode = $root.MonitorCheckBarcodeResponse.toObject(message.checkBarcode, options);
            if (message.jackpots != null && message.hasOwnProperty("jackpots"))
                object.jackpots = $root.JackpotsUnifiedResponse.toObject(message.jackpots, options);
            return object;
        };
    
        /**
         * Converts this MonitorActionResponse to JSON.
         * @function toJSON
         * @memberof MonitorActionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MonitorActionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return MonitorActionResponse;
    })();
    
    $root.MonitorConfigRequest = (function() {
    
        /**
         * Properties of a MonitorConfigRequest.
         * @exports IMonitorConfigRequest
         * @interface IMonitorConfigRequest
         * @property {boolean|null} [fetch] MonitorConfigRequest fetch
         */
    
        /**
         * Constructs a new MonitorConfigRequest.
         * @exports MonitorConfigRequest
         * @classdesc Represents a MonitorConfigRequest.
         * @implements IMonitorConfigRequest
         * @constructor
         * @param {IMonitorConfigRequest=} [properties] Properties to set
         */
        function MonitorConfigRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * MonitorConfigRequest fetch.
         * @member {boolean} fetch
         * @memberof MonitorConfigRequest
         * @instance
         */
        MonitorConfigRequest.prototype.fetch = false;
    
        /**
         * Creates a new MonitorConfigRequest instance using the specified properties.
         * @function create
         * @memberof MonitorConfigRequest
         * @static
         * @param {IMonitorConfigRequest=} [properties] Properties to set
         * @returns {MonitorConfigRequest} MonitorConfigRequest instance
         */
        MonitorConfigRequest.create = function create(properties) {
            return new MonitorConfigRequest(properties);
        };
    
        /**
         * Encodes the specified MonitorConfigRequest message. Does not implicitly {@link MonitorConfigRequest.verify|verify} messages.
         * @function encode
         * @memberof MonitorConfigRequest
         * @static
         * @param {IMonitorConfigRequest} message MonitorConfigRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorConfigRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fetch != null && Object.hasOwnProperty.call(message, "fetch"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.fetch);
            return writer;
        };
    
        /**
         * Encodes the specified MonitorConfigRequest message, length delimited. Does not implicitly {@link MonitorConfigRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MonitorConfigRequest
         * @static
         * @param {IMonitorConfigRequest} message MonitorConfigRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a MonitorConfigRequest message from the specified reader or buffer.
         * @function decode
         * @memberof MonitorConfigRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MonitorConfigRequest} MonitorConfigRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorConfigRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.MonitorConfigRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fetch = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a MonitorConfigRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MonitorConfigRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MonitorConfigRequest} MonitorConfigRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorConfigRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a MonitorConfigRequest message.
         * @function verify
         * @memberof MonitorConfigRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MonitorConfigRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                if (typeof message.fetch !== "boolean")
                    return "fetch: boolean expected";
            return null;
        };
    
        /**
         * Creates a MonitorConfigRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MonitorConfigRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MonitorConfigRequest} MonitorConfigRequest
         */
        MonitorConfigRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.MonitorConfigRequest)
                return object;
            var message = new $root.MonitorConfigRequest();
            if (object.fetch != null)
                message.fetch = Boolean(object.fetch);
            return message;
        };
    
        /**
         * Creates a plain object from a MonitorConfigRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MonitorConfigRequest
         * @static
         * @param {MonitorConfigRequest} message MonitorConfigRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MonitorConfigRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.fetch = false;
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                object.fetch = message.fetch;
            return object;
        };
    
        /**
         * Converts this MonitorConfigRequest to JSON.
         * @function toJSON
         * @memberof MonitorConfigRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MonitorConfigRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return MonitorConfigRequest;
    })();
    
    $root.MonitorConfigResponse = (function() {
    
        /**
         * Properties of a MonitorConfigResponse.
         * @exports IMonitorConfigResponse
         * @interface IMonitorConfigResponse
         * @property {Array.<MonitorConfigResponse.MonitorFeature>|null} [features] MonitorConfigResponse features
         * @property {Array.<MonitorConfigResponse.IMonitorOption>|null} [options] MonitorConfigResponse options
         * @property {string|null} [accCurrency] MonitorConfigResponse accCurrency
         * @property {ICurrencyInfo|null} [accCurrencyInfo] MonitorConfigResponse accCurrencyInfo
         */
    
        /**
         * Constructs a new MonitorConfigResponse.
         * @exports MonitorConfigResponse
         * @classdesc Represents a MonitorConfigResponse.
         * @implements IMonitorConfigResponse
         * @constructor
         * @param {IMonitorConfigResponse=} [properties] Properties to set
         */
        function MonitorConfigResponse(properties) {
            this.features = [];
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * MonitorConfigResponse features.
         * @member {Array.<MonitorConfigResponse.MonitorFeature>} features
         * @memberof MonitorConfigResponse
         * @instance
         */
        MonitorConfigResponse.prototype.features = $util.emptyArray;
    
        /**
         * MonitorConfigResponse options.
         * @member {Array.<MonitorConfigResponse.IMonitorOption>} options
         * @memberof MonitorConfigResponse
         * @instance
         */
        MonitorConfigResponse.prototype.options = $util.emptyArray;
    
        /**
         * MonitorConfigResponse accCurrency.
         * @member {string} accCurrency
         * @memberof MonitorConfigResponse
         * @instance
         */
        MonitorConfigResponse.prototype.accCurrency = "";
    
        /**
         * MonitorConfigResponse accCurrencyInfo.
         * @member {ICurrencyInfo|null|undefined} accCurrencyInfo
         * @memberof MonitorConfigResponse
         * @instance
         */
        MonitorConfigResponse.prototype.accCurrencyInfo = null;
    
        /**
         * Creates a new MonitorConfigResponse instance using the specified properties.
         * @function create
         * @memberof MonitorConfigResponse
         * @static
         * @param {IMonitorConfigResponse=} [properties] Properties to set
         * @returns {MonitorConfigResponse} MonitorConfigResponse instance
         */
        MonitorConfigResponse.create = function create(properties) {
            return new MonitorConfigResponse(properties);
        };
    
        /**
         * Encodes the specified MonitorConfigResponse message. Does not implicitly {@link MonitorConfigResponse.verify|verify} messages.
         * @function encode
         * @memberof MonitorConfigResponse
         * @static
         * @param {IMonitorConfigResponse} message MonitorConfigResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorConfigResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.features != null && message.features.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.features.length; ++i)
                    writer.int32(message.features[i]);
                writer.ldelim();
            }
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.MonitorConfigResponse.MonitorOption.encode(message.options[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.accCurrency != null && Object.hasOwnProperty.call(message, "accCurrency"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.accCurrency);
            if (message.accCurrencyInfo != null && Object.hasOwnProperty.call(message, "accCurrencyInfo"))
                $root.CurrencyInfo.encode(message.accCurrencyInfo, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified MonitorConfigResponse message, length delimited. Does not implicitly {@link MonitorConfigResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MonitorConfigResponse
         * @static
         * @param {IMonitorConfigResponse} message MonitorConfigResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a MonitorConfigResponse message from the specified reader or buffer.
         * @function decode
         * @memberof MonitorConfigResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MonitorConfigResponse} MonitorConfigResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorConfigResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.MonitorConfigResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.features && message.features.length))
                        message.features = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.features.push(reader.int32());
                    } else
                        message.features.push(reader.int32());
                    break;
                case 2:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.MonitorConfigResponse.MonitorOption.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.accCurrency = reader.string();
                    break;
                case 4:
                    message.accCurrencyInfo = $root.CurrencyInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a MonitorConfigResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MonitorConfigResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MonitorConfigResponse} MonitorConfigResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorConfigResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a MonitorConfigResponse message.
         * @function verify
         * @memberof MonitorConfigResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MonitorConfigResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.features != null && message.hasOwnProperty("features")) {
                if (!Array.isArray(message.features))
                    return "features: array expected";
                for (var i = 0; i < message.features.length; ++i)
                    switch (message.features[i]) {
                    default:
                        return "features: enum value[] expected";
                    case 0:
                        break;
                    }
            }
            if (message.options != null && message.hasOwnProperty("options")) {
                if (!Array.isArray(message.options))
                    return "options: array expected";
                for (var i = 0; i < message.options.length; ++i) {
                    var error = $root.MonitorConfigResponse.MonitorOption.verify(message.options[i]);
                    if (error)
                        return "options." + error;
                }
            }
            if (message.accCurrency != null && message.hasOwnProperty("accCurrency"))
                if (!$util.isString(message.accCurrency))
                    return "accCurrency: string expected";
            if (message.accCurrencyInfo != null && message.hasOwnProperty("accCurrencyInfo")) {
                var error = $root.CurrencyInfo.verify(message.accCurrencyInfo);
                if (error)
                    return "accCurrencyInfo." + error;
            }
            return null;
        };
    
        /**
         * Creates a MonitorConfigResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MonitorConfigResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MonitorConfigResponse} MonitorConfigResponse
         */
        MonitorConfigResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.MonitorConfigResponse)
                return object;
            var message = new $root.MonitorConfigResponse();
            if (object.features) {
                if (!Array.isArray(object.features))
                    throw TypeError(".MonitorConfigResponse.features: array expected");
                message.features = [];
                for (var i = 0; i < object.features.length; ++i)
                    switch (object.features[i]) {
                    default:
                    case "MF_JACKPOTS":
                    case 0:
                        message.features[i] = 0;
                        break;
                    }
            }
            if (object.options) {
                if (!Array.isArray(object.options))
                    throw TypeError(".MonitorConfigResponse.options: array expected");
                message.options = [];
                for (var i = 0; i < object.options.length; ++i) {
                    if (typeof object.options[i] !== "object")
                        throw TypeError(".MonitorConfigResponse.options: object expected");
                    message.options[i] = $root.MonitorConfigResponse.MonitorOption.fromObject(object.options[i]);
                }
            }
            if (object.accCurrency != null)
                message.accCurrency = String(object.accCurrency);
            if (object.accCurrencyInfo != null) {
                if (typeof object.accCurrencyInfo !== "object")
                    throw TypeError(".MonitorConfigResponse.accCurrencyInfo: object expected");
                message.accCurrencyInfo = $root.CurrencyInfo.fromObject(object.accCurrencyInfo);
            }
            return message;
        };
    
        /**
         * Creates a plain object from a MonitorConfigResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MonitorConfigResponse
         * @static
         * @param {MonitorConfigResponse} message MonitorConfigResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MonitorConfigResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.features = [];
                object.options = [];
            }
            if (options.defaults) {
                object.accCurrency = "";
                object.accCurrencyInfo = null;
            }
            if (message.features && message.features.length) {
                object.features = [];
                for (var j = 0; j < message.features.length; ++j)
                    object.features[j] = options.enums === String ? $root.MonitorConfigResponse.MonitorFeature[message.features[j]] : message.features[j];
            }
            if (message.options && message.options.length) {
                object.options = [];
                for (var j = 0; j < message.options.length; ++j)
                    object.options[j] = $root.MonitorConfigResponse.MonitorOption.toObject(message.options[j], options);
            }
            if (message.accCurrency != null && message.hasOwnProperty("accCurrency"))
                object.accCurrency = message.accCurrency;
            if (message.accCurrencyInfo != null && message.hasOwnProperty("accCurrencyInfo"))
                object.accCurrencyInfo = $root.CurrencyInfo.toObject(message.accCurrencyInfo, options);
            return object;
        };
    
        /**
         * Converts this MonitorConfigResponse to JSON.
         * @function toJSON
         * @memberof MonitorConfigResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MonitorConfigResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        /**
         * MonitorFeature enum.
         * @name MonitorConfigResponse.MonitorFeature
         * @enum {number}
         * @property {number} MF_JACKPOTS=0 MF_JACKPOTS value
         */
        MonitorConfigResponse.MonitorFeature = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "MF_JACKPOTS"] = 0;
            return values;
        })();
    
        /**
         * MonitorOptionType enum.
         * @name MonitorConfigResponse.MonitorOptionType
         * @enum {number}
         * @property {number} CTA_UNKNOWN=0 CTA_UNKNOWN value
         * @property {number} CTA_ENABLED=2 CTA_ENABLED value
         * @property {number} CTA_BTN_LETSPLAY_TEXT=3 CTA_BTN_LETSPLAY_TEXT value
         * @property {number} CTA_BTN_BE_A_WINNER_TEXT=4 CTA_BTN_BE_A_WINNER_TEXT value
         * @property {number} CTA_BTN_SPIN_TEXT=5 CTA_BTN_SPIN_TEXT value
         * @property {number} CTA_BTN_SPIN_BG=6 CTA_BTN_SPIN_BG value
         * @property {number} CTA_BLOCK_BG=7 CTA_BLOCK_BG value
         * @property {number} CTA_BTN_LINK=8 CTA_BTN_LINK value
         * @property {number} INFO_ENABLED=9 INFO_ENABLED value
         * @property {number} INFO_BTN_TEXT=10 INFO_BTN_TEXT value
         * @property {number} INFO_BG=11 INFO_BG value
         * @property {number} INFO_BTN_LINK=12 INFO_BTN_LINK value
         * @property {number} SOCIAL_ENABLED=13 SOCIAL_ENABLED value
         * @property {number} SOCIAL_FB_LINK=14 SOCIAL_FB_LINK value
         * @property {number} SOCIAL_INSTAGRAM_LINK=15 SOCIAL_INSTAGRAM_LINK value
         * @property {number} SOCIAL_TELEGRAM_LINK=16 SOCIAL_TELEGRAM_LINK value
         */
        MonitorConfigResponse.MonitorOptionType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CTA_UNKNOWN"] = 0;
            values[valuesById[2] = "CTA_ENABLED"] = 2;
            values[valuesById[3] = "CTA_BTN_LETSPLAY_TEXT"] = 3;
            values[valuesById[4] = "CTA_BTN_BE_A_WINNER_TEXT"] = 4;
            values[valuesById[5] = "CTA_BTN_SPIN_TEXT"] = 5;
            values[valuesById[6] = "CTA_BTN_SPIN_BG"] = 6;
            values[valuesById[7] = "CTA_BLOCK_BG"] = 7;
            values[valuesById[8] = "CTA_BTN_LINK"] = 8;
            values[valuesById[9] = "INFO_ENABLED"] = 9;
            values[valuesById[10] = "INFO_BTN_TEXT"] = 10;
            values[valuesById[11] = "INFO_BG"] = 11;
            values[valuesById[12] = "INFO_BTN_LINK"] = 12;
            values[valuesById[13] = "SOCIAL_ENABLED"] = 13;
            values[valuesById[14] = "SOCIAL_FB_LINK"] = 14;
            values[valuesById[15] = "SOCIAL_INSTAGRAM_LINK"] = 15;
            values[valuesById[16] = "SOCIAL_TELEGRAM_LINK"] = 16;
            return values;
        })();
    
        MonitorConfigResponse.MonitorOption = (function() {
    
            /**
             * Properties of a MonitorOption.
             * @memberof MonitorConfigResponse
             * @interface IMonitorOption
             * @property {MonitorConfigResponse.MonitorOptionType|null} [type] MonitorOption type
             * @property {string|null} [optValueStr] MonitorOption optValueStr
             * @property {boolean|null} [optValueBool] MonitorOption optValueBool
             */
    
            /**
             * Constructs a new MonitorOption.
             * @memberof MonitorConfigResponse
             * @classdesc Represents a MonitorOption.
             * @implements IMonitorOption
             * @constructor
             * @param {MonitorConfigResponse.IMonitorOption=} [properties] Properties to set
             */
            function MonitorOption(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * MonitorOption type.
             * @member {MonitorConfigResponse.MonitorOptionType} type
             * @memberof MonitorConfigResponse.MonitorOption
             * @instance
             */
            MonitorOption.prototype.type = 0;
    
            /**
             * MonitorOption optValueStr.
             * @member {string} optValueStr
             * @memberof MonitorConfigResponse.MonitorOption
             * @instance
             */
            MonitorOption.prototype.optValueStr = "";
    
            /**
             * MonitorOption optValueBool.
             * @member {boolean} optValueBool
             * @memberof MonitorConfigResponse.MonitorOption
             * @instance
             */
            MonitorOption.prototype.optValueBool = false;
    
            /**
             * Creates a new MonitorOption instance using the specified properties.
             * @function create
             * @memberof MonitorConfigResponse.MonitorOption
             * @static
             * @param {MonitorConfigResponse.IMonitorOption=} [properties] Properties to set
             * @returns {MonitorConfigResponse.MonitorOption} MonitorOption instance
             */
            MonitorOption.create = function create(properties) {
                return new MonitorOption(properties);
            };
    
            /**
             * Encodes the specified MonitorOption message. Does not implicitly {@link MonitorConfigResponse.MonitorOption.verify|verify} messages.
             * @function encode
             * @memberof MonitorConfigResponse.MonitorOption
             * @static
             * @param {MonitorConfigResponse.IMonitorOption} message MonitorOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MonitorOption.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                if (message.optValueStr != null && Object.hasOwnProperty.call(message, "optValueStr"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.optValueStr);
                if (message.optValueBool != null && Object.hasOwnProperty.call(message, "optValueBool"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.optValueBool);
                return writer;
            };
    
            /**
             * Encodes the specified MonitorOption message, length delimited. Does not implicitly {@link MonitorConfigResponse.MonitorOption.verify|verify} messages.
             * @function encodeDelimited
             * @memberof MonitorConfigResponse.MonitorOption
             * @static
             * @param {MonitorConfigResponse.IMonitorOption} message MonitorOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MonitorOption.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a MonitorOption message from the specified reader or buffer.
             * @function decode
             * @memberof MonitorConfigResponse.MonitorOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {MonitorConfigResponse.MonitorOption} MonitorOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MonitorOption.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.MonitorConfigResponse.MonitorOption();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.type = reader.int32();
                        break;
                    case 2:
                        message.optValueStr = reader.string();
                        break;
                    case 3:
                        message.optValueBool = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a MonitorOption message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof MonitorConfigResponse.MonitorOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {MonitorConfigResponse.MonitorOption} MonitorOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MonitorOption.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a MonitorOption message.
             * @function verify
             * @memberof MonitorConfigResponse.MonitorOption
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MonitorOption.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                        break;
                    }
                if (message.optValueStr != null && message.hasOwnProperty("optValueStr"))
                    if (!$util.isString(message.optValueStr))
                        return "optValueStr: string expected";
                if (message.optValueBool != null && message.hasOwnProperty("optValueBool"))
                    if (typeof message.optValueBool !== "boolean")
                        return "optValueBool: boolean expected";
                return null;
            };
    
            /**
             * Creates a MonitorOption message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof MonitorConfigResponse.MonitorOption
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {MonitorConfigResponse.MonitorOption} MonitorOption
             */
            MonitorOption.fromObject = function fromObject(object) {
                if (object instanceof $root.MonitorConfigResponse.MonitorOption)
                    return object;
                var message = new $root.MonitorConfigResponse.MonitorOption();
                switch (object.type) {
                case "CTA_UNKNOWN":
                case 0:
                    message.type = 0;
                    break;
                case "CTA_ENABLED":
                case 2:
                    message.type = 2;
                    break;
                case "CTA_BTN_LETSPLAY_TEXT":
                case 3:
                    message.type = 3;
                    break;
                case "CTA_BTN_BE_A_WINNER_TEXT":
                case 4:
                    message.type = 4;
                    break;
                case "CTA_BTN_SPIN_TEXT":
                case 5:
                    message.type = 5;
                    break;
                case "CTA_BTN_SPIN_BG":
                case 6:
                    message.type = 6;
                    break;
                case "CTA_BLOCK_BG":
                case 7:
                    message.type = 7;
                    break;
                case "CTA_BTN_LINK":
                case 8:
                    message.type = 8;
                    break;
                case "INFO_ENABLED":
                case 9:
                    message.type = 9;
                    break;
                case "INFO_BTN_TEXT":
                case 10:
                    message.type = 10;
                    break;
                case "INFO_BG":
                case 11:
                    message.type = 11;
                    break;
                case "INFO_BTN_LINK":
                case 12:
                    message.type = 12;
                    break;
                case "SOCIAL_ENABLED":
                case 13:
                    message.type = 13;
                    break;
                case "SOCIAL_FB_LINK":
                case 14:
                    message.type = 14;
                    break;
                case "SOCIAL_INSTAGRAM_LINK":
                case 15:
                    message.type = 15;
                    break;
                case "SOCIAL_TELEGRAM_LINK":
                case 16:
                    message.type = 16;
                    break;
                }
                if (object.optValueStr != null)
                    message.optValueStr = String(object.optValueStr);
                if (object.optValueBool != null)
                    message.optValueBool = Boolean(object.optValueBool);
                return message;
            };
    
            /**
             * Creates a plain object from a MonitorOption message. Also converts values to other types if specified.
             * @function toObject
             * @memberof MonitorConfigResponse.MonitorOption
             * @static
             * @param {MonitorConfigResponse.MonitorOption} message MonitorOption
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MonitorOption.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.type = options.enums === String ? "CTA_UNKNOWN" : 0;
                    object.optValueStr = "";
                    object.optValueBool = false;
                }
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.MonitorConfigResponse.MonitorOptionType[message.type] : message.type;
                if (message.optValueStr != null && message.hasOwnProperty("optValueStr"))
                    object.optValueStr = message.optValueStr;
                if (message.optValueBool != null && message.hasOwnProperty("optValueBool"))
                    object.optValueBool = message.optValueBool;
                return object;
            };
    
            /**
             * Converts this MonitorOption to JSON.
             * @function toJSON
             * @memberof MonitorConfigResponse.MonitorOption
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MonitorOption.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return MonitorOption;
        })();
    
        return MonitorConfigResponse;
    })();
    
    $root.MonitorCheckEventRequest = (function() {
    
        /**
         * Properties of a MonitorCheckEventRequest.
         * @exports IMonitorCheckEventRequest
         * @interface IMonitorCheckEventRequest
         * @property {number|null} [source] MonitorCheckEventRequest source
         * @property {Long|null} [round] MonitorCheckEventRequest round
         */
    
        /**
         * Constructs a new MonitorCheckEventRequest.
         * @exports MonitorCheckEventRequest
         * @classdesc Represents a MonitorCheckEventRequest.
         * @implements IMonitorCheckEventRequest
         * @constructor
         * @param {IMonitorCheckEventRequest=} [properties] Properties to set
         */
        function MonitorCheckEventRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * MonitorCheckEventRequest source.
         * @member {number} source
         * @memberof MonitorCheckEventRequest
         * @instance
         */
        MonitorCheckEventRequest.prototype.source = 0;
    
        /**
         * MonitorCheckEventRequest round.
         * @member {Long} round
         * @memberof MonitorCheckEventRequest
         * @instance
         */
        MonitorCheckEventRequest.prototype.round = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
        /**
         * Creates a new MonitorCheckEventRequest instance using the specified properties.
         * @function create
         * @memberof MonitorCheckEventRequest
         * @static
         * @param {IMonitorCheckEventRequest=} [properties] Properties to set
         * @returns {MonitorCheckEventRequest} MonitorCheckEventRequest instance
         */
        MonitorCheckEventRequest.create = function create(properties) {
            return new MonitorCheckEventRequest(properties);
        };
    
        /**
         * Encodes the specified MonitorCheckEventRequest message. Does not implicitly {@link MonitorCheckEventRequest.verify|verify} messages.
         * @function encode
         * @memberof MonitorCheckEventRequest
         * @static
         * @param {IMonitorCheckEventRequest} message MonitorCheckEventRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorCheckEventRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.source);
            if (message.round != null && Object.hasOwnProperty.call(message, "round"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.round);
            return writer;
        };
    
        /**
         * Encodes the specified MonitorCheckEventRequest message, length delimited. Does not implicitly {@link MonitorCheckEventRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MonitorCheckEventRequest
         * @static
         * @param {IMonitorCheckEventRequest} message MonitorCheckEventRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorCheckEventRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a MonitorCheckEventRequest message from the specified reader or buffer.
         * @function decode
         * @memberof MonitorCheckEventRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MonitorCheckEventRequest} MonitorCheckEventRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorCheckEventRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.MonitorCheckEventRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.source = reader.uint32();
                    break;
                case 2:
                    message.round = reader.uint64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a MonitorCheckEventRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MonitorCheckEventRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MonitorCheckEventRequest} MonitorCheckEventRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorCheckEventRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a MonitorCheckEventRequest message.
         * @function verify
         * @memberof MonitorCheckEventRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MonitorCheckEventRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.source != null && message.hasOwnProperty("source"))
                if (!$util.isInteger(message.source))
                    return "source: integer expected";
            if (message.round != null && message.hasOwnProperty("round"))
                if (!$util.isInteger(message.round) && !(message.round && $util.isInteger(message.round.low) && $util.isInteger(message.round.high)))
                    return "round: integer|Long expected";
            return null;
        };
    
        /**
         * Creates a MonitorCheckEventRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MonitorCheckEventRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MonitorCheckEventRequest} MonitorCheckEventRequest
         */
        MonitorCheckEventRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.MonitorCheckEventRequest)
                return object;
            var message = new $root.MonitorCheckEventRequest();
            if (object.source != null)
                message.source = object.source >>> 0;
            if (object.round != null)
                if ($util.Long)
                    (message.round = $util.Long.fromValue(object.round)).unsigned = true;
                else if (typeof object.round === "string")
                    message.round = parseInt(object.round, 10);
                else if (typeof object.round === "number")
                    message.round = object.round;
                else if (typeof object.round === "object")
                    message.round = new $util.LongBits(object.round.low >>> 0, object.round.high >>> 0).toNumber(true);
            return message;
        };
    
        /**
         * Creates a plain object from a MonitorCheckEventRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MonitorCheckEventRequest
         * @static
         * @param {MonitorCheckEventRequest} message MonitorCheckEventRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MonitorCheckEventRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.source = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.round = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.round = options.longs === String ? "0" : 0;
            }
            if (message.source != null && message.hasOwnProperty("source"))
                object.source = message.source;
            if (message.round != null && message.hasOwnProperty("round"))
                if (typeof message.round === "number")
                    object.round = options.longs === String ? String(message.round) : message.round;
                else
                    object.round = options.longs === String ? $util.Long.prototype.toString.call(message.round) : options.longs === Number ? new $util.LongBits(message.round.low >>> 0, message.round.high >>> 0).toNumber(true) : message.round;
            return object;
        };
    
        /**
         * Converts this MonitorCheckEventRequest to JSON.
         * @function toJSON
         * @memberof MonitorCheckEventRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MonitorCheckEventRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return MonitorCheckEventRequest;
    })();
    
    $root.MonitorCheckEventResponse = (function() {
    
        /**
         * Properties of a MonitorCheckEventResponse.
         * @exports IMonitorCheckEventResponse
         * @interface IMonitorCheckEventResponse
         * @property {MonitorCheckEventResponse.CheckEventStatus|null} [status] MonitorCheckEventResponse status
         * @property {IBmHistoryEntry|null} [historyEntry] MonitorCheckEventResponse historyEntry
         */
    
        /**
         * Constructs a new MonitorCheckEventResponse.
         * @exports MonitorCheckEventResponse
         * @classdesc Represents a MonitorCheckEventResponse.
         * @implements IMonitorCheckEventResponse
         * @constructor
         * @param {IMonitorCheckEventResponse=} [properties] Properties to set
         */
        function MonitorCheckEventResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * MonitorCheckEventResponse status.
         * @member {MonitorCheckEventResponse.CheckEventStatus} status
         * @memberof MonitorCheckEventResponse
         * @instance
         */
        MonitorCheckEventResponse.prototype.status = 0;
    
        /**
         * MonitorCheckEventResponse historyEntry.
         * @member {IBmHistoryEntry|null|undefined} historyEntry
         * @memberof MonitorCheckEventResponse
         * @instance
         */
        MonitorCheckEventResponse.prototype.historyEntry = null;
    
        /**
         * Creates a new MonitorCheckEventResponse instance using the specified properties.
         * @function create
         * @memberof MonitorCheckEventResponse
         * @static
         * @param {IMonitorCheckEventResponse=} [properties] Properties to set
         * @returns {MonitorCheckEventResponse} MonitorCheckEventResponse instance
         */
        MonitorCheckEventResponse.create = function create(properties) {
            return new MonitorCheckEventResponse(properties);
        };
    
        /**
         * Encodes the specified MonitorCheckEventResponse message. Does not implicitly {@link MonitorCheckEventResponse.verify|verify} messages.
         * @function encode
         * @memberof MonitorCheckEventResponse
         * @static
         * @param {IMonitorCheckEventResponse} message MonitorCheckEventResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorCheckEventResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.historyEntry != null && Object.hasOwnProperty.call(message, "historyEntry"))
                $root.BmHistoryEntry.encode(message.historyEntry, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified MonitorCheckEventResponse message, length delimited. Does not implicitly {@link MonitorCheckEventResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MonitorCheckEventResponse
         * @static
         * @param {IMonitorCheckEventResponse} message MonitorCheckEventResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorCheckEventResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a MonitorCheckEventResponse message from the specified reader or buffer.
         * @function decode
         * @memberof MonitorCheckEventResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MonitorCheckEventResponse} MonitorCheckEventResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorCheckEventResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.MonitorCheckEventResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.historyEntry = $root.BmHistoryEntry.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a MonitorCheckEventResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MonitorCheckEventResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MonitorCheckEventResponse} MonitorCheckEventResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorCheckEventResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a MonitorCheckEventResponse message.
         * @function verify
         * @memberof MonitorCheckEventResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MonitorCheckEventResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.historyEntry != null && message.hasOwnProperty("historyEntry")) {
                var error = $root.BmHistoryEntry.verify(message.historyEntry);
                if (error)
                    return "historyEntry." + error;
            }
            return null;
        };
    
        /**
         * Creates a MonitorCheckEventResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MonitorCheckEventResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MonitorCheckEventResponse} MonitorCheckEventResponse
         */
        MonitorCheckEventResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.MonitorCheckEventResponse)
                return object;
            var message = new $root.MonitorCheckEventResponse();
            switch (object.status) {
            case "CES_OK":
            case 0:
                message.status = 0;
                break;
            case "CES_NOT_FOUND":
            case 1:
                message.status = 1;
                break;
            }
            if (object.historyEntry != null) {
                if (typeof object.historyEntry !== "object")
                    throw TypeError(".MonitorCheckEventResponse.historyEntry: object expected");
                message.historyEntry = $root.BmHistoryEntry.fromObject(object.historyEntry);
            }
            return message;
        };
    
        /**
         * Creates a plain object from a MonitorCheckEventResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MonitorCheckEventResponse
         * @static
         * @param {MonitorCheckEventResponse} message MonitorCheckEventResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MonitorCheckEventResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "CES_OK" : 0;
                object.historyEntry = null;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.MonitorCheckEventResponse.CheckEventStatus[message.status] : message.status;
            if (message.historyEntry != null && message.hasOwnProperty("historyEntry"))
                object.historyEntry = $root.BmHistoryEntry.toObject(message.historyEntry, options);
            return object;
        };
    
        /**
         * Converts this MonitorCheckEventResponse to JSON.
         * @function toJSON
         * @memberof MonitorCheckEventResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MonitorCheckEventResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        /**
         * CheckEventStatus enum.
         * @name MonitorCheckEventResponse.CheckEventStatus
         * @enum {number}
         * @property {number} CES_OK=0 CES_OK value
         * @property {number} CES_NOT_FOUND=1 CES_NOT_FOUND value
         */
        MonitorCheckEventResponse.CheckEventStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CES_OK"] = 0;
            values[valuesById[1] = "CES_NOT_FOUND"] = 1;
            return values;
        })();
    
        return MonitorCheckEventResponse;
    })();
    
    $root.MonitorCheckBarcodeRequest = (function() {
    
        /**
         * Properties of a MonitorCheckBarcodeRequest.
         * @exports IMonitorCheckBarcodeRequest
         * @interface IMonitorCheckBarcodeRequest
         * @property {string|null} [barcode] MonitorCheckBarcodeRequest barcode
         */
    
        /**
         * Constructs a new MonitorCheckBarcodeRequest.
         * @exports MonitorCheckBarcodeRequest
         * @classdesc Represents a MonitorCheckBarcodeRequest.
         * @implements IMonitorCheckBarcodeRequest
         * @constructor
         * @param {IMonitorCheckBarcodeRequest=} [properties] Properties to set
         */
        function MonitorCheckBarcodeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * MonitorCheckBarcodeRequest barcode.
         * @member {string} barcode
         * @memberof MonitorCheckBarcodeRequest
         * @instance
         */
        MonitorCheckBarcodeRequest.prototype.barcode = "";
    
        /**
         * Creates a new MonitorCheckBarcodeRequest instance using the specified properties.
         * @function create
         * @memberof MonitorCheckBarcodeRequest
         * @static
         * @param {IMonitorCheckBarcodeRequest=} [properties] Properties to set
         * @returns {MonitorCheckBarcodeRequest} MonitorCheckBarcodeRequest instance
         */
        MonitorCheckBarcodeRequest.create = function create(properties) {
            return new MonitorCheckBarcodeRequest(properties);
        };
    
        /**
         * Encodes the specified MonitorCheckBarcodeRequest message. Does not implicitly {@link MonitorCheckBarcodeRequest.verify|verify} messages.
         * @function encode
         * @memberof MonitorCheckBarcodeRequest
         * @static
         * @param {IMonitorCheckBarcodeRequest} message MonitorCheckBarcodeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorCheckBarcodeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.barcode != null && Object.hasOwnProperty.call(message, "barcode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.barcode);
            return writer;
        };
    
        /**
         * Encodes the specified MonitorCheckBarcodeRequest message, length delimited. Does not implicitly {@link MonitorCheckBarcodeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MonitorCheckBarcodeRequest
         * @static
         * @param {IMonitorCheckBarcodeRequest} message MonitorCheckBarcodeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorCheckBarcodeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a MonitorCheckBarcodeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof MonitorCheckBarcodeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MonitorCheckBarcodeRequest} MonitorCheckBarcodeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorCheckBarcodeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.MonitorCheckBarcodeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.barcode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a MonitorCheckBarcodeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MonitorCheckBarcodeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MonitorCheckBarcodeRequest} MonitorCheckBarcodeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorCheckBarcodeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a MonitorCheckBarcodeRequest message.
         * @function verify
         * @memberof MonitorCheckBarcodeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MonitorCheckBarcodeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.barcode != null && message.hasOwnProperty("barcode"))
                if (!$util.isString(message.barcode))
                    return "barcode: string expected";
            return null;
        };
    
        /**
         * Creates a MonitorCheckBarcodeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MonitorCheckBarcodeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MonitorCheckBarcodeRequest} MonitorCheckBarcodeRequest
         */
        MonitorCheckBarcodeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.MonitorCheckBarcodeRequest)
                return object;
            var message = new $root.MonitorCheckBarcodeRequest();
            if (object.barcode != null)
                message.barcode = String(object.barcode);
            return message;
        };
    
        /**
         * Creates a plain object from a MonitorCheckBarcodeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MonitorCheckBarcodeRequest
         * @static
         * @param {MonitorCheckBarcodeRequest} message MonitorCheckBarcodeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MonitorCheckBarcodeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.barcode = "";
            if (message.barcode != null && message.hasOwnProperty("barcode"))
                object.barcode = message.barcode;
            return object;
        };
    
        /**
         * Converts this MonitorCheckBarcodeRequest to JSON.
         * @function toJSON
         * @memberof MonitorCheckBarcodeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MonitorCheckBarcodeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return MonitorCheckBarcodeRequest;
    })();
    
    $root.MonitorCheckBarcodeResponse = (function() {
    
        /**
         * Properties of a MonitorCheckBarcodeResponse.
         * @exports IMonitorCheckBarcodeResponse
         * @interface IMonitorCheckBarcodeResponse
         * @property {MonitorCheckBarcodeResponse.CheckBarcodeStatus|null} [checkStatus] MonitorCheckBarcodeResponse checkStatus
         * @property {IUserSlipData|null} [slipData] MonitorCheckBarcodeResponse slipData
         */
    
        /**
         * Constructs a new MonitorCheckBarcodeResponse.
         * @exports MonitorCheckBarcodeResponse
         * @classdesc Represents a MonitorCheckBarcodeResponse.
         * @implements IMonitorCheckBarcodeResponse
         * @constructor
         * @param {IMonitorCheckBarcodeResponse=} [properties] Properties to set
         */
        function MonitorCheckBarcodeResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * MonitorCheckBarcodeResponse checkStatus.
         * @member {MonitorCheckBarcodeResponse.CheckBarcodeStatus} checkStatus
         * @memberof MonitorCheckBarcodeResponse
         * @instance
         */
        MonitorCheckBarcodeResponse.prototype.checkStatus = 0;
    
        /**
         * MonitorCheckBarcodeResponse slipData.
         * @member {IUserSlipData|null|undefined} slipData
         * @memberof MonitorCheckBarcodeResponse
         * @instance
         */
        MonitorCheckBarcodeResponse.prototype.slipData = null;
    
        /**
         * Creates a new MonitorCheckBarcodeResponse instance using the specified properties.
         * @function create
         * @memberof MonitorCheckBarcodeResponse
         * @static
         * @param {IMonitorCheckBarcodeResponse=} [properties] Properties to set
         * @returns {MonitorCheckBarcodeResponse} MonitorCheckBarcodeResponse instance
         */
        MonitorCheckBarcodeResponse.create = function create(properties) {
            return new MonitorCheckBarcodeResponse(properties);
        };
    
        /**
         * Encodes the specified MonitorCheckBarcodeResponse message. Does not implicitly {@link MonitorCheckBarcodeResponse.verify|verify} messages.
         * @function encode
         * @memberof MonitorCheckBarcodeResponse
         * @static
         * @param {IMonitorCheckBarcodeResponse} message MonitorCheckBarcodeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorCheckBarcodeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.checkStatus != null && Object.hasOwnProperty.call(message, "checkStatus"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.checkStatus);
            if (message.slipData != null && Object.hasOwnProperty.call(message, "slipData"))
                $root.UserSlipData.encode(message.slipData, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified MonitorCheckBarcodeResponse message, length delimited. Does not implicitly {@link MonitorCheckBarcodeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MonitorCheckBarcodeResponse
         * @static
         * @param {IMonitorCheckBarcodeResponse} message MonitorCheckBarcodeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonitorCheckBarcodeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a MonitorCheckBarcodeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof MonitorCheckBarcodeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MonitorCheckBarcodeResponse} MonitorCheckBarcodeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorCheckBarcodeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.MonitorCheckBarcodeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.checkStatus = reader.int32();
                    break;
                case 2:
                    message.slipData = $root.UserSlipData.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a MonitorCheckBarcodeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MonitorCheckBarcodeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MonitorCheckBarcodeResponse} MonitorCheckBarcodeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonitorCheckBarcodeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a MonitorCheckBarcodeResponse message.
         * @function verify
         * @memberof MonitorCheckBarcodeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MonitorCheckBarcodeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.checkStatus != null && message.hasOwnProperty("checkStatus"))
                switch (message.checkStatus) {
                default:
                    return "checkStatus: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.slipData != null && message.hasOwnProperty("slipData")) {
                var error = $root.UserSlipData.verify(message.slipData);
                if (error)
                    return "slipData." + error;
            }
            return null;
        };
    
        /**
         * Creates a MonitorCheckBarcodeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MonitorCheckBarcodeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MonitorCheckBarcodeResponse} MonitorCheckBarcodeResponse
         */
        MonitorCheckBarcodeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.MonitorCheckBarcodeResponse)
                return object;
            var message = new $root.MonitorCheckBarcodeResponse();
            switch (object.checkStatus) {
            case "CBS_OK":
            case 0:
                message.checkStatus = 0;
                break;
            case "CBS_NOT_FOUND":
            case 1:
                message.checkStatus = 1;
                break;
            }
            if (object.slipData != null) {
                if (typeof object.slipData !== "object")
                    throw TypeError(".MonitorCheckBarcodeResponse.slipData: object expected");
                message.slipData = $root.UserSlipData.fromObject(object.slipData);
            }
            return message;
        };
    
        /**
         * Creates a plain object from a MonitorCheckBarcodeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MonitorCheckBarcodeResponse
         * @static
         * @param {MonitorCheckBarcodeResponse} message MonitorCheckBarcodeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MonitorCheckBarcodeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.checkStatus = options.enums === String ? "CBS_OK" : 0;
                object.slipData = null;
            }
            if (message.checkStatus != null && message.hasOwnProperty("checkStatus"))
                object.checkStatus = options.enums === String ? $root.MonitorCheckBarcodeResponse.CheckBarcodeStatus[message.checkStatus] : message.checkStatus;
            if (message.slipData != null && message.hasOwnProperty("slipData"))
                object.slipData = $root.UserSlipData.toObject(message.slipData, options);
            return object;
        };
    
        /**
         * Converts this MonitorCheckBarcodeResponse to JSON.
         * @function toJSON
         * @memberof MonitorCheckBarcodeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MonitorCheckBarcodeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        /**
         * CheckBarcodeStatus enum.
         * @name MonitorCheckBarcodeResponse.CheckBarcodeStatus
         * @enum {number}
         * @property {number} CBS_OK=0 CBS_OK value
         * @property {number} CBS_NOT_FOUND=1 CBS_NOT_FOUND value
         */
        MonitorCheckBarcodeResponse.CheckBarcodeStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CBS_OK"] = 0;
            values[valuesById[1] = "CBS_NOT_FOUND"] = 1;
            return values;
        })();
    
        return MonitorCheckBarcodeResponse;
    })();
    
    $root.JPStateRequest = (function() {
    
        /**
         * Properties of a JPStateRequest.
         * @exports IJPStateRequest
         * @interface IJPStateRequest
         * @property {boolean|null} [config] JPStateRequest config
         * @property {boolean|null} [fetch] JPStateRequest fetch
         */
    
        /**
         * Constructs a new JPStateRequest.
         * @exports JPStateRequest
         * @classdesc Represents a JPStateRequest.
         * @implements IJPStateRequest
         * @constructor
         * @param {IJPStateRequest=} [properties] Properties to set
         */
        function JPStateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * JPStateRequest config.
         * @member {boolean} config
         * @memberof JPStateRequest
         * @instance
         */
        JPStateRequest.prototype.config = false;
    
        /**
         * JPStateRequest fetch.
         * @member {boolean} fetch
         * @memberof JPStateRequest
         * @instance
         */
        JPStateRequest.prototype.fetch = false;
    
        /**
         * Creates a new JPStateRequest instance using the specified properties.
         * @function create
         * @memberof JPStateRequest
         * @static
         * @param {IJPStateRequest=} [properties] Properties to set
         * @returns {JPStateRequest} JPStateRequest instance
         */
        JPStateRequest.create = function create(properties) {
            return new JPStateRequest(properties);
        };
    
        /**
         * Encodes the specified JPStateRequest message. Does not implicitly {@link JPStateRequest.verify|verify} messages.
         * @function encode
         * @memberof JPStateRequest
         * @static
         * @param {IJPStateRequest} message JPStateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JPStateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.config);
            if (message.fetch != null && Object.hasOwnProperty.call(message, "fetch"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.fetch);
            return writer;
        };
    
        /**
         * Encodes the specified JPStateRequest message, length delimited. Does not implicitly {@link JPStateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof JPStateRequest
         * @static
         * @param {IJPStateRequest} message JPStateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JPStateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a JPStateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof JPStateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {JPStateRequest} JPStateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JPStateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.JPStateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.config = reader.bool();
                    break;
                case 2:
                    message.fetch = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a JPStateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof JPStateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {JPStateRequest} JPStateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JPStateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a JPStateRequest message.
         * @function verify
         * @memberof JPStateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JPStateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.config != null && message.hasOwnProperty("config"))
                if (typeof message.config !== "boolean")
                    return "config: boolean expected";
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                if (typeof message.fetch !== "boolean")
                    return "fetch: boolean expected";
            return null;
        };
    
        /**
         * Creates a JPStateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof JPStateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {JPStateRequest} JPStateRequest
         */
        JPStateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.JPStateRequest)
                return object;
            var message = new $root.JPStateRequest();
            if (object.config != null)
                message.config = Boolean(object.config);
            if (object.fetch != null)
                message.fetch = Boolean(object.fetch);
            return message;
        };
    
        /**
         * Creates a plain object from a JPStateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof JPStateRequest
         * @static
         * @param {JPStateRequest} message JPStateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JPStateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.config = false;
                object.fetch = false;
            }
            if (message.config != null && message.hasOwnProperty("config"))
                object.config = message.config;
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                object.fetch = message.fetch;
            return object;
        };
    
        /**
         * Converts this JPStateRequest to JSON.
         * @function toJSON
         * @memberof JPStateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JPStateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return JPStateRequest;
    })();
    
    $root.CurrencyInfo = (function() {
    
        /**
         * Properties of a CurrencyInfo.
         * @exports ICurrencyInfo
         * @interface ICurrencyInfo
         * @property {string|null} [code] CurrencyInfo code
         * @property {number|null} [denom] CurrencyInfo denom
         * @property {string|null} [symbol] CurrencyInfo symbol
         */
    
        /**
         * Constructs a new CurrencyInfo.
         * @exports CurrencyInfo
         * @classdesc Represents a CurrencyInfo.
         * @implements ICurrencyInfo
         * @constructor
         * @param {ICurrencyInfo=} [properties] Properties to set
         */
        function CurrencyInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * CurrencyInfo code.
         * @member {string} code
         * @memberof CurrencyInfo
         * @instance
         */
        CurrencyInfo.prototype.code = "";
    
        /**
         * CurrencyInfo denom.
         * @member {number} denom
         * @memberof CurrencyInfo
         * @instance
         */
        CurrencyInfo.prototype.denom = 0;
    
        /**
         * CurrencyInfo symbol.
         * @member {string} symbol
         * @memberof CurrencyInfo
         * @instance
         */
        CurrencyInfo.prototype.symbol = "";
    
        /**
         * Creates a new CurrencyInfo instance using the specified properties.
         * @function create
         * @memberof CurrencyInfo
         * @static
         * @param {ICurrencyInfo=} [properties] Properties to set
         * @returns {CurrencyInfo} CurrencyInfo instance
         */
        CurrencyInfo.create = function create(properties) {
            return new CurrencyInfo(properties);
        };
    
        /**
         * Encodes the specified CurrencyInfo message. Does not implicitly {@link CurrencyInfo.verify|verify} messages.
         * @function encode
         * @memberof CurrencyInfo
         * @static
         * @param {ICurrencyInfo} message CurrencyInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CurrencyInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
            if (message.denom != null && Object.hasOwnProperty.call(message, "denom"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.denom);
            if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.symbol);
            return writer;
        };
    
        /**
         * Encodes the specified CurrencyInfo message, length delimited. Does not implicitly {@link CurrencyInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CurrencyInfo
         * @static
         * @param {ICurrencyInfo} message CurrencyInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CurrencyInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a CurrencyInfo message from the specified reader or buffer.
         * @function decode
         * @memberof CurrencyInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CurrencyInfo} CurrencyInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CurrencyInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CurrencyInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.code = reader.string();
                    break;
                case 2:
                    message.denom = reader.uint32();
                    break;
                case 4:
                    message.symbol = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a CurrencyInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CurrencyInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CurrencyInfo} CurrencyInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CurrencyInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a CurrencyInfo message.
         * @function verify
         * @memberof CurrencyInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CurrencyInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            if (message.denom != null && message.hasOwnProperty("denom"))
                if (!$util.isInteger(message.denom))
                    return "denom: integer expected";
            if (message.symbol != null && message.hasOwnProperty("symbol"))
                if (!$util.isString(message.symbol))
                    return "symbol: string expected";
            return null;
        };
    
        /**
         * Creates a CurrencyInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CurrencyInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CurrencyInfo} CurrencyInfo
         */
        CurrencyInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.CurrencyInfo)
                return object;
            var message = new $root.CurrencyInfo();
            if (object.code != null)
                message.code = String(object.code);
            if (object.denom != null)
                message.denom = object.denom >>> 0;
            if (object.symbol != null)
                message.symbol = String(object.symbol);
            return message;
        };
    
        /**
         * Creates a plain object from a CurrencyInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CurrencyInfo
         * @static
         * @param {CurrencyInfo} message CurrencyInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CurrencyInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.code = "";
                object.denom = 0;
                object.symbol = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.denom != null && message.hasOwnProperty("denom"))
                object.denom = message.denom;
            if (message.symbol != null && message.hasOwnProperty("symbol"))
                object.symbol = message.symbol;
            return object;
        };
    
        /**
         * Converts this CurrencyInfo to JSON.
         * @function toJSON
         * @memberof CurrencyInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CurrencyInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return CurrencyInfo;
    })();

    return $root;
});
