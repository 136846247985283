import "./win.css";
import { observer } from "mobx-react";
import { Jackpot } from "../../stores/jackpot";
import { forwardRef, LegacyRef } from "react";

interface IWinProps {
  isGlobal: boolean;
  barcode?: string | null;
  stamp?: number | null;
  amount: string;
  jackpot?: Jackpot;
}

const _Win = forwardRef((props: IWinProps, ref: LegacyRef<HTMLDivElement>) => {
  const { barcode, amount, stamp, jackpot } = props;
  if (!jackpot) return null;
  const date = new Date(stamp ?? 0);
  const style = jackpot.color ? {background: jackpot.color} : {};

  return (
    <div ref={ref} className={`win win--${jackpot.colorClass}`}>
      <div className="win__top">
        {!!jackpot.name && <div className="win__name" style={style}>{jackpot.name}</div>}
        <div className="win__amount" dangerouslySetInnerHTML={{__html: amount}}/>
      </div>
      <div className="win__bottom">
        {!!barcode && <div className="win__barcode">#{barcode}</div>}
        <div className="win__date">{date.toLocaleString()}</div>
      </div>
    </div>
  );
});

export const Win = observer(_Win);
