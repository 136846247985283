import { useEffect } from "react";
import "./fonts/fonts.css";
import "./App.css";
import { useStore } from "./stores/store";
import { observer } from "mobx-react";
import { Placeholder } from "./components/placeholder/placeholder";
import { Main } from "./components/main";

const _App = () => {
  const store = useStore();

  useEffect(() => {
    store.init();
  }, [store]);

  return (
    <div className="App">
      {!store.config.isReceivedFromServer ? (
        <Placeholder />
      ) : (
        <Main />
      )}
    </div>
  );
};

export const App = observer(_App);
