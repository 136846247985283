import Long from "long";
import { action, computed, makeObservable, observable } from "mobx";
import { IJackpotUnifiedInfo, IJackpotUnifiedOpt, JackpotUnifiedOpt } from "../proto/proto";
import { formatAmount } from "../utils/formatAmount";
import { getJackpotColor } from "../utils/getJackpotColor";
import { Store } from "./store";


export interface IJackpotConfig {
  showJpBar?: boolean;
  opts?: IJackpotUnifiedOpt[] | null | undefined;
}

export class Jackpot implements IJackpotUnifiedInfo {
  id: string = '';
  balance: Long = new Long(0);
  fallValue: Long = new Long(0);
  formattedAmount: string = '';
  config: IJackpotConfig = {};
  nameVal?: string;

  constructor(
    private store: Store,
    jackpot: IJackpotUnifiedInfo
  ) {
    
    makeObservable(this, {
      balance: observable,
      fallValue: observable,
      formattedAmount: observable,
      updateState: action,
      key: computed,
      formattedMinBet: computed,
      color: computed,
      colorClass: computed,
      isAnimationActive: computed,
      amountNumber: computed,
    });

    Object.assign(this, jackpot);
    if (jackpot.opts) {
      this.config.opts = jackpot.opts;
    }
  }

  get key(): string {
    return this.id?.toString() || '';
  }

  get isV2Type(): boolean {
    return this.id?.substring(0,2) === 'v2';
  }

  get formattedMinBet(): string {
    return !!this.minBet && !this.minBet.isZero() && this.isV2Type
      ? `Min Bet ${formatAmount(
        this.minBet,
          false,
          this.store.config.accCurrency,
          this.store.config.currencySymbol,
          this.store.config.currencyDenomination
        )}`
      : "";
  }

  get minBet(): Long | null {
    const opt = this.config?.opts?.find(
      (_) => _.optType === JackpotUnifiedOpt.Type.JO_MIN_BET
    );
    if (opt?.valUInt) {
      return opt?.valUInt;
    }
    return null;
  }

  get isGlobal(): boolean {
    const opt = this.config?.opts?.find(
      (_) => _.optType === JackpotUnifiedOpt.Type.JO_VISUAL_TYPE
    );
    if (opt?.valUInt) {
      return opt.valUInt.toNumber() === JackpotUnifiedOpt.VisualType.JVT_GLOBAL;
    }
    return false;
  }

  get colorClass(): string {
    const styleOpt = this.config?.opts?.find(
      (_) => _.optType === JackpotUnifiedOpt.Type.JO_MONITOR_STYLE
    );
    if (styleOpt?.valStr) {
      return ["red", "green", "yellow", "blue"].includes(styleOpt.valStr) ? styleOpt.valStr : '';
    }
    const jpIndex = this.isGlobal || this.sortWeight >= 50
        ? this.store.jackpots.global.indexOf(this)
        : this.store.jackpots.local.indexOf(this);
    return getJackpotColor(jpIndex || 0);
  }

  get color(): string | null {
    const styleOpt = this.config?.opts?.find(
      (_) => _.optType === JackpotUnifiedOpt.Type.JO_MONITOR_STYLE
    );
    if (styleOpt?.valStr) {
      return ["red", "green", "yellow", "blue"].includes(styleOpt.valStr) ? null : styleOpt.valStr;
    }
    return null;
  }

  get sortWeight(): number {
    const styleOpt = this.config?.opts?.find(
      (_) => _.optType === JackpotUnifiedOpt.Type.JO_SORT_WEIGHT
    );
    if (styleOpt?.valUInt) {
      return styleOpt.valUInt.toNumber();
    }
    return 0;
  }

  get isAnimationActive(): boolean | undefined {
    return (
      !this.fallValue?.isZero() &&
      this.balance?.greaterThanOrEqual(this.fallValue!)
    );
  }

  get amountNumber(): number {
    return this.balance?.toNumber() || 0;
  }

  get name(): string {
    if (this.nameVal) return this.nameVal;
    const nameOpt = this.config?.opts?.find(
      (_) => _.optType === JackpotUnifiedOpt.Type.JO_NAME
    );
    if (nameOpt?.valStr) {
      return nameOpt.valStr;
    }
    return "";
  }

  updateState(jackpot: IJackpotUnifiedInfo) {
    Object.assign(this, jackpot);
    if (jackpot.opts) {
      this.config.opts = jackpot.opts;
    }
  }
}
