import { useStore } from "../stores/store";
import { observer } from "mobx-react";
import { formatLink } from "../utils/formatLink";

const _Footer = () => {
  const {config} = useStore();

  return (
    <footer>
          <div className="footer__links">
            {config.isSocialEnabled && (
              <div className="footer__social">
                {config.socialFBLink !== null && (
                  <a
                    href={formatLink(config.socialFBLink)}
                    className="footer__socialItem footer__socialItem--fb"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Facebook
                  </a>
                )}
                {config.socialInstagramLink !== null && (
                  <a
                    href={formatLink(config.socialInstagramLink)}
                    className="footer__socialItem footer__socialItem--insta"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                )}
                {config.socialTelegramLink !== null && (
                  <a
                    href={formatLink(config.socialTelegramLink)}
                    className="footer__socialItem footer__socialItem--tg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Telegram
                  </a>
                )}
              </div>
            )}
            {config.isInfoEnabled && (
              <a
                href={config.infoLink ? formatLink(config.infoLink) : "#"}
                className="footer__infoPage"
                style={
                  { background: config.infoBG ? config.infoBG : 'linear-gradient(180deg, #EAC400 0%, #FFEF64 50.46%, #EAC400 100%)' }
                }
                target="_blank"
                rel="noreferrer"
              >
                <span className="footer__infoPageText">
                  {config.infoText ?? "Info page"}
                </span>
              </a>
            )}
          </div>
          <div className="footer__copyright">© Copyright | 2022</div>
        </footer>
  );
};

export const Footer = observer(_Footer);
