import settings from "../settings.json";
import { ICurrenciesSettings, ISettings } from "../types/settings";

export function formatAmount(
  amount: number | Long | undefined | null,
  isDenominatedAlready = false,
  currency: string,
  symbol: string | null,
  denomination: number,
  withoutCurrency?: boolean,
): string {
  let amountStr = "0";
  if (amount) {
    if (typeof amount === "number") {
      amountStr = String(Math.floor(amount));
    } else {
      amountStr = amount.toString();
    }
  }
  const isNegative = amountStr.includes("-");
  amountStr = amountStr.replace("-", "");
  if (amountStr === "0") {
    return "-";
  }
  if (isDenominatedAlready) {
    return `${isNegative ? "-" : ""}$${amountStr}`;
  }
  if (amountStr.length <= denomination) {
    amountStr =
      new Array(denomination + 1 - amountStr.length).fill("0").join("") +
      amountStr;
  }
  let formattedAmount = `${amountStr.slice(
    0,
    -denomination
  )}.${amountStr.slice(-denomination)}`;
  //eslint-disable-next-line
  let [_, num, suffix] = formattedAmount.match(/^(.*?)((?:[,.]\d+)?|)$/) || [];
  formattedAmount = `${num.replace(/\B(?=(?:\d{3})*$)/g, ' ')}${suffix}`;
  
  if (withoutCurrency) {  
    return formattedAmount;
  }
  
  const currenciesSettings: ICurrenciesSettings | undefined = (settings as ISettings).currenciesSettings;
  let beforeAmount = false;
  let withSymbol = false;
  if (currenciesSettings && currenciesSettings[currency]) {
    if (currenciesSettings[currency].symbol) {
      symbol = currenciesSettings[currency].symbol || "";
    }
    if (currenciesSettings[currency].beforeAmount) {
      beforeAmount = currenciesSettings[currency].beforeAmount || false;
    }
    if (currenciesSettings[currency].symbolEnabled) {
      withSymbol = true;
    }
  }
  
  if (symbol && withSymbol) {
    if (beforeAmount) {
      return `<span class="geometria-symbol">${symbol}</span>${isNegative ? "-" : ""}${formattedAmount}`;
    }
    return `${isNegative ? "-" : ""}${formattedAmount}<span class="geometria-symbol">${symbol}</span>`;
  }
  if (beforeAmount) {
    return `<span class="geometria-symbol">${currency.toUpperCase()}</span>${isNegative ? "-" : ""}${formattedAmount}`;
  }
  return `${isNegative ? "-" : ""}${formattedAmount}<span class="geometria-symbol">${currency.toUpperCase()}</span>`;
}

export function getCurrency(
  currency: string,
  symbol: string | null,
) {
  const currenciesSettings: ICurrenciesSettings | undefined = (settings as ISettings).currenciesSettings;
  let withSymbol = false;
  if (currenciesSettings && currenciesSettings[currency]) {
    if (currenciesSettings[currency].symbol) {
      symbol = currenciesSettings[currency].symbol || "";
    }
    if (currenciesSettings[currency].symbolEnabled) {
      withSymbol = true;
    }
  }
  return withSymbol ? symbol || currency : currency;
}

export function isCurrencyBeforeAmount(
  currency: string,
) {
  const currenciesSettings: ICurrenciesSettings | undefined = (settings as ISettings).currenciesSettings;
  if (currenciesSettings && currenciesSettings[currency]) {
    if (currenciesSettings[currency].beforeAmount) {
      return true;
    }
  }
  return false;
}