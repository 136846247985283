import "./jackpot.css";
import { observer } from "mobx-react";
import React from 'react';
import { Countup } from "../countup/countup";
import { useStore } from "../../stores/store";
import { getCurrency, isCurrencyBeforeAmount } from "../../utils/formatAmount";
import { Jackpot as JackpotType } from "../../stores/jackpot";

interface IJackpotProps {
  index?: number;
  size?: "large" | "small";
  jackpot: JackpotType ;
}

const _Jackpot = ({ size = "large", jackpot, index }: IJackpotProps) => {
  const store = useStore();
  let firstWord: string | undefined = undefined;
  const [amountFontSize, setAmountFontSize] = React.useState(30);
  React.useEffect(() => {
    calcAmountFontSize();
    window.addEventListener('resize', (event) => {
      calcAmountFontSize();
    }, true);
    //eslint-disable-next-line
  },[])
  let name = jackpot.name;
  if (size === "large") {
    firstWord = name.split(" ")[0];
    name = name.replace(firstWord, "");
  }
  const style = jackpot.color ? {background: jackpot.color} : {};
  const currency = <span className="geometria-symbol">{getCurrency(store.config.accCurrency, store.config.currencySymbol)}</span>;
  const beforeAmount = isCurrencyBeforeAmount(store.config.accCurrency);
  const calcAmountFontSize = () => {
    if (size === "small") {
      setAmountFontSize(17);
      if (window.innerWidth > 520 || !String(jackpot.amountNumber)?.length) {
        setAmountFontSize(17);
      } else {
        const fontSize = Math.round((window.innerWidth / 2) / (String(jackpot.amountNumber).length + 6));
        setAmountFontSize(fontSize > 17 ? 17 : fontSize);
      }
    } else {
      if (window.innerWidth > 450 || !String(jackpot.amountNumber)?.length) {
        setAmountFontSize(30);
      } else {
        const fontSize = Math.round(window.innerWidth / (String(jackpot.amountNumber).length + 4));
        setAmountFontSize(fontSize > 30 ? 30 : fontSize);
      }
    }
  }
  return (
    <div
      className={`jackpot jackpot--${size} jackpot--${jackpot.colorClass} ${
        jackpot.isAnimationActive ? "jackpot--animated" : ""
      } ${index === 0 ? "jackpot--first" : ""}`}
      style={style}
    >
      <div className="jackpot__animationWrapper">
        <div className="jackpot__animation"></div>
      </div>
      <div className="jackpot__content">
        <div className="jackpot__top">
          <div className="jackpot__topTitle">
            {firstWord !== undefined && (
              <span className="jackpot__topTitle--type">{firstWord}</span>
            )}
            {!!jackpot.name && (
              <span className="jackpot__topTitle--name">{name}</span>
            )}
          </div>
          <div className="jackpot__topDescription" dangerouslySetInnerHTML={{__html: jackpot.formattedMinBet}}/>
        </div>
        <div className="jackpot__amountWrapper">
          <div className="jackpot__amount" style={{fontSize: amountFontSize}}>
            {beforeAmount ? currency : null}
            <Countup
              currency={store.config.accCurrency}
              end={jackpot.amountNumber}
              currencySymbol={store.config.currencySymbol}
              currencyDenomination={store.config.currencyDenomination}
            />
            {!beforeAmount ? currency : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Jackpot = observer(_Jackpot);
