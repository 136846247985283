import "./placeholder.css";

export const Placeholder = () => {
  return (
    <div className="placeholder wrap">
      <div className="placeholder__title"></div>
      <div className="placeholder__jackpot placeholder__jackpot--first">
        <div className="placeholder__jackpotTitle"></div>
        <div className="placeholder__jackpotAmount"></div>
      </div>
      <div className="placeholder__jackpot">
        <div className="placeholder__jackpotTitle"></div>
        <div className="placeholder__jackpotAmount"></div>
      </div>
      {/* <div className="placeholder__spin">
        <div className="placeholder__spinSkip"></div>
        <div className="placeholder__spinTitle"></div>
        <div className="placeholder__spinSubtitle"></div>
        <div className="placeholder__spinButton"></div>
      </div> */}
    </div>
  );
};
