import { useStore } from "../stores/store";
import { observer } from "mobx-react";
import FlipMove from 'react-flip-move';
import { Win } from "../components/win/win";
import { formatAmount } from "../utils/formatAmount";

const _Winners = () => {
  const {jackpots, config} = useStore();
  const {globalWins, localWins} = jackpots;
  console.debug(localWins.length)
  return (
    <>
       {(globalWins.length > 0 ||
          localWins.length > 0) && (
          <>
            <h1>WINNERS</h1>
            {globalWins.length > 0 && (
              <section className="winnersGlobal">
                <h2>GLOBAL</h2>
                <FlipMove 
                  staggerDurationBy="30"
                  duration={500}
                  className="winnersLocal_list"
                  enterAnimation="accordionVertical"
                  leaveAnimation="accordionVertical"
                >
                  {globalWins.map((_, index) => (
                    <Win
                      isGlobal={true}
                      key={_?.winId}
                      barcode={_.barcode}
                      amount={formatAmount(
                        _.winAmount,
                        false,
                        config.accCurrency,
                        config.currencySymbol,
                        config.currencyDenomination
                      )}
                      stamp={_.stamp?.toNumber()}
                      jackpot={_.jackpot}
                    />
                  ))}
                </FlipMove>
              </section>
            )}
            {localWins.length > 0 && (
              <section className="winnersLocal">
                <h2>LOCAL</h2>
                <FlipMove 
                  staggerDurationBy="30"
                  duration={500}
                  className="winnersLocal_list"
                  enterAnimation="accordionVertical"
                  leaveAnimation="accordionVertical"
                >
                  {localWins.map((_, index) => (
                      <Win
                        isGlobal={false}
                        key={`${_?.winId}`}
                        barcode={_?.barcode}
                        amount={formatAmount(
                          _?.winAmount,
                          false,
                          config.accCurrency,
                          config.currencySymbol,
                          config.currencyDenomination
                        )}
                        stamp={_?.stamp?.toNumber()}
                        jackpot={_?.jackpot}
                      />
                    ))}
                </FlipMove>
              </section>
            )}
          </>
        )}
    </>
  );
};

export const Winners = observer(_Winners);
