import * as React from "react";
import CountUp from "react-countup";
import { formatAmount } from "../../utils/formatAmount";

interface ICountupProps {
  currency: string;
  end: number;
  currencySymbol: string | null;
  currencyDenomination: number;
}

interface ICountupState {
  startValue: number;
  endValue: number;
  isAnimatingCounter: boolean;
  formattingFn: (value: number) => string;
}

export class Countup extends React.Component<ICountupProps, ICountupState> {
  constructor(props: ICountupProps) {
    super(props);

    this.state = {
      startValue: 0,
      endValue: this.props.end,
      isAnimatingCounter: false,
      formattingFn: (value: number) => {
        return formatAmount(value, false, this.props.currency, this.props.currencySymbol, this.props.currencyDenomination, true);
      },
    };

    this.onStart = this.onStart.bind(this);
    this.onComplete = this.onComplete.bind(this);
  }

  shouldComponentUpdate(nextProps: ICountupProps, nextState: ICountupState) {
    return nextProps.end !== this.state.startValue;
  }

  componentDidUpdate(prevProps: ICountupProps, prevState: ICountupState) {
    if (!prevState.isAnimatingCounter || !prevProps.end) {
      if (
        prevProps.end !== this.props.end &&
        prevState.startValue !== this.props.end
      ) {
        this.setState({
          startValue: prevProps.end,
          endValue: this.props.end,
        });
      }
    }
  }

  render() {
    const { startValue, endValue, isAnimatingCounter, formattingFn } =
      this.state;
    const shouldRunAnimationForUpdate =
      startValue !== endValue && !isAnimatingCounter;

    return (
      <CountUp
        start={startValue}
        end={endValue}
        redraw={shouldRunAnimationForUpdate}
        duration={1}
        useEasing={true}
        onEnd={this.onComplete}
        onStart={this.onStart}
        formattingFn={formattingFn}
        className="jackpot__amount__value"
      />
    );
  }

  onComplete() {
    this.setState((prevState) => {
      return {
        ...prevState,
        isAnimatingCounter: false,
        startValue: prevState.endValue,
      };
    });
  }

  onStart() {
    this.setState((prevState) => {
      return { ...prevState, isAnimatingCounter: true };
    });
  }
}
