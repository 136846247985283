import { observer } from "mobx-react";
import { Spin } from "../components/spin/spin";
import { Footer } from "./footer";
import { Jackpots } from "./jackpots";
import { Winners } from "./winners";

const _Main = () => {

  return (
    <>
      <Spin />
      <div className="wrap">
        <Jackpots />
        <Winners />
        <Footer />
      </div>
    </>
  );
};

export const Main = observer(_Main);
